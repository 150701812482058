/**
* Timeline
*
* @section module
* @author author
*/

$timeline-width: 100%;
$timeline-height-lg: 380px; //540px;
$timeline-t-duration: 700ms;
$timeline-t-easing: cubic-bezier(.57, .13, .4, .88);
.timeline {
	overflow: hidden;
	background-color: $color-theme-white-bg;
	padding: 20px 0;
	>.container .inner {
		position: relative;
		background: #dedede;
		cursor: grab;
	}
	.module__top {
		max-width: 550px;
		margin: 0 auto 45px;
		text-align: center;
		p {
			font-weight: 100;
		}
	}
}

.timeline-items {
	width: $timeline-width;
	height: $timeline-height-lg;
	position: relative;
	z-index: 0;
	overflow: hidden;
	user-select: none;
}

.timeline-item
{
   position: absolute;
   width: 100%;
   height: 100%;
   background-repeat: no-repeat;
   background-position: 50% 50%;
   background-size: cover;

   &__fig
   {
      position: relative;
      display: none;

      img
      {
         display: block;
         width: 100%;
         height: auto;
      }
   }

   &__meta
   {
      position: absolute;
      /*left: 16px; //45px;
		right: 45px;
		bottom: 130px;*/
      z-index: 1; // background: blue;
      color: #fff; //$color-white; // state
      opacity: 0;
      pointer-events: none;
      transition: opacity $timeline-t-duration $timeline-t-duration;
      padding: 12px; //8px 8px 30px;
      background-color: rgba(41,38,30,0.6);
   }

   &__title
   {
      font-size: 20px;
      line-height: 25px;
      color: inherit;
      margin: 0px;
      padding: 0 0 6px;
      text-shadow: 0 0 5px #000;
   }

   &__year
   {
      display: none;
   }

   &__desc
   {
      color: inherit;
      font-weight: 300;
      padding: 0;
      line-height: 1.4em;
      text-shadow: 0 0 5px #000;
   }

   opacity: 0;
   pointer-events: none;

   &.active
   {
      z-index: 99;
      opacity: 1;
      pointer-events: auto;
   }

   &.before-animate-out
   {
      opacity: 1;
      transform: translate3d(0, 0, 0);
   }

   &.before-animate-out.animate-out
   {
      transition: transform $timeline-t-duration $timeline-t-easing;

      &.forward
      {
         transform: translate3d(-50%, 0, 0);
      }

      &.backward
      {
         transform: translate3d(50%, 0, 0);
      }
   }
   // animate in
   &.before-animate-in
   {
      &.forward
      {
         transform: translate3d(110%, 0, 0) scale(1.2);
      }

      &.backward
      {
         transform: translate3d(-110%, 0, 0) scale(1.2);
      }
   }

   &.before-animate-in.animate-in
   {
      transform: translate3d(0, 0, 0);
      transition: transform $timeline-t-duration $timeline-t-easing;

      .timeline-item__meta
      {
         opacity: 1;
         pointer-events: auto;
      }
   }
}

// bullet ui
.timeline-ui
{
   position: absolute;
   left: 0;
   bottom: 0px; //45px;
   padding: 50px 0 5px;
   background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%); /* FF3.6-15 */
   background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 100%); /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#99000000',GradientType=0 ); /* IE6-9 */
   z-index: 1;
   width: 100%;
   height: auto;
   overflow: hidden; // var
   // --item-spacing: 100px;
   &:before
   {
      content: "";
      position: absolute;
      bottom: 16px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: rgba(255, 255, 255, .4);
   }

   .bullets
   {
      display: flex;
      transition: all 300ms;
   }

   .bullet
   {
      flex: 0 0 auto; // margin: 0 0 0 var(--item-spacing);
      margin: 0 0 0 45px;
      color: $color-white;
      font-size: 15px;
      line-height: 18px;
      font-weight: 500;
      display: inline-flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      position: relative;

      > *
      {
         pointer-events: none;
      }

      &:focus,
      &:active, // &.active,
      &:hover
      {
         outline: none;

         .circle:after
         {
            opacity: 1;
            transform: scale(1);
         }
      }

      &.active
      {
         pointer-events: none;

         .circle:after
         {
            opacity: 0;
         }
      }
      // last item
      &:nth-last-of-type(1)
      {
         // margin-right: var(--item-spacing);
         margin-right: 50px;
      }
   }

   .year
   {
      margin-bottom: 5px; //20px;
      text-shadow: 0 0 5px #000;
   }

   .circle
   {
      display: inline-block;
      width: 24px; //30px;
      height: 24px; //30px;
      position: relative; // inner(white dot)
      &:before
      {
         content: "";
         position: absolute;
         width: 6px;
         height: 6px;
         border-radius: 50%;
         background: $color-white;
         top: 50%;
         left: 50%;
         transform: translate3d(-50%, -50%, 0);
      }
      // outter(red ring)
      &:after
      {
         content: "";
         position: absolute;
         width: 100%;
         height: 100%;
         border-radius: 50%;
         top: 0;
         left: 0;
         transform: scale(.75);
         opacity: 0;
         border: 1px solid $color-red;
         transition: opacity 250ms, transform 250ms;
      }
   }

   .red-circle
   {
      position: absolute;
      bottom: 5px;  //0;
      left: 0;
      width: 24px; //30px;
      height: 24px; //30px;
      border-radius: 50%;
      pointer-events: none;
      border: 2px solid $color-red;
      will-change: left;
      transition: left 450ms 150ms;
   }
}

//experienceeditor
.experienceeditor {
	.timeline {
		background-color: none;
		padding: 30px;
		>.container .inner {
			background: none;
			cursor: pointer;
		}
	}
	.timeline-ui {
		display: none;
	}
	.timeline-items {
		width: 100%;
		height: auto;
		user-select: auto;
	}
	.timeline-item {
		position: static;
		width: 100%;
		height: auto;
		opacity: 1;
		pointer-events: auto;
		background-size: 0;
		&__fig {
			display: block;
		}
		&__meta {
			position: static;
			color: $color-black; // state
			opacity: 1;
			pointer-events: auto;
			transition: none;
		}
		&__year {
			display: block;
		}
	}
}

@media screen and (min-width: $bp-mobile) {}

@media screen and (min-width: $bp-tablet) {
	/*.timeline {
		padding: 30px 0 20px;
	}*/
	.timeline-ui {
		.bullet {
			margin-left: 100px;
			&:nth-last-of-type(1) {
				margin-right: 100px;
			}
		}
	}
	.timeline-item {
		&__meta {
			top: 20px; //90px;
			left: 20px; //100px;
         width: 50%;
         //right: 100px;
			//bottom: auto;
		}
	}
   .timeline-items {
      height: 450px;
   }
}

@media screen and (min-width: $bp-desktop-small) {
	/*.timeline {
		padding: 60px 0 90px;
	}*/
   .timeline-items {
      height: 550px;
   }
   .timeline-item
   {
      &__meta
      {
         width: 40%;
      }

      &__desc
      {
         font-size: 16px;
      }
   }
}

@media screen and (min-width: 1200px) {
   .timeline-items
   {
      height: 650px;
   }
}
