/**
* Module Container
*
* note, this is used as a container to container multiple modules inside.
*
* @section module
*  
*/
.module-container{
  > .container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: $bp-tablet) {
  .module-container {
    &.col-2,
    &.col-3 {
      > .container {
        display: flex;
        flex-wrap: nowrap;
      }
    }

    &.col-2{
      .module {
        flex: 0 0 50%;

        > .container {
          width: 100%;
        }
      }
    }

    &.col-3{
      .module {
        flex: 0 0 33%;

        > .container {
          width: 100%;
        }
      }
    }

    .csl-table,
    .download-list,
    .dropdown-table,
    .accordian-list,
    .dropdown-table {
      .ui-wrapper {
        text-align: left;
      }
    }

  }
}

@media screen and (min-width: $bp-desktop-small) {
}
