/**
* Content List
* col-2 | col-3 | col-4
*
* @section module
*  
*/

.content {
	background-color: $color-white;
	position: relative;
	padding: 10px 0;

	a {
		color: $color-aa-red;

		&:focus,
		&:hover,
		&:active {
			@include global-focus();
		}
	}

	&__top {
		padding: 70px 0 100px;

		h2 {
			margin: 0 0 40px 25px;
		}

		p {
			margin: 0 0 0 25px;
		}
	}

	&__item {
		//border-bottom: 1px solid #cbcbcb;
		padding: 0px 0px 25px; //30px 25px;
		position: relative;

		&:after {
			content: "";
			position: absolute;
			width: calc(100% + 30px);
			height: 1px;
			/*background-color: #cbcbcb;*/
			bottom: 0;
			left: -15px;
		}

		&__img {
			img {
				width: 100%;
				height: auto;
				display: block;
			}
		}

		&__title {
			margin: 0; //15px 0 5px;
			font-size: 18px; //20px;
			line-height: 1.5em; //25px;
			font-weight: 500; //400;
			padding: 10px 0 0; 
		}

		&__desc {
			//margin-bottom: 15px; //20px;
			margin: 0;
			padding: 10px 0;
			font-weight: 300;
		}

		&__rich {
			em {
				font-style: normal;
			}

			p {
				color: inherit;
				font-weight: 300;
			}
		}

		.btn-wrapper {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			/*margin-top: 30px;*/
		}

		[class*="ui-btn"] + [class*="ui-btn"] {
			margin-top: 15px;
		}
	}
	// load more
	.ui-wrapper {
		display: block;
		width: 100%;
		padding: 30px 0;
		text-align: center;
	}

	.module {
		&__top {
			h4 {
				margin: 0;
				padding: 10px 0;
				font-size: 24px; //36px;
				font-weight: 500;
				line-height: 32px;
			}
			p {
				font-size: 15px;
			}
		}
	}
}

//not using(color)
// .content {
// 	&.theme-black {}
// 	&.theme-white {}
// 	&.theme-gray {}
// }

.content--quote {
	text-align: center;
	.content__item__quote {
		font-style: italic;
		&::before {
			content: '\201C';
		}
		&::after {
			content: '\201D';
		}
	}
	.ui-btn {
		left: 50%;
		transform: translateX(-50%);
	}
}

//experienceeditor
.experienceeditor {
	.content {
		&__items {
			.scEmptyPlaceholder {
				min-width: 100px;
				min-height: 100px;
			}
		}
		.ui-wrapper {
			display: none;
		}
	}
}

@media screen and (max-width: $bp-tablet - 1px) {
	.content {
		.container {
			width: 100%;
		}
	}
}

@media screen and (min-width: $bp-tablet) {
	.content {
		&__top {
			padding: 140px 0;

			h2,
			p {
				margin-left: 4.5%;
			}
		}

		&__items {
			padding-bottom: 0;
			//margin: 0 -15px;
			display: flex;
			align-items: stretch;
			flex-wrap: wrap;
		}

		&__item {
			border: none;
			flex: 0 0 auto;
			figure {
				margin-top: 10px;
			}

			&:after {
				visibility: hidden;
			}

			&__title {
				font-weight: 300;
				font-size: 26px; //30px;
				line-height: 1.3em; //40px;
			}

			&__desc {
				/*margin-top: 40px;*/
				//margin-bottom: 5px; //40px;
				font-weight: 300;
			}
			/*.btn-wrapper {
				position: absolute;
				bottom: 20px; //15px;
				//min-height: 45px;
				margin: 0;
			}*/
		}

		.ui-wrapper {
			padding: 90px 0;
		}
		//variations (col-{nth})
		&.col-2,
		&.col-3,
		&.col-4 {
			.content__item {
				width: 50%;
				padding: 0 50px 10px 0; //10px 50px 75px 0; //30px 30px 10px 30px;
			}
		}
		//variations (col-{nth})
		&.col-2 {
			.content__item__title {
				min-height: 40px;
			}
		}

		&.col-3 {
			.content__item__title {
				min-height: 40px;
			}
		}

		&.col-4 {
			.content__item__title {
				min-height: 40px;
			}
		}
		.module {
			&__top {
				h4 {
					//margin: 25px 0px 0px ;
					font-size: 30px;
					line-height: 40px;
					font-weight: 300;
				}
			}
		}
	}
}

@media screen and (min-width: $bp-desktop-small) {
	.content {
		&__item {
			&__title {
				padding: 20px 0 0; //20px 0;
			}
		}
		//variations (col-{nth})
		&.col-2 {
			.content__item {
				width: 50%;
				padding: 0 50px 10px 0; //10px 50px 75px 0;  //30px 90px 15px 90px;
			}

			.content__item__img {
				//width: calc(100% + 150px);
				//margin-left: -75px;
			}
		}

		&.col-3 {
			.content__item {
				width: 33.3%;
				padding: 0 30px 10px 0; //10px 30px 75px 0; //30px 15px 15px 15px;
			}
		}

		&.col-4 {
			.content__item {
				width: 25%;
				padding: 0 30px 75px 0; //10px 30px 75px 0; //30px 15px 75px 15px;
			}
		}
	}
	.content--quote {
		.content__item {
			width: 25%;
		}
	}
}

//print
@media print {
	.content {
		.ui-wrapper {
			display: none;
		}
	}
}

//language overrides
.ja-JP,
.zh-CN {
	.content .module__top h4 {
		font-size: 18px;
		line-height: 26px;
	}

	.content__item__title {
		font-size: 16px;
		line-height: 20px;
	}

	@media screen and (min-width: $bp-tablet) {
		.content .module__top h4 {
			font-size: 22px;
			line-height: 30px;
		}

		.content__item__title {
			font-size: 20px;
			line-height: 28px;
		}
	}
}
