/**
* UI Pagination
*
* @author <min>
*/

.ui-pagination {
	$btn-width: 20px;
	$btn-height: 20px;
	$icon-size: 24px;
	display: inline-flex;
	align-items: center;
	>* {
		flex: 0 0 auto;
		&:not(:first-child) {
			margin-left: 45px;
		}
	}
	&__arrow,
	&__btn {
		width: $btn-width;
		height: $btn-height;
		text-align: center;
		color: $ui-color-black;
		will-change: color;
		transition: color $ui-transition-dur/2; //hover
		&:focus,
		&:hover,
		&:active {
			color: $ui-color-red;
			outline: none;
		} //inactive state
		&.inactive {
			pointer-events: none;
			user-select: none;
			opacity: .25;
		}
	}
	&__arrow {
		//icon
		i {
			@extend .icon-caret-outline !optional;
			font-size: $icon-size;
			line-height: $btn-height;
			display: inline-block;
			color: inherit;
		} //inactive state
		&.inactive {
			visibility: hidden;
			outline: none;
		}
		&[role="prev"] i {
			transform: rotate(90deg);
		}
		&[role="next"] i {
			transform: rotate(-90deg);
		}
	}
	&__index {
		display: inline-block;
		text-align: center;
		width: 20px;
		height: $btn-height;
		line-height: $btn-width;
		will-change: color;
		transition: color $ui-transition-dur/2;
		color: $ui-color-red;
		&:focus,
		&:hover,
		&.active {
			color: $ui-color-black;
			text-decoration: underline;
			outline: none;
		}
	} //current & total
	&__count {
		font-family: $ui-font-family;
		font-size: 13px;
		color: $color-ui-black;
		display: inline-block;
		text-align: center;
		min-width: 40px;
	} //inactive state
	&.inactive {
		pointer-events: none;
		user-select: none;
		opacity: .25;
	}
	//is static
	// &.is-static {}
	//color theme
	&.theme-black,
	&-black {
		.ui-pagination__btn {
			background-color: $color-ui-gray;
			border: 2px solid $color-ui-white; //icon
			[class^="icon-"] {
				color: $color-ui-white;
			} //hover
			&:hover,
			&:active {
				background-color: $color-ui-white;
				[class^="icon-"] {
					color: $color-ui-gray;
				}
			}
		}
		.ui-pagination__count {
			color: $color-ui-white;
		}
	}
}

@media screen and (max-width: 480px) {}
