/**
* Breadcrumb
*
* @author echristensen
*/

.c-breadcrumb {
	position: relative;
	background: transparent !important;
	nav ul {
		margin: 0;
		padding: 0;
		list-style: none;
		overflow: hidden;
		color: #111;
		font-size: 12px;
		li {
			padding: 2px 16px 2px 0;
			margin: 0;
			float: left;
			position: relative; //only display the very first one, the last - 1
			&:nth-of-type(1),
			&:nth-last-of-type(2) {
				display: inline-block;
			}
			&:after {
				content: ">";
				position: absolute;
				right: 5px;
				top: 4px;
				font-size: 14px;
				color: inherit;
			} // update. even if the page is hidden in CMS, don't have it
			// https://vertic.atlassian.net/browse/CSL-441
			// &.hide-nav {
			// 	// display: none;
			// }
			&:nth-last-of-type(1) {
				pointer-events: none;
				&:after {
					display: none;
				}
			}
		}
		li,
		a {
			font-weight: 300;
			line-height: 25px;
			font-size: inherit;
			color: inherit;
			&:first-letter {
				text-transform: uppercase;
			}
		}
		a {
			&:active,
			&:hover,
			&:focus {
				@include global-focus();
			}
		}
	}
}

@media screen and (min-width: $bp-tablet) {
	.c-breadcrumb {
		nav ul li {
			display: inline-block;
		}
	}
}
