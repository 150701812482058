//override
$ui-transition-dur: 250ms;
$ui-easing: cubic-bezier(0.15, 0.67, 0.67, 1);
$ui-font-family: $base-font;

$ui-color-border: rgb(241, 239, 234) !default;
$ui-color-white: rgba(255, 255, 255, 1) !default;
$ui-color-black: rgba(1, 1, 1, 1) !default;
$ui-color-gray: rgba(255, 255, 255, 1) !default;
$ui-color-red: $color-aa-red !default;
