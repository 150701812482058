/**
* Abstract module
*
* @section module
* @author min<kyang@vertic.com
*/
.module{
  &.no-js,
  &.init,
  .experienceeditor & {
    pointer-events: auto;
  }

  .dummy-input {
	  opacity: 0;
	  position: absolute;
	  pointer-events: none;
	  user-select: none;
	  top: 0;
	  left: 0;
  }
}

//inbetween top spacing
$startIndex: 1;
$endIndex: 20;
$positiveSpacingName: 'p-space';
$negativeSpacingName: 'n-space';

//positive margins
@for $i from $startIndex through $endIndex {
  .#{$positiveSpacingName}-#{$i * 10} {
    /*margin-top: 40px;*/
  }

  .#{$negativeSpacingName}-#{$i * 10} {
    /*margin-top: -40px;*/
  }
}

@media screen and (min-width: $bp-mobile) {
  @for $i from $startIndex through $endIndex {
    .#{$positiveSpacingName}-#{$i * 10} {
      /*margin-top: $i * 6px;*/
    }
    .#{$negativeSpacingName}-#{$i * 10} {
      /*margin-top: -$i * 6px;*/
    }
  }
}

@media screen and (min-width: $bp-tablet) {
  @for $i from $startIndex through $endIndex {
    .#{$positiveSpacingName}-#{$i * 10} {
      /*margin-top: $i * 8px;*/
    }
    .#{$negativeSpacingName}-#{$i * 10} {
      /*margin-top: -$i * 8px;*/
    }
  }
}

@media screen and (min-width: $bp-desktop-small) {
  @for $i from $startIndex through $endIndex {
    .#{$positiveSpacingName}-#{$i * 10} {
      /*margin-top: $i * 10px;*/
    }
    .#{$negativeSpacingName}-#{$i * 10} {
      /*margin-top: -$i * 10px;*/
    }
  }
}
