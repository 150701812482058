/**
* UI Pagination
*
* @author <min>
*/

.ui-selector {
	position: relative;
	display: inline-block;
	will-change: background-color;
	transition: background-color 150ms;
	min-width: 100px; // label
	.ui-selector-label {
		display: block;
		font-size: 14px;
		line-height: 16px;
		position: relative;
		padding: 10px 30px 10px 0;
		text-align: left;
		color: $color-gray-0;
		i {
			position: absolute;
			top: 50%;
			right: 0;
			transform-origin: 50%;
			font-size: 30px;
			margin-top: -15px;
		}
		&:focus,
		&:hover,
		&.active {
			i {
				transform: rotate(180deg);
			} // red & underline
			border: 0;
			@include global-focus();
		}
	}
	.l-list {
		display: none; // pointer-events: none;
		width: 100%;
		position: absolute;
		z-index: 99;
		left: 0;
		margin: 0;
		padding: 0 10px 10px;
		background-color: inherit;
		text-align: left;
		transform: translateY(-1px); // 1px hairline
		>div {
			padding: 7.5px 0;
			margin: 0;
			position: relative;
			&.active {
				display: none;
			}
		}
		a {
			font-size: 14px;
			line-height: 16px;
			font-weight: 300;
			text-align: left;
			color: $color-gray-0;
			&:hover,
			&:focus {
				// red & underline
				@include global-focus();
			}
		}
		i,
		em,
		[class*="icon-"] {
			position: absolute;
			top: 50%;
			right: 5px;
			transform: translateY(-50%);
			font-weight: 100;
			font-size: 10px;
		}
	}
	&.direction-up {
		.l-list {
			bottom: 30px;
			transform: translateY(1px); // 1px hairline
		}
	}
	&.active {
		padding: 0 10px;
		background-color: $color-white;
		box-shadow: 0px -1px 2px 1px rgba(37, 37, 37, 0.05);
		.l-list {
			display: block;
			box-shadow: 0px 2px 2px 1px rgba(37, 37, 37, 0.05);
			pointer-events: auto;
		}
	}

	// &.disabled {}
	// &.has-limited-item {}

}

@media screen and (max-width: 480px) {}
