/**
* Custom bootstrap override
* no longer use bootstrap css.
* removed from package.json
*/

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1180px; //1200px;
}

//custom vars
.container.no-padding{
  .row,
  [class^="col-"] {
    padding: 0;
  }
}

@media (min-width: $bp-tablet) {
  .container {
    width: 752px;
  }
}

@media (min-width: $bp-desktop-small) {
  .container {
    width: 1008px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1180px; //1200px;
  }
}
