/**
* CSL Form
*
* @section module
*  
*/

.csl-form {
	padding: 20px 0 10px; 
	p {
		font-size: 15px;
		line-height: 25px;
		font-weight: 300;
		margin: 0;
		padding: 15px 0;
		a {
			color: $color-red;

			&:focus,
			&:active,
			&:hover {
				@include global-focus();
			}
		}
	}

	h2 {
		padding: 0 0 10px;
		margin: 30px 0 0 0;
	}

	h3 {
		padding: 0 0 10px;
		margin: 0;
	}

	&__t,
	&__st,
	&__st p,
	&__st p {
		@extend .h3;
		color: inherit;
		font-weight: 300;
		margin-top: 0;
		margin-bottom: 45px;
	}

	&__sst {
		@extend .h6;
		color: inherit;
		font-weight: 300;
		margin-top: 0;
		margin-bottom: 45px;
	}

	.thankyou {
		display: none;
	}

	// state (is-submitted)
	&.is-form-submitted {
		.subscription {
			display: none;
		}

		.thankyou {
			display: block;
		}
	}

	p + form {
		margin-top: 30px;
	}
	//override
	form {
		// top error messages(hide)
		.has-error.has-feedback:not(.form-group) {
			display: none !important;
		}
		//each inputs group
		fieldset {
			border: 0;
			padding: 0;
			clear: both;
		}
		//each inputs group title
		/*legend {
			display: none;
		}*/
		// bootstrap
		.row {
			margin: 0;
			padding: 0;
		}

		[class*="col-"] {
			margin: 0;
			padding: 0;
			overflow: hidden;
		}

		.required-field .control-label:before,
		.required-field .checkbox:after {
			color: $color-red;
			content: "*";
			font-weight: bolder;
		}
		// each input
		.form-group {
			box-sizing: border-box;
			padding: 5px 0; //0;
			//margin-bottom: 30px;
			width: 100%;
			float: none;
			clear: none;

			&.hidden {
				display: none;
			}

			&.no-float {
				width: 100%;
				clear: both;
				float: none;
			}

			&.col-9,
			&.col-6,
			&.col-3 {
				width: 100%;
			}

			label.control-label {
				display: block;
				font-size: 15px;
				line-height: 25px;
				font-weight: 400;
				margin-bottom: 30px;

				&.checkbox-label {
					display: inline-block;
					margin: 0 5px 0 0;
					max-width: calc(100% - 30px);

					& ~ input[type="checkbox"],
					& ~ input[type="radio"] {
						vertical-align: top;
						margin-top: 10px;
					}
				}
			}

			textarea.form-control {
				display: block;
				min-width: 100%;
				max-width: 100%;
				border: 1px solid #b5b5b5;
				font-family: $ui-font-family;
				font-size: $ui-input-font-size;
				font-weight: 300;
				overflow: hidden;
				outline: none;
				background: transparent; // AA
				&:focus {
					outline: none;
					border: 1px solid $color-global-focus;
				}
			}

			input.form-control {
				display: block;
				width: 100%;
				height: 60px;
				border: 1px solid #b5b5b5;
				font-family: $ui-font-family;
				font-size: $ui-input-font-size;
				font-weight: 300;
				padding: 0 15px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				background: transparent;
				outline: none;
				background: transparent; // AA
				&:focus {
					outline: none;
					border: 1px solid $color-global-focus;
				}

				& + .help-block {
					opacity: 0;
				}

				&.input-validation-error + .help-block {
					opacity: 1;
				}

				&:disabled {
					border: 0;
					pointer-events: none;
					user-select: none;
				}
			}

			select {
				width: 100%;
				height: $ui-dropdown-h;
				border-radius: 0;
				border: 0;
				background: $ui-color-gray;
				color: $ui-color-black;
				font-size: 15px;
				line-height: 25px;
				outline: none;
				padding: 0 15px;
				border: 1px solid #b5b5b5;
				border-radius: 0; //webkit
				-webkit-appearance: none;
				-webkit-border-radius: 0px;

				/*&,
				&:-webkit-autofill,
				&:-webkit-autofill:hover,
				&:-webkit-autofill:focus {
					background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>") !important;
					background-position: 98% 50% !important;
					background-repeat: no-repeat !important; // AA
				}*/

				&:focus {
					outline: none;
					border: 1px solid $color-global-focus;
				}
			}
			// fallback due to some webkit browser won't allow override
			.ui-select-wrapper {
				width: calc(100% - 30px);
				position: relative;

				select {
					width: 100%;
					background-image: none !important;
				}

				[class^="icon-"] {
					position: absolute;
					top: 50%;
					right: 20px;
					transform: translateY(-50%);
					z-index: 1;
					pointer-events: none;
					color: $color-gray-2;
					font-size: 12px;
				}
			}
			// inline error messages
			.help-block {
				display: block;
				font-size: 10px;
				color: $color-red;
				margin-top: 15px;
				min-height: 20px;
			}
		}
		// submit btn
		.form-submit-border {
			text-align: center;
			margin-bottom: 30px; // round cta style
			margin-top: 30px; // round cta style
			clear: both;
		}

		input[type="submit"],
		input[type="button"],
		.btn {
			line-height: normal;
			@include ui-btn-factory('box');

			&:disabled {
				@extend .ui-btn.disabled;
			}

			& + input[type="submit"] {
				margin-left: 15px;
			}
		}
	}
	// override csl-table
	.csl-table {
		th,
		td {
			padding: 8px 15px; //15px;
		}

		th.sort {
			&:after {
				position: absolute;
				content: "";
				top: calc(50% - 10px);
				right: 5px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 5px;
				border-color: rgb(185, 185, 185) transparent transparent transparent;
			}
		}

		th.sortdesc {
			&:after {
				position: absolute;
				content: "";
				top: calc(50% - 10px);
				right: 5px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 5px;
				border-color: black transparent transparent transparent;
			}
		}

		th.sortasc {
			&:after {
				position: absolute;
				content: "";
				top: calc(50% - 10px);
				right: 5px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 5px;
				border-color: transparent transparent black transparent;
			}
		}
	}

	.section-divider {
		margin: 30px 0; //60px 0;
	}
	// disclaimer
	.subscription span:last-child {
		font-size: 13px;
	}

	.ui-wrapper {
		display: inline-flex;
		flex-direction: column;
		align-items: center;

		> a + p,
		> p + a,
		> a + a,
		> a + input,
		> input + input,
		> input + a {
			margin-top: 30px;
		}
	}

	.date-picker-wrapper {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
		flex-direction: row;

		.date-picker-item {
			flex: 0 0 auto;
			width: auto;
			padding: 0 5px;
			margin: 5px;
			overflow: hidden;
		}

		.date-picker {
			margin: 0;
			border: 0;
			border-bottom: 1px solid #b5b5b5;
			padding: 10px 0;
		}
	}

	.disclaimer {
		padding: 15px 0;
	}

	.days_ago {
		font-size: 12px;
		line-height: normal;
		color: #666;
		margin-top: 5px;
	}
}

// user forms
.csl-form {
	// this form will be nested in popup
	&.forgot-password {
		padding: 0;
		>.container {
			width: 100%;
		}
	}
	&.auth-doc-check {
		padding: 60px 0;
		iframe {
			margin: 0 auto;
			display: block;
		}
	}
	&.dashboard {}
}

//experienceeditor
.experienceeditor {
	.csl-form {
		.thankyou {
			display: block;
		}
		form {
			[class*="col-"] {
				.form-group {
					width: 100%;
					float: none;
					clear: both;
					&.no-float {
						width: 100%;
						clear: both;
					}
					&.col-9,
					&.col-6,
					&.col-3 {
						width: 100%;
						clear: both;
					}
				}
			}
		}
	}
}

@media screen and (min-width: $bp-tablet) {
	.csl-form {
		padding: 30px 0 10px; //60px 0;
		h1 {
			font-size: 42px;
			line-height: 52px;
			margin: 0;
			padding-bottom: 10px;
		}

		h3 {
			font-size: 24px;
			margin: 0;
			padding-bottom: 10px;
		}

		p + form {
			margin-top: 60px;
		}
		//override
		form {
			//each inputs group
			fieldset {
				border: 0;
				padding: 0;
			}
			// each input
			.form-group {
				//margin-bottom: 30px;
				//width: 50%;
				float: left;
				clear: none;

				&.col-3 {
					width: 25%;
				}

				&.col-6 {
					width: 50%;
				}

				&.col-9 {
					width: 75%;
				}

				label.control-label {
					margin-bottom: 30px;

					&.checkbox-label {
						& ~ input[type="checkbox"],
						& ~ input[type="radio"] {
							vertical-align: middle;
							margin-top: 0;
						}
					}
				}

				input.form-control {
					width: calc(100% - 30px);
					height: 60px;
				}

				textarea.form-control {
					min-width: calc(100% - 30px);
				}

				select {
					width: calc(100% - 30px);
					height: $ui-dropdown-h;
				}
			}
		}
	}
}

@media screen and (min-width: $bp-desktop-small) {
	.csl-form {
		padding: 40px 0 10px; //120px 0;
	}
}

@media screen and (max-width: 320px) {
	.csl-form {
		&.auth-doc-check {
			iframe {
				margin-left: -10px;
			}
		}
	}
}
