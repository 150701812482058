/**
* UI Input
*
* @author <min>
*/

$ui-input-h-sm: 30px;
$ui-input-h: 60px;
$ui-input-h-lg: 90px;
$ui-input-font-size: 15px;
$ui-input-font-size-lg: 30px;
$ui-input-icon-size: 25px;
%ui-input,
.ui-input {
	height: $ui-input-h;
	position: relative;
	border: 1px solid $ui-color-border;
	font-family: $ui-font-family;
	font-size: $ui-input-font-size;
	font-weight: 300;

	label {
		//@extend %visuallyhidden;
	}

	input,
	input[type="text"],
	input[type="number"] {
		font-family: inherit;
		font-size: inherit;
		font-weight: inherit;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
		height: 100%;
		outline: none;
		border: 0;
		background: transparent;
		color: $ui-color-black;
		padding: 0 50px;

		&::-webkit-input-placeholder {
			color: $ui-color-black;
		}

		&::-ms-clear {
			display: none;
		}

		&::-ms-reveal {
			display: none;
		}

		&:active,
		&:focus {
			border: 1px solid $color-global-focus;
		}
	}

	a[data-role="search"],
	a[data-role="email"],
	a[data-role="submit"] {
		@extend %icon;

		&:active,
		&:focus {
			outline: none;
			border: 1px solid $color-global-focus;
			text-decoration: none;
		}
	}

	&.small {
		height: $ui-input-h-sm;
	}

	&.large {
		font-size: $ui-input-font-size-lg;
		font-weight: 300;
		height: $ui-input-h-lg;
	}

	&.search {
		padding-left: 90px;
		padding-right: 90px;

		input:-webkit-autofill {
			background-color: transparent !important;
			color: $color-ui-white !important;
		}
		//search icon
		a[data-role="search"],
		i {
			position: absolute;
			left: 30px;
			top: 50%;
			font-size: 20px;
			color: $ui-color-red;
			transform: translateY(-50%);
			padding: 15px;
			display: inline-block;
			cursor: pointer;
			z-index: 1;

			&:before {
				content: "\e900";
				pointer-events: none;
			}
		}
		//arrow icon
		a[data-role="submit"] {
			position: absolute;
			top: 50%;
			font-size: 20px;
			padding: 20px;
			right: 30px;
			color: $ui-color-red;
			transform: translateY(-50%);
			display: inline-block;
			cursor: pointer;
			z-index: 1;

			&:before {
				pointer-events: none;
				content: "\e90c";
			}
		}
	}

	&.email {
		padding-left: 90px;
		padding-right: 90px;

		input:-webkit-autofill {
			background-color: transparent !important;
			color: $color-ui-white !important;
		}
		//email icon
		a[data-role="email"],
		i {
			position: absolute;
			left: 30px;
			top: 50%;
			font-size: 20px;
			color: $ui-color-red;
			transform: translateY(-50%);
			padding: 15px;
			display: inline-block;
			cursor: pointer;
			z-index: 1;

			&:before {
				content: "\e921";
				pointer-events: none;
			}
		}
		//arrow icon
		a[data-role="submit"] {
			position: absolute;
			top: 50%;
			font-size: 20px;
			padding: 20px;
			right: 30px;
			color: $ui-color-red;
			transform: translateY(-50%);
			display: inline-block;
			cursor: pointer;
			z-index: 1;

			&:before {
				pointer-events: none;
				content: "\e90c";
			}
		}
	}

	&.white {
		background-color: $ui-color-white;

		input,
		input[type="text"],
		input[type="number"] {
			color: $ui-color-black;

			&::-webkit-input-placeholder {
				color: $ui-color-black;
			}
		}
	}

	&.dark {
		background-color: $ui-color-black;

		input,
		input[type="text"],
		input[type="number"] {
			color: $ui-color-white;

			&::-webkit-input-placeholder {
				color: $ui-color-white;
			}
		}
	}
	//states
	&.focus {
		//x icon
		a[data-role="search"],
		i {
			&:before {
				content: "\e90d";
			}
		}
	}
}

@media screen and (max-width: $bp-tablet - 1px) {
	.ui-input {
		&.large {
			font-size: $ui-input-font-size;
			height: $ui-input-h;
		}
		&.email,
		&.search {
			padding-left: 45px;
			padding-right: 45px; //search icon
			a[data-role="search"],
			a[data-role="email"] {
				left: 0;
			}
			a[data-role="submit"] {
				right: 0;
			}
			i {
				left: 0;
			}
		}
	}
}
