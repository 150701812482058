/**
* Base typographical setup (vertical rythm etc)
*
* @section Layout
* @author min
*/
h1,
.h1,
.font-hero-title {
	font-size: 30px;
	line-height: 35px;
	font-family: $base-font;
	font-weight: $font-weight-light;
	letter-spacing: -1px;
}

h2,
.h2{
	font-size: 20px;
	line-height: 30px;
	font-family: $base-font;
	font-weight: $font-weight-light;
}

.font-module-title {
	font-size: 24px;
	line-height: 32px;
	font-family: $base-font;
	font-weight: $font-weight-light;
}

h3,
.h3,
.font-contents-title {
	font-size: 20px;
	line-height: 30px;
	font-family: $base-font;
	font-weight: $font-weight-regular;
}

.h4,
h4{
	font-size: 20px;
	line-height: 30px;
	font-weight: $font-weight-bold;
}

.h5,
h5{
	font-size: 15px;
	line-height: 25px;
	font-weight: $font-weight-regular;
}

.h6,
h6{
	font-size: 15px;
	line-height: 25px;
	font-weight: $font-weight-regular;
}

.h7,
.font-tag,
.font-meta {
	font-size: 12px;
	line-height: 25px;
	font-family: $base-font;
	font-weight: $font-weight-regular;
	color: $color-font-black;
}


h1,
h2,
h3,
h4,
h5,
h6,
p,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.h7,
.font-hero-title,
.font-module-title,
.font-contents-title,
.font-search-field,
.font-meta {
	&.white {
		color: $color-font-white;
	}

	&.black {
		color: $color-font-black;
	}

	&[id] {
		margin-top: 0;
		padding-top: 72px;
	}
}

p,
.p,
.font-search-field,
li {
	padding: 0 0 16px; 
    margin: 0; //0 0 em(16px);
	font-size: 16px;
	line-height: 1.7em;
	font-family: $base-font;
}

ul,
ol {
	padding-left: 20px;
}
li {
	margin: 0 0 em(8px);
}

.entry {
	font-size: em(14px);
	line-height: 1.5em;
}

.justify {
	text-align: justify;
}

//down to 768px (tablet portrait || landscape || print)
@media screen and (min-width: $bp-tablet) {
	.h1,
	h1,
	.font-hero-title {
		font-size: 60px;
		line-height: 60px;
		font-weight: $font-weight-ultra-light;
	}

	.h2,
	h2,
	.font-module-title {
		font-size: 40px;
		line-height: 50px;
		font-weight: $font-weight-light;
	}

	.h3,
	h3,
	.font-contents-title {
		font-size: 30px;
		line-height: 40px;
		font-weight: $font-weight-regular;
	}

	.h4,
	h4{
		font-size: 30px;
		line-height: 40px;
		font-weight: $font-weight-light;
	}

	.h5,
	h5{
		font-size: 30px;
		line-height: 40px;
		font-weight: $font-weight-bold;
	}

	.h6,
	h6{
		font-size: 15px;
		line-height: 25px;
		font-weight: $font-weight-bold;
	}

	.font-content {
		font-size: 14px;
		line-height: 20px;
	}

	.font-meta {
		font-size: 12px;
		line-height: 23px;
	}
}

//down to 1025px(desktop)
@media screen and (min-width: $bp-desktop-small) {
}

// print, same as $bp-tablet
@media print {

	.h1,
	h1,
	.font-hero-title {
		font-size: 60px;
		line-height: 60px;
		font-weight: $font-weight-ultra-light;
	}

	.h2,
	h2,
	.font-module-title {
		font-size: 40px;
		line-height: 50px;
		font-weight: $font-weight-light;
	}

	.h3,
	h3,
	.font-contents-title {
		font-size: 30px;
		line-height: 40px;
		font-weight: $font-weight-regular;
	}

	.h4,
	h4{
		font-size: 30px;
		line-height: 40px;
		font-weight: $font-weight-light;
	}

	.h5,
	h5{
		font-size: 30px;
		line-height: 40px;
		font-weight: $font-weight-bold;
	}

	.h6,
	h6{
		font-size: 15px;
		line-height: 25px;
		font-weight: $font-weight-bold;
	}

	.font-content {
		font-size: 14px;
		line-height: 20px;
	}

	.font-meta {
		font-size: 12px;
		line-height: 23px;
	}

}

//language overrides
.ja-JP,
.zh-CN {
	.h4,
	h4 {
		font-size: 18px;
		line-height: 24px;
		font-weight: 500;
	}

	@media screen and (min-width: $bp-tablet) {
		.h4,
		h4 {
			font-size: 24px;
			line-height: 32px;
			font-weight: $font-weight-light;
		}
	}
}

//language font-family overrides
.el-GR,
.pl-PL,
.cs-CZ
{
	h1, .h1, .font-hero-title,
	h2, .h2, 
	.font-module-title,
	h3, .h3, .font-contents-title, 
	h4, .h4, 
	.h7, .font-tag, .font-meta,
	p, .p, .font-search-field, li
	{
		font-family: Arial, Helvetica, sans-serif;
	}
}
