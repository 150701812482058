/**
* Download List
*
* @section module
*  
*/

.download-list {
	padding: 10px 0; //30px 0;

	&__top {
		margin: 0 0 15px; //25px 0;

		h4 {
			@extend .h4;
			font-size: 24px;
			line-height: 32px;
			font-weight: 500;
			margin: 0px;
		}

		p {
			margin-bottom: 20px;
			max-width: 768px;
			font-weight: 300;
		}
	}

	&__bottom {
		overflow-x: auto;
	}

	&__title {
		font-weight: 500;
		margin-top: 0;
		margin-bottom: 15px;
		font-size: 15px;
	}

	&__desc {
		font-weight: 300;
		margin-top: 30px;
		margin-bottom: 0;
	}

	.ui-wrapper {
		display: block;
		text-align: center;
		margin-top: 50px;
	}

	table {
		width: 100%;
		//min-width: $csl-table-min-width;
		border-collapse: collapse;
		border-spacing: 0;
		border: 0;
		table-layout: auto;
		font-size: 15px;
		font-weight: 300;
		line-height: 18px; //25px;
	}
	//tr group by id(data-id)
	tbody {
		overflow: hidden;

		&:hover {
			a.anchor {
				transform: translateY(calc(-50% + 2.5px));
			}
		}

		&.active {
			a.anchor {
				transform: rotate(180deg) translateY(50%);
			}

			.expanded-row {
				display: table-row;
			}
		}
	}
	//rows
	tr {
		border-bottom: 1px solid #e0e0e0;

		&:nth-of-type(1) {
			border-top: 1px solid #e0e0e0;
		}
	}

	th,
	td {
		position: relative;
		padding: 10px 30px 10px 3px; //30px 30px 30px 0;
		text-align: left;
		vertical-align: middle;

		p {
			margin: 0;
			font-size: 15px;
			padding: 0;
			line-height: 1.5em;
		}
		/*min-width: 120px;*/
		a:not(.ui-icon-btn) {
			color: $color-aa-red;

			&:hover,
			&:active {
				text-decoration: underline;
			}
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
		}
		// td only
		&:not(th):nth-of-type(1) {
			font-weight: 500;
		}
		// the last column content should be ling + icon
		&:nth-last-of-type(1) {
			padding: 0;
			min-width: 0;
			width: 30px;

			a:not(.ui-icon-btn):not(:empty) {
				@include icon(); //asset download type
				@extend .icon-doc !optional; //except for media(video link etc)
				&[class="media"] {
					@extend .icon-Icon-play !optional;
				}

				text-indent: -9999px;

				&:before {
					color: $color-aa-red;
					font-size: 30px;
					line-height: 30px;
					height: 30px;
				}

				&:focus,
				&:hover,
				&:active {
					outline: none;
					text-decoration: none;

					&:before {
						border: 1px solid $color-global-focus;
					}
				}
			}
		}
	}
}

// min-width
@media screen and (min-width: $bp-mobile) {
	.download-list {
		&__top {
			h4 {
				max-width: 80%;
			}

			p {
				/*margin-bottom: 60px;*/
			}
		}

		th,
		td {
			padding: 15px 30px 15px 0; //30px 30px 30px 0;
		}

		&__item {
			padding: 30px 0;
		}
	}
}

@media screen and (min-width: $bp-tablet) {
	.download-list {
		/*padding: 60px 0;*/
		&__top {
			h4 {
				max-width: 60%;
				font-size: 30px;
				line-height: 40px;
				font-weight: 300;
			}
		}
	}
}

@media screen and (min-width: $bp-desktop-small) {
	.download-list {
		&__bottom {
			overflow: hidden;
		}

		&__desc {
			margin-top: 50px;
		}

		th,
		td {
			padding: 20px 30px 20px 0; //30px 30px 30px 0;
		}
	}
}

//print
@media print {
	.download-list {
		.ui-wrapper {
			display: none;
		}
		table {
			table-layout: auto;
		}
		th,
		td {
			width: auto;
		}
	}
}
