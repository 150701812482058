/**
* Dashboard 
*
* @section module
*  
*/

.dashboard{

    .dashboard-container {
		margin-top: 30px;
	}
	
	.report-buttons {
		position: absolute;
		top: 45px;
		right: 60px;
	}	
	
}