/**
* Title block
*
* @section module
*  
*/

.title-block {
	overflow: hidden;
	padding: 10px 0 20px; //25px 0px;
	text-align: center;
	&__title {
		@extend .h1;
		padding: 10px 0; //0 0 20px; //0 0 35px;
		color: inherit;
		margin: 0;
	}
	&__desc {
		margin: 0; //0 0 10px;
		padding: 10px;
		line-height: 1.7em; //30px;
		color: inherit;
	}
	.ui-wrapper {
		//margin-top: 30px;
		padding: 10px 0;
	}
}

.title-block {
	&.theme-black {
		background: $color-theme-black-bg;
		color: $color-font-white;
		a,
		.ui-btn--text,
		.ui-btn--secondary {
			color: $color-red;
		}
	}
	&.theme-white {
		background: $color-theme-white-bg;
		color: $color-font-black;
	}
}

@media screen and (max-width: $bp-mobile - 1px) {
	.title-block {
		&__title {
			//exception for this module
			//font-size: 24px;
			line-height: 32px;
			//font-weight: $font-weight-ultra-light;
		}
	}
}

// min-width
/*@media screen and (min-width: $bp-mobile) {
	.title-block {
		//padding: 120px 0 30px;
		&__title {
			//margin: 0 0 45px;
		}
		&__desc {
			//margin: 0 0 15px;
		}
	}
}*/

@media screen and (min-width: $bp-tablet) {
	.title-block {
		&__title {
			font-size: 42px; //48px;
			line-height: 50px;
		}

		&__desc {
			//margin: 0 0 15px;
			font-size: 20px;
			line-height: 30px;
			font-weight: 300;
		}
	}
}


@media screen and (min-width: $bp-desktop-small) {
	.title-block {
		//padding: 25px 0 40px;
		&__title {
			//margin: 10px 0; //0 20% 30px;
			font-size: 48px; //60px;
			line-height: 54px;
		}

		&__desc {
			padding: 0 60px 15px;
		}
	}
}

//language overrides
.ja-JP,
.zh-CN {
	.title-block__title {
		font-size: 24px;
		line-height: 22px;
	}

	@media screen and (min-width: $bp-tablet) {
		.title-block__title {
			font-size: 30px;
			line-height: 36px;
		}
	}

	@media screen and (min-width: $bp-desktop-small) {
		.title-block__title {
			font-size: 36px;
			line-height: 42px;
		}
	}
}
