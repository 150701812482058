/**
* Modules Index(Common)
*
* @section Setup
* @min
*/

// COMMON MODULES
@import 'modules/abstract';
@import 'modules/sidebar';
@import 'modules/header';
@import 'modules/footer';
@import 'modules/module-container';
@import 'modules/language-selector';
@import 'modules/popup';
@import 'modules/video';
@import 'modules/inline-search';
@import 'modules/search-overlay';
@import 'modules/search-result';
@import 'modules/featured-area';
@import 'modules/content';
@import 'modules/title-block';
@import 'modules/banner';
@import 'modules/block-quote';
@import 'modules/fact-cards';
@import 'modules/list';
@import 'modules/prefooter';
@import 'modules/intro-text';
@import 'modules/content-block';
@import 'modules/dropdown-table';
@import 'modules/download-list';
@import 'modules/accordian-list';
@import 'modules/product-accordian-list';
@import 'modules/csl-table';
@import 'modules/news-cards';
@import 'modules/mosaic';
@import 'modules/newshub';
@import 'modules/article';
@import 'modules/social-share';
@import 'modules/hero';
@import 'modules/internal-hero';
@import 'modules/subscription-block';
@import 'modules/csl-form';
@import 'modules/centralize';
@import 'modules/career-portal';
@import 'modules/product-pipeline';
@import 'modules/event-list';
@import 'modules/event-banner';
@import 'modules/stock-chart';
@import 'modules/sitemap';
@import 'modules/footnotes';
@import 'modules/timeline';
@import 'modules/simple-popup';
@import 'modules/cookie-policy';
@import 'modules/basic-content';
@import 'modules/video-brightcove';
@import 'modules/inline-video-image';
@import 'modules/dashboard';
