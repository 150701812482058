/**
* SVG Icon fonts - icomoon.io
* Use assets/fonts/selection.json file to import set to make changes
*/

@font-face
{
   font-family: 'iconfont';
   src: url('/app/common/fonts/iconfont.ttf'), url('/app/common/fonts/iconfont.woff?kefpg5'), url('/app/common/fonts/iconfont.svg?kefpg5#iconfont');
   font-weight: normal;
   font-style: normal;
}

@mixin icon() {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'iconfont' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Enable Ligatures ================ */
	letter-spacing: 0;
	-webkit-font-feature-settings: "liga";
	-moz-font-feature-settings: "liga=1";
	-moz-font-feature-settings: "liga";
	-ms-font-feature-settings: "liga" 1;
	font-feature-settings: "liga";
	-webkit-font-variant-ligatures: discretionary-ligatures;
	font-variant-ligatures: discretionary-ligatures;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

i,
.icon,
.icon-font,
%icon {
	@include icon();

	&.flip-x {
		transform: rotate(-180deg);
		display: inline-block;
	}
}

.icon-browse:before {
	content: "\e91b";
}

.icon-ic_home_black_24px:before {
	content: "\e91a";
}

.icon-date:before {
	content: "\e919";
}

.icon-magnifier-sm:before {
	content: "\e90b";
}

.icon-right-arrow:before {
	content: "\e90c";
}

.icon-close-sm:before {
	content: "\e90d";
}

.icon-linkedin:before {
	content: "\e90e";
}

.icon-Icon-twitter:before {
	content: "\e90f";
}

.icon-youtube:before {
	content: "\e910";
}

.icon-fb:before {
	content: "\e911";
}

.icon-fullscreen:before {
	content: "\e914";
}

.icon-Icon-play:before {
	content: "\e915";
}

.icon-pause:before {
	content: "\e916";
}

.icon-doc:before {
	content: "\e917";
}

.icon-volumn:before {
	content: "\e918";
}

.icon-caret-outline:before {
	content: "\e91e";
}

.icon-caret-solid-sm:before {
	content: "\e91f";
}

.icon-right-arrow-sm:before {
	content: "\e920";
}

.icon-email-outline:before {
	content: "\e921";
}

.icon-check:before {
	content: "\e922";
}

.icon-left-arrow:before {
	content: "\e000";
}

.icon-right-arrow-2:before {
	content: "\e001";
}

.icon-close-lg:before {
	content: "\e002";
}

.icon-download:before {
	content: "\e003";
}

.icon-email-solid:before {
	content: "\e004";
}

.icon-environment:before {
	content: "\e005";
}

.icon-finance:before {
	content: "\e006";
}

.icon-icon-play-circle:before {
	content: "\e009";
}

.icon-icon-print:before {
	content: "\e00a";
}

.icon-magnifier-lg:before {
	content: "\e00b";
}

.icon-share:before {
	content: "\e00c";
}

.icon-social:before {
	content: "\e00d";
}

.icon-globe:before {
	content: "\e007";
}

.icon-account:before {
	content: "\e00e";
}

.icon-circle-solid:before {
	content: "\e00f";
}

.icon-circle-outline:before {
	content: "\e010";
}

.icon-star:before {
	content: "\e011";
}

.icon-star-outline:before {
	content: "\e012";
}

.icon-check-2:before {
	content: "\e013";
}

.icon-check-outline:before {
	content: "\e014";
}

.icon-caret-down-lg:before {
	content: "\e015";
}

.icon-zoom:before {
	content: "\e016";
}

.icon-maintain:before {
	content: "\e017";
}

.icon-chat2:before {
	content: "\e018";
}

.icon-impaired:before {
	content: "\e019";
}

.icon-menu:before {
	content: "\e008";
}

.icon-checked:before {
	content: "\e90a";
}

.icon-close_bold:before {
	content: "\e909";
}

.icon-play:before {
	content: "\e912";
}

.icon-google-plus:before {
	content: "\ea8b";
}

.icon-twitter:before {
	content: "\ea96";
}

.icon-linkedin2:before {
	content: "\eaca";
}

.icon-facebook:before {
	content: "\ea90";
}

.icon-hamburger:before {
	content: "\e907";
}

.icon-close:before {
	content: "\e902";
}

.icon-chat:before {
	content: "\e901";
}

.icon-down-arrow:before {
	content: "\e903";
}

.icon-email:before {
	content: "\e904";
}

.icon-up-arrow:before {
	content: "\e905";
}

.icon-user:before {
	content: "\e906";
}

.icon-magnifier:before {
	content: "\e900";
}

.icon-arrow:before {
	content: "\e913";
}

.icon-lang:before {
	content: "\e908";
}
