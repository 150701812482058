/**
* Base(Common)
*
* variables and function definitions
*
* @section framework
*/

// ---------------------------------------------------------------------------
// Base variables
$base-font-size: 10px !default;
$base-line-height: 1.231em !default;

// ---------------------------------------------------------------------------
// Breakpoints (default is same as Bootstrap 3)
$bp-mobile: 480px !default;
$bp-tablet: 768px !default;

$bp-desktop-small: 1024px !default;
$bp-desktop-medium: 1280px !default;
$bp-desktop-large: 1440px !default;
$bp-desktop-xlarge: 1600px !default;

$gutter-20: 20px !default;
$gutter-30: 30px !default;
$gutter-40: 40px !default;
$gutter-50: 50px !default;
$gutter-60: 60px !default;
$gutter-70: 70px !default;
$gutter-80: 80px !default;
$gutter-90: 90px !default;
$gutter-100: 100px !default;
$default-gutter: $gutter-30 !default;

$header-height: 150px !default;
$header-sm-height: 90px !default;
$csl-table-min-width: 480px !default;
$csl-table-min-sm-width: 320px !default;

//z index
$page-index: 10 !default;
$header-index: 900 !default;
$footer-index: 700 !default;
$overlay-index: 1100 !default;
$search-overlay-index: 1100 !default;
$sidebar-index: 800 !default;

$transition-dur: 500ms;
$easing: cubic-bezier(.57,.32,.81,.65);

$ui-transition-dur: 250ms !default;
$ui-easing: cubic-bezier(0.15, 0.67, 0.67, 1) !default;

$slider-transition-dur: 600ms !default;
$slider-easing: cubic-bezier(0.15, 0.67, 0.67, 1) !default;

// ---------------------------------------------------------------------------
// Colors
$color-white: rgb(255, 255, 253) !default;
$color-black: rgb(0, 0, 0) !default;

//darkest -> lighest
$color-gray-0: rgb(41, 38, 30) !default;
$color-gray-1: rgb(52, 48, 39) !default;
$color-gray-2: rgb(58, 55, 46) !default;
$color-gray-3: rgb(226, 223, 218) !default;
$color-gray-4: rgb(252, 252, 250) !default;
$color-gray-5: rgb(241, 239, 234) !default;
$color-gray-6: rgb(226, 223, 221) !default;
$color-gray-7: rgb(249, 249, 247) !default;
$color-gray-8: $color-gray-4 !default;
$color-gray-9: rgb(207, 200, 189) !default;
$color-red: rgb(252, 25, 33) !default; //fc1921
$color-aa-red: #E7040B; // slightly darker then red. AA
$color-aa-dark-bg: #171717; // slightly darker then red. AA
$color-dark-red: #D8030A;

$color-theme-white-bg: $color-gray-8 !default;
$color-theme-black-bg: $color-aa-dark-bg !default;

// global focus color
// $color-global-focus: $color-red !default;
$color-global-focus: $color-aa-red !default;


// UI
//ui colors
$color-ui-gray: gray !default;
$color-ui-light-gray: #eee !default;
$color-ui-white: white !default;
$color-ui-red: $color-red !default;
$color-ui-black: rgba(0, 0, 0, 1) !default;
$color-grey-5: #eee !default;
$color-ui-blue-3: #eee !default;
$color-ui-btn-text: #111 !default;
$color-ui-btn-text-hover: #222 !default;
$color-btn-bg: green !default;
$color-ui-btn-bg-hover: $color-aa-red !default;

// hover, active, focus color
$color-button-link-text: $color-aa-red !default;

$font-size-cta: 12px !default;
$line-height-cta: 1.2em !default;

//vars
$ui-transition-dur: 250ms !default;
$ui-easing: cubic-bezier(0.15, 0.67, 0.67, 1) !default;

// Font colors
$color-font-gray: rgb(208, 201, 190) !default;
$color-font-white: $color-white !default;
$color-font-black: $color-gray-0 !default;

// ---------------------------------------------------------------------------
$font-montserrat: 'Montserrat', sans-serif;
$base-font: $font-montserrat!default;

// Font weights
$font-weight-black: 900 !default;
$font-weight-bold: 700 !default;
$font-weight-medium: 500 !default;
$font-weight-regular: 500 !default;
$font-weight-light: 300 !default;
$font-weight-ultra-light: 100 !default;
