/**
* News Cards
*
* note, tile is rendered using mosaic tile
*
* @section module
*  
*/

$t-img-width: 300px;
$t-height: 600px;
$t-gutter: 30px;
$t-width-min: 360px;
.news-cards {

	background: $color-gray-5; //modue top title/desc
	overflow: hidden;

	.module__top {
		text-align: center;
		overflow: hidden;
		padding: 0 40px;
		margin-bottom: 60px;
		h2 {
			@extend .h1;
			margin-right: auto;
			margin-left: auto;
			font-weight: 100;
			max-width: 60%;
		}
		p {
			max-width: 80%;
			margin: 0 auto;
			font-weight: 300;
		}
	}
	.grid {
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
	}
	.load-more-container {
		text-align: center;
		display: block;
		margin: 60px 0;
	} //tiles
	.m-tile {
		flex: 0 0 auto;
		margin: 0 $t-gutter/2 $t-gutter;
		&.not-include {
			display: none;
		} //see all
		&.see-all {
			background-color: $color-gray-0;
			.m-tile__content {
				padding: 0;
				text-align: center;
			}
			.m-tile__title {
				text-align: center;
				color: $color-white;
				width: 100%;
			}
			.ui-btn--circle-plus {
				margin-bottom: 30px;
			}
			a:not(.ui-btn) {
				display: block;
				color: $color-white;
				font-size: 15px;
				line-height: 25px;
			}
		}
	}
}

@media screen and (max-width: $bp-desktop-small - 1px) {
	.news-cards {
		.module__top {
			margin-bottom: 30px;
			h2 {
				max-width: none;
				margin-bottom: 30px;
			}
		}
		>.container {
			width: 100%;
			padding: 0;
		} //x scrolling
		.grid {
			flex-wrap: nowrap;
			flex-direction: row;
			align-items: flex-start;
			justify-content: flex-start;
			overflow-x: auto;
			padding-bottom: 30px;
		}
		.load-more-container {
			opacity: 0;
			pointer-events: none;
		}
		.m-tile {
			margin: 0 0 0 $t-gutter;
			width: $t-width-min;
			&:nth-of-type(1) {
				margin-left: 90px;
			} // display only up to 5th + see all card.
			&:nth-of-type(5)~.m-tile:not(.see-all) {
				display: none;
			}
			&.not-include {
				display: block;
			}
			&.see-all {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}

@media screen and (max-width: $bp-tablet - 1px) {
	.news-cards {
		//move to center
		.m-tile {
			&:nth-of-type(1) {
				margin-left: calc(50% - #{$t-width-min/2});
			}
		}
	}
}

//print
@media print {
	.news-cards {
		.grid {
			height: auto !important;
		}
		.m-tile {
			width: 100%;
			height: auto;
			position: static !important;
		}
		.m-tile__meta {
			width: 100%;
			height: auto;
			position: static;
			display: block;
		}
		.load-more-container {
			display: none;
		}
	}
}
