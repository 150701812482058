/**
* Cookie Policy
*
* @section module
*  
*/

.cookie-policy {
	display: block;
	position: fixed;
	background: $color-white;
	top: 0;
	left: 0;
	width: 100%;
	padding: 0px 15px;
	z-index: $overlay-index - 1;
	overflow: hidden;
	text-align: center;
	display: none;

	// rich text editor
	.module__inner {
		padding: 15px 0px 0px;

		a {
			color: $color-red;
			&:hover,
			&:focus {
				@include global-focus();
			}
		}

		p {
			font-size: 12px;
			margin-bottom: 1em;
		}
	}

	.ui-icon-btn {
		position: absolute;
		z-index: 1;
		top: 15px;
		right: 15px;
		color: $color-black;
	}

	&.active {
		display: block;
	}

}

@media screen and (min-width: $bp-mobile) {}

@media screen and (min-width: $bp-tablet) {}

@media screen and (min-width: $bp-desktop-small) {}
