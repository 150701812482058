/**
* Pop up
*
* @section module
*  
*/

.popup, .popup-brightcove
{
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: $overlay-index;
   background-color: rgba(0, 0, 0, 0.8);
   overflow: hidden;
   margin: 0;
   padding: 0;
   display: none;

   .popup__content
   {
      position: relative;
      width: 100%;
      max-width: 900px;
   }

   .close-btn
   {
      position: absolute;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      top: 10px;
      right: 10px;
      font-size: 16px;
      color: $color-white;
      text-decoration: none;
      z-index: 3;

      > i
      {
         font-size: inherit;
         pointer-events: none;
      }

      &:before
      {
         transition: transform 300ms;
      }

      &:hover,
      &:active
      {
         &:before
         {
            transform: rotate(90deg);
         }
      }
   }

   .video
   {
      margin: 0;
   }

   .video
   {
      display: block;
   }
}

.popup.active, .popup-brightcove.active
{
   display: flex;
   align-items: center;
   justify-content: center;
}

//print
@media print {
   .popup, .popup-brightcove
   {
      display: none;
   }
}
