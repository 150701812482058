/**
* Centralize
*
* @section module
*/
.centralize{
  padding: 60px 0;
  color: $color-gray-0;
  background-color: $color-gray-8;

  h1,
  h2,
  h3,
  h4{
    @extend .h2;
    color: inherit;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 30px;
  }

  p {
    font-size: 15px;
    line-height: 25px;
    font-weight: 300;
  }

  a {
    color: $color-aa-red;

	&:active,
	&:hover,
	&:focus {
		@include global-focus();
	}
  }
}


@media screen and (min-width: $bp-tablet) {
  .centralize{
    padding: 120px 0;

    h3,
    h4{
      margin-bottom: 60px;
    }
  }
}
