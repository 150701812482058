/**
* Site Header(CSL)
*
* @section module
*  
*/

// touch supprt/mobile
@mixin touchSupport() {
	>.container {
		width: 100%;
      @media screen and (max-width: $bp-desktop-small - 1px) {
         max-width: 750px;
      }
		height: $header-sm-height;
		>.col.left {
			width: auto;
		}
	}
	.header__logo {
		width: 90px;
		height: 90px;
        .logo-wedge {
            width: 120px;
        }
		.logo img {
			width: 70px; //50px;
			height: auto;
		}
	}
	.header-sub-nav {
		display: none;
	}
	.header-nav {
		ul {
			flex-direction: row-reverse;
		}
		li {
			display: none;
		}
		li+li {
			margin-left: 0;
		} //mobile menu & search & utility
		li:nth-last-child(1),
		li:nth-last-child(2),
		li:nth-last-child(3) {
			display: block;
			flex-grow: 0;
		}
	}
}

//animation
@keyframes headerExpandedIntro {
	0% {
		transform: translateY(-10px);
		opacity: 0.25;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes header-to-bottom {
	0% {
		bottom: 0;
		transform: matrix(1, 0, 0, 1, 0, 0);
	}
	100% {
		transform: translateY(100%);
	}
}

.header
{
   position: fixed;
   z-index: $header-index;
   top: 0;
   left: 0;
   width: 100%;
   height: auto;
   will-change: background-color;

   ul,
   li
   {
      margin: 0;
      padding: 0;
      list-style: none;
   }

   > .container
   {
      display: flex;
      height: $header-height;
      align-items: center;
      position: relative;

      > .col
      {
         flex: 1 1 auto;

         & + .col
         {
            margin-left: 30px;
         }

         &.left
         {
            flex: 0 0 auto;
            width: $header-logo-box-w;
         }
      }
   }

   //use case,
   //1. when search overlay is active
   //2. when there is no hero
   &.white:not(.open):not(.collapsed)
   {
      // background: red;
      .nav-link
      {
         color: $color-gray-0;
      }

      .header__logo
      {
         .logo-wedge {
             display: none;
            }
         .logo-wedge-no-hero {
             display: block;
            }
      }

      .header-sub-nav
      {

         a:not(:focus):not(:hover)
         {
            /*color: #cfc8bd;*/
            color: #29261e;
         }

         .ui-selector
         {
            .ui-selector-label,
            .l-list a
            {
               &:not(:focus):not(:hover)
               {
                  color: #29261e;
               }
            }
         }
      }
   }

   &.white
   {
      #header-lang-btn,
      #header-global-btn,
      #header-hamburger-btn,
      #header-search-btn
      {
         color: $color-red;
      }
   }
   //when active(hover, open)
   &.open
   {
      background: $header-hover-bg;

      .header__logo
      {
         rect
         {
            fill: $color-white;
         }
      }
      // all dark gray
      .nav-link,
      .header-sub-nav a,
      #header-lang-btn,
      #header-global-btn,
      #header-hamburger-btn,
      #header-search-btn
      {
         color: $color-gray-0;
      }

      .header-sub-nav
      {
         border-color: $header-border-color;

         .ui-selector
         {
            &:not(.active)
            {
               .ui-selector-label,
               .l-list a
               {
                  &:not(:focus):not(:hover)
                  {
                     color: $color-gray-0;
                  }
               }
            }
         }
      }
   }

   &.collapsed
   {
      background-color: $color-white;
      -webkit-box-shadow: 0px 5px 5px -2px rgba(0,0,0,0.2);
      -moz-box-shadow: 0px 5px 5px -2px rgba(0,0,0,0.2);
      box-shadow: 0px 5px 5px -2px rgba(0,0,0,0.2);

      > .container
      {
         height: 50px;

         @media screen and (min-width: 480px)
         {
            height: $header-sm-height;
         }
      }

      .header-sub-nav
      {
         a
         {
            &:not(:focus):not(:hover)
            {
               color: $color-gray-2;
            }
         }

         a.lang-selector
         {
            padding: 5px 35px 4px 0;
         }
         // ui selector
         // a. lang selector
         // b. login/logout selector
         .ui-selector
         {
            .ui-selector-label,
            .l-list a
            {
               &:not(:focus):not(:hover)
               {
                  color: $color-gray-2;
               }
            }
         }
      }

      #header-lang-btn,
      #header-global-btn,
      #header-hamburger-btn,
      #header-search-btn
      {
         color: $color-red;
      }

      .nav-link
      {
         color: $color-gray-0;
      }

      .header__logo
      {
          .logo-wedge, .logo-wedge-no-hero {
              display: none;
            }
      }

      .expanded-content[data-level="0"]
      {
         top: $header-sm-height;
         .expanded-content__row
         {
            background-color: $color-white;
            &.wedge
            {
               background: none;
               
               .wedge1
               {
                  display: none;
               }

               .wedge2
               {
                  display: block;
               }
            }
         }
      }

      .expanded-content[data-level="1"]
      {
         top: $header-sm-height;
      }
   }

   &.to-bottom
   {
      animation-name: header-to-bottom;
      animation-duration: 1000ms;
      animation-delay: 200ms;
      animation-iteration-count: 1;
      animation-timing-function: cubic-bezier(0.19, 0.74, 0.54, 0.95);
   }
}

.experienceeditor .header {
	position: static;
	background-color: $color-white;
	.header-nav .nav-link[data-level="0"] {
		.expanded-content {
			display: none !important;
		}
	}
	.header-sub-nav a {
		color: $color-black;
	}
	.nav-link[data-level="0"],
	.nav-link[data-level="1"] {
		color: $color-black;
	}
}

// show only logo
.sidebar-active .header,
.sidebar-active .header.collapsed {
    width: auto;
    background: transparent;
    box-shadow: none; 
    // same as top
    .header__logo {
        width: 90px;
        height: 90px; 
        .logo-wedge {
            display: none;
        }

        .logo {
            img {
                width: 70px; 
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
            }

            a {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
            }
        }
    }

    > .container {
        width: 100%;

        > .col.right {
            display: none;
        }
    }
}

.header__logo {
    width: $header-logo-box-w;
    height: $header-logo-box-h;
    position: relative;
    overflow: hidden;
    background: transparent;
    .logo-wedge, .logo-wedge-no-hero {
        img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
    }

    .logo-wedge-no-hero {
        display: none;
    }

    .logo {
        a {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;

            &:focus {
                // red & underline
                outline: none;
                text-decoration: none;
                border: 1px solid $color-global-focus;
            }
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            display: block;
            width: $header-logo-w;
            height: auto;
        }
    }
}

.header-sub-nav {
	border-bottom: 1px solid rgb(241, 239, 234);
	>ul {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding-right: 10px;
		li:nth-child(1) {
			flex: 0 0 auto;
		}
		li:nth-child(2) {
			flex: 1 0 auto;
		}
		li+li {
			margin-left: 20px 
		}
	}
	a {
		display: inline-block;
		font-size: 14px; 
		line-height: 18px;
		font-weight: $font-weight-light;
		color: $color-white;
		text-decoration: none;
		will-change: opacity;
		transition: opacity $header-t-duration;

		&:hover,
		&:focus {
			// red & underline
			@include global-focus();
			border: 0;
		}
	}
	a+a {
		margin-left: 30px;
	}
	.lang-selector {
		@include ui-btn-factory("icon");
		padding: 10px 28px 10px 0; 
		i {
			position: absolute;
			top: 50%;
			right: 0;
			transform-origin: 50%;
			font-size: 30px;
			margin-top: -15px;
		}
		&:focus,
		&:hover,
		&.active {
			border: 0;
			i {
				transform: rotate(180deg);
			}
		}
	}
	// ui selector
	// a. lang selector
	// b. login/logout selector
	.ui-selector {
		.ui-selector-label,
		.l-list a {
			font-weight: $font-weight-light;
		}

		&:not(.active) {
			.ui-selector-label,
			.l-list a {
				&:not(:focus):not(:hover) {
					color: $color-white;
				}
			}
		}

		// lang selector
		&.ui-lang-selector {
			.l-list {
				min-width: 150px;
			}
			// when it has only default lang
			&.has-limited-item {
				display: none;
			}

		}
		// user login selector
		&.ui-login-selector {
			width: auto;
			margin-right: -10px;
			.l-list {
				min-width: 300px;
				right: 0;
				left: auto;
				padding: 15px;
				>div:nth-last-child(1) {
					border-top: 1px solid $color-ui-light-gray;
					margin-top: 15px;
				}
			}
			&.active {
				margin-right: -15px;
			}
		}
	}
}

//nav in top row
.header-nav {
	ul {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
	}
	li+li {
		margin-left: 20px; //45px;
	}
	li:nth-child(1) {
		.nav-link[data-level="0"] {
			.label {
				padding-left: 0;
			}
			&:before {
				left: 0px;
				width: calc(100% - 10px);
			}
		}
	} // icons
	li:nth-last-child(1),
	li:nth-last-child(2),
	li:nth-last-child(3) {
		flex-grow: 1;
		text-align: right;
		display: none;
	} // search
	li:nth-last-child(2) {
		display: block;
	}
   /*li:nth-last-child(4) {
      margin-right: 100px;
   }*/
}

// mobile icons
#header-global-btn,
#header-search-btn,
#header-hamburger-btn {
	color: $color-white;
	display: inline-block;
	font-size: 20px;
	padding: 15px 7.5px;
	transition: color $header-t-duration;
	text-decoration: none;
	outline: none;
	i {
		color: inherit;
		pointer-events: none;
		vertical-align: middle;
	}
	&.hide {
		user-select: none;
		pointer-events: none;
	}
	&:focus,
	&.active,
	&:hover {
		color: $color-red;
	}
}

#header-search {
	text-align: right;
}

#header-global-btn {
	i {
		font-size: 18px;
		font-weight: 600;
	}
}

//Nav(main, expanded)
.nav-link
{
   position: relative;
   text-align: left;
   color: $color-white;
   display: block;
   letter-spacing: 0.5px;

   .label
   {
      display: inline-block;
      text-align: left;
      padding: 10px;
      font-size: 15px;
      font-weight: 500;
      color: inherit;
      line-height: 1.4em; //1.5em;
      white-space: inherit;
   }

   &.in-active
   {
      .label
      {
         color: #d2cec3;
      }
   }
   //top lv0
   &[data-level="0"]
   {
      color: $color-white;
      text-align: left;
      will-change: color;

      > .label
      {
         transition: color $header-t-duration;
         text-transform: uppercase;

         &:active,
         &:hover,
         &:focus
         {
            @include global-focus();
         }
      }
      //expanded content
      .expanded-content[data-level="0"]
      {
         display: none;
         //@include set-brand-clip(0);
      }
      //hit area
      &.has-children
      {
         &:after
         {
            display: block;
            content: "";
            width: 150%;
            width: 400px;
            height: 50px;
            position: absolute;
            bottom: -40px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 10;
            pointer-events: none;
         }
      }
      //bottom bar
      &:before
      {
         content: "";
         position: absolute;
         top: calc(50% + 15px);
         left: 10px;
         width: calc(100% - 20px);
         height: 2px;
         background-color: $color-red;
         pointer-events: none;
         z-index: 11;
         will-change: opacity;
         transition: opacity $header-t-duration;
         opacity: 0;
      }
      //show extended content
      &.hover-intent
      {
         // hit area & bar
         // &:before {
         // 	opacity: 1;
         // }
         > .label
         {
            @include global-focus();
         }

         &:after
         {
            pointer-events: auto;
         }

         .expanded-content[data-level="0"]
         {
            display: block;

            .expanded-content__row
            {
               .container 
               {
                  animation: headerExpandedIntro;
                  animation-duration: 1000ms;
                  animation-timing-function: cubic-bezier( 0.19, 0.74, 0.54, 0.95);
               }
              
               .already-in &
               {
                  .container
                  {
                     animation: none;
                  }
               }
            }
         }
      }
   }
   //lv1
   &[data-level="1"],
   &[data-level="2"]
   {
      color: $header-nav-item-color;
      border-top: 1px solid #ceccc9; //$header-border-color;
      .label
      {
         font-size: 15px;
         min-height: 54px; //60px;
         width: 100%;
         padding-right: 50px;
         display: inline-flex;
         align-items: center;
         transition: color $header-t-duration;
         z-index: 3;
      }
      // caret
      i
      {
         @extend .icon-caret-outline !optional;
         position: absolute;
         top: 50%;
         right: 3px;
         transform: translateY(-50%) rotate(-90deg);
         font-size: 26px;
         font-weight: 600;
         pointer-events: none;
         /*color: rgba(209, 202, 192, 1);*/
         color: #bcb5ac;
         opacity: 0;
         z-index: 0;

         &:before
         {
            color: inherit;
         }
      }
      // only lv0 duplicate
      &.duplicate[data-level="1"]
      {
         > .label > i
         {
            @extend .icon-ic_home_black_24px !optional;
            opacity: 1;
            font-size: 20px;
            transform: translateY(-50%);
         }
      }
      // &:not(.duplicate) {}
      &.has-children
      {
         > .label > i
         {
            opacity: 1;
         }
      }
      //show lv2 nav items
      &.hover-intent
      {
         .expanded-content[data-level="1"]
         {
            display: block;
            pointer-events: auto;
            opacity: 1; // transition: opacity $header-t-duration;
            body[class*="ie"] &
            {
               transition: none;
            }
         }
      }

      > .label
      {
         &:hover,
         &:focus
         {
            @include global-focus();
         }
      }


   }

   &[data-level="1"]
   {
      //text-transform: uppercase;
      font-weight: $font-weight-bold;
   }

   &[data-level="2"]
   {
      font-weight: $font-weight-medium;
   }
}

//lv0|1 extended subnav
.expanded-content[data-level="0"]
{
   position: fixed;
   width: 100%;
   //min-height: 450px; //555px;
   top: $header-height;
   left: 0;
   .expanded-content__row
   {
      height: 100%;
      background-color: $header-hover-bg;
      overflow: hidden;
      //padding-bottom: 20px; //60px;
      &.wedge
      {
         display: block;
         width: 100%;
         //height: 100px;
         background: none;

         .wedge2
         {
            display: none;
         }
      }
   }

   .container
   {
      height: calc(100% - 100px);
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      overflow: hidden; 
      // left menu col(lv1)
      > .col:nth-of-type(1) > .col__inner
      {
         overflow-x: hidden;
         overflow-y: auto; // @include webkit-custom-scrollbar(2, 0, 0, $color-red, transparent, 0);
      }
   }

   .col
   {
      flex: 0 0 auto;
      width: floor(100% / $header-col-length);
      margin-top: 30px;
      padding-left: 15px;
      padding-right: 15px;
   }
   

   .col__inner
   {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding-right: 15px;
   }
}

.expanded-content[data-level="1"]
{
   position: fixed;
   width: auto;
   max-width: (1200px / $header-col-length) * 2; // 2col
   top: $header-height;
   z-index: 999;
   will-change: opacity;
   opacity: 0;
   pointer-events: none;
   transform: translateX(calc(100% - 15px)); //translateX(calc(50% - 15px)); // ie
   body[class*="ie"] &
   {
      transform: translateX(100%) translateX(-15px); //translateX(50%) translateX(-15px);
      display: none;
   }
   //override
   .expanded-content__row
   {
      display: flex;
      flex-wrap: nowrap;
      z-index: 999;
      background: none;
   }

   .col__inner
   {
      width: 100%;
      height: 100%;
   }

   .col
   {
      flex: 0 0 auto;
      width: 100%; //50%;
      padding-bottom: 20px;
   }

   .col + .col
   {
      border: 0;
   }
   //no 3rd col, hide it if exist
   .col:nth-of-type(3)
   {
      display: none;
   }
   //hit area on the left
   &:before
   {
      content: "";
      width: 60px;
      height: 100%;
      position: absolute;
      top: 0;
      left: -40px;
   }
}

.header__teaser {
	width: 100%;
	height: auto;
	figure {
		height: 150px; //180px;
		position: relative;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50%;
		background-color: $color-gray-6;
		>a {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
		}
		img {
			width: 100%;
			height: auto;
			display: block;
			display: none;
		}
	}
	p {
		color: $color-gray-1;
		margin-top: 20px;
		font-weight: $font-weight-light;
		line-height: 1.5em;
	}
	.ui-btn {
		color: $color-red;
	}
}

.featureItemOne
{
   display: block;

   &.invisible
   {
      visibility: hidden;
   }
}

.featureItemTwo {
   display: block;
   padding-bottom: 15px;
}

@media screen and (min-width: $bp-desktop-small) {
	.header {
		.container {
			.col {
				max-width: 780px;
			}
		}
	}
}

@media screen and (min-width: 1200px) {
	.header {
		.container {
			.col {
				max-width: 955px;
			}
		}
	}
}

// ~ 1024
@media screen and (max-width: 1023px) {
	// touch supprt/mobile
	.header {
		@include touchSupport();
	}
}

//tablet && sm desktop
@media screen and (max-width: 1199px) {
	.header-nav li + li {
		margin-left: 0;
	}
}

//print
@media print {
	.header {
		padding: 0 !important;
		position: static;
		box-shadow: none !important;
	}
	.header-sub-nav,
	.header-nav {
		display: none;
	}
}

//language overrides
.ja-JP,
.zh-CN
{
   .header-nav li:nth-last-child(4)
   {
      margin-right: 0px;
   }
}

//language overrides
.el-GR,
.pl-PL,
.cs-CZ
{
   .nav-link .label, .nav-link[data-level="0"]>.label {
      text-transform: none;
   }
}