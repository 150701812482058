/**
* Career Portal
*
* @section module
*  
*/
.career-portal{
  padding: 10px 0 40px; //60px 0;
  color: $color-gray-0;
  background-color: $color-gray-8;
  text-align: center;

  h2{
    @extend .h1;
    color: inherit;
    margin-top: 0;
    margin-bottom: 15px; //30px;
	padding: 0 30px; //0 10px;
  }

  p {
    font-size: 15px;
    line-height: 25px;
    font-weight: 300;
  }

  .ui-btn {
    margin-top: 15px;
  }
}


@media screen and (min-width: $bp-mobile) {
}

@media screen and (min-width: $bp-tablet) {
  .career-portal{
    padding: 25px 0 50px; // 120px 0;
	

    .ui-btn {
      margin-top: 30px;
    }
	h2 {
		font-size: 42px; //48px;
		line-height: 50px;
		margin: 0;
	}
  }
}

@media screen and (min-width: $bp-desktop-small) {
	.career-portal {
		h2 {
			font-size: 48px; //60px;
			line-height: 54px;
		}
	}
}

//language overrides
.ja-JP,
.zh-CN {
	.career-portal {
		h2 {
			font-size: 24px;
			line-height: 30px;
		}
	}

	@media screen and (min-width: $bp-tablet) {
		.career-portal {
			h2 {
				font-size: 32px;
				line-height: 42px;
			}
		}
	}
}
