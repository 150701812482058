/**
* Search Overlay
*
* @section module
*  
*/

@keyframes s-result-item-animate-in {
	0% {
		opacity: 0;
		transform: translateY(-15px);
	}
	50% {
		transform: translateY(-15px);
		opacity: 0;
	}
	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

.search-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: $color-gray-4;
	z-index: $search-overlay-index;
	will-change: transform;
	display: none;
	pointer-events: none;
	>.container {
		width: 100%;
	}
	ul,
	li {
		padding: 0;
		margin: 0;
		list-style: none;
	}
	@keyframes flip-y {
		0% {
			transform: rotateX(90deg);
		}
		100% {
			transform: rotateX(0);
		}
	} // active & animate in
	&.active {
		display: flex;
		align-items: center;
		pointer-events: auto; //coming in from top
		@include animate-in('animate-y-in-minus', 400ms);
	}
	&.active.animate-in {
		>.container {
			opacity: 1;
			transform: translateY(0);
			transition: all 600ms;
		}
	}
	>.container {
		opacity: 0;
		transform: translateY(60px);
	}
	.close-btn {
		position: absolute;
		bottom: 30px;
		left: 50%;
		transform: translateX(-50%);
		font-size: 24px;
		color: $color-gray-0;
	}
}

.search__suggestion {
	height: calc(101px * 3); // max 3 list items
	overflow-x: hidden;
	overflow-y: auto;
	@include webkit-custom-scrollbar(2, 0, 0, $color-red, transparent, 0); //NOTE, phase 1 we don't do type-ahead. so hide it.
	display: none;
	ul {
		overflow: hidden;
	}
	li {
		box-sizing: border-box;
		border-bottom: 1px solid #e2dfda;
		padding: 15px 47.5px;
		@include animate-in('s-result-item-animate-in', 600ms);
		@for $i from 1 to 999 {
			&:nth-of-type(#{$i}) {
				animation-duration: #{$i * 600}ms;
			}
		}
	}
	a,
	.suggestion__link {
		text-decoration: none;
		position: relative;
		display: inline-block;
		font-size: 15px;
		line-height: 25px;
		font-weight: 300;
		color: rgba(0, 0, 0, 1);
		&:hover,
		&.active,
		&:focus {
			outline: none;
			color: $color-red;
		}
		span {
			pointer-events: none;
			display: inline-block;
			&.match {
				color: $color-red;
			}
			&+span {
				margin-left: 5px;
			}
		}
	}
}

.search__popular {
	//width: 100%;
	display: flex;
	flex-wrap: no-wrap;
	align-items: center;
	margin: 0; //margin-top: 30px;
	padding: 10px;

	.col {
		flex: 0 0 auto;
		overflow: hidden;
		&:nth-of-type(1) {
			display: none;
		}
		&:nth-of-type(2) {
			//width: 100%;
			position: relative;
			//padding: 0 30px;
			height: 30px; //carets only for mobile
			&:after,
			&:before {
				@include icon();
				content: "";
				position: absolute;
				width: 20px;
				line-height: 30px;
				font-size: 20px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				z-index: 3;
				opacity: .25;
			} // left
			&:before {
				content: "\e000";
				left: 0px;
			} // right
			&:after {
				content: "\e001";
				right: 0px;
			}
		}
	}
	ul {
		display: flex;
		flex-wrap: no-wrap;
		align-items: center;
		height: 100%;
		overflow-x: auto;
		overflow-y: hidden;
	}
	li {
		flex: 0 0 auto;
		white-space: nowrap;
		width: auto;
		margin: 7.5px 15px;
		>* {
			display: block;
		}
	}
	p {
		text-transform: uppercase;
		font-size: 12px;
		line-height: 15px;
		color: $color-black;
		margin: 0;
	}
	a,
	.ui-btn--secondary {
		@include secondary-btn-color('black-red');
		font-size: 12px;
		line-height: 15px;
		font-weight: 500;

		&:active,
		&:hover,
		&:focus {
			@include global-focus();
		}
	}
}

//experience mode
.experienceeditor .search-overlay {
	display: flex;
	align-items: center;
	pointer-events: auto;
	position: static;
	width: 100%;
	height: auto;
	padding: 30px;
	background-color: rgba(0, 0, 0, 1);
	opacity: 1;
	.ui-input.search {
		opacity: 1;
		transform: none;
	}
}

@media screen and (min-width: $bp-tablet) {
	.search__suggestion {
		height: calc(101px * 3); // max 3 list items
		li {
			box-sizing: border-box;
			border-bottom: 1px solid #e2dfda;
			padding: 30px 92.5px;
			@include animate-in('s-result-item-animate-in', 600ms);
			@for $i from 1 to 999 {
				&:nth-of-type(#{$i}) {
					animation-duration: #{$i * 600}ms;
				}
			}
		}
		a,
		.suggestion__link {
			text-decoration: none;
			position: relative;
			display: inline-block;
			font-size: 30px;
			line-height: 40px;
			font-weight: 300;
			color: rgba(0, 0, 0, 1);
		}
	}
	.search__popular {
		//margin-top: 60px;
		padding: 30px 0 10px;

		.col {
			&:nth-of-type(1) {
				display: block;
				padding-right: 20px; //width: 250px;
			}
			/*&:nth-of-type(2) {
				width: calc(100% - 230px);
			}*/
		}
		ul {
			flex-direction: row;
		}
		li {
			margin: 0; //0 30px;
			padding: 5px 35px 5px 0;
		}
		p,
		a,
		.ui-btn--secondary {
			font-size: 15px;
			line-height: 20px;
			padding: 0;
		}
	}
}

@media screen and (min-width: $bp-desktop-small + 1px) {
	.search__popular {
		.col {
			&:nth-of-type(2) {
				// carest, hide
				&:before,
				&:after {
					opacity: 0;
				}
			}
		}
	}
}

@media screen and (max-width: $bp-tablet - 1px) {
	.search__popular {
		display: none;
	}
}

//print
@media print {
	.search-overlay {
		display: none;
	}
}

//language overrides
.ja-JP,
.zh-CN {

	.ui-input input {
		font-size: 16px;
	}

	@media screen and (min-width: $bp-tablet) {
		.ui-input input {
			font-size: 18px;
		}
	}

	@media screen and (min-width: $bp-desktop-small) {
		.ui-input input {
			font-size: 20px;
		}
	}
}
