/**
* Sitemap
*
* @section module
*  
*/

$sitemap-col-nth: 1;
$sitemap-basic-spacing: 30px;
$sitemap-col-gap: $sitemap-basic-spacing/2;
$sitemap-border-color: $color-red;
.sitemap {
	padding: $header-sm-height 0;
	background: $color-gray-4;
	&__inner {
		display: block;
		overflow: hidden;
	}
	h1,
	h2 {
		@extend .h3;
		font-weight: 300;
		margin-bottom: 60px; //underline
	}
	a {
		@include ui-btn-factory('secondary');
		font-size: 14px;
	}
	&-group {
		&.lv-0 {
			margin-bottom: $sitemap-basic-spacing * 2;

			>.g-label {
				position: relative;
				margin-bottom: $sitemap-basic-spacing / 2; // space between line and the group
				>a {
					text-transform: uppercase;
				}
			}
		}

		&.lv-1 {
			margin-bottom: $sitemap-basic-spacing * 2;
			>.g-label {
				margin-bottom: $sitemap-basic-spacing / 2; // space between line and the group
				>a {
					text-transform: uppercase;
				}
			}
		} // col item
		&.lv-2 {
			margin-bottom: $sitemap-basic-spacing / 2;
			padding-left: $sitemap-basic-spacing; // indent

			>.g-label+.g-children {
				margin: $sitemap-basic-spacing / 2 0; // spacing  between label and children
			}
		}

		&.lv-3{
			margin-bottom: $sitemap-basic-spacing / 2;
			padding-left: $sitemap-basic-spacing + 5px; // indent
			>.g-label {
				position: relative;
				&:before {
					content: "";
					position: absolute;
					top: 8px;
					left: -10px;
					width: 4px;
					height: 4px;
					border-radius: 50%;
					background: $color-red;
				}
			}
			>.g-label+.g-children {
				margin: $sitemap-basic-spacing / 2 0; // spacing  between label and children
			}
		}
		&.lv-4,
		&.lv-5 {
			margin-bottom: $sitemap-basic-spacing / 2;
			padding-left: $sitemap-basic-spacing + 5px; // indent

			>.g-label {
				position: relative;
				&:before {
					content: "";
					position: absolute;
					top: 10px;
					left: -10px;
					width: 5px;
					height: 1px;
					border-radius: 50%;
					background: $color-red;
				}
			}
			>.g-label+.g-children {
				margin: $sitemap-basic-spacing / 2 0; // spacing  between label and children
			}
		}
	} // old one using list
}

@media screen and (min-width: $bp-tablet) {}

@media screen and (min-width: $bp-desktop-small) {}

//language overrides
.el-GR,
.pl-PL,
.cs-CZ
{
   .sitemap-group.lv-0>.g-label>a, .sitemap-group.lv-1>.g-label>a {
      text-transform: none;
   }
}
