/**
* Video
*
* @section module
*/

@import "../extra/plyr/plyr.scss"; //this is required
.video {
	//controls
	$control-height: 60px;
	$track-height: 2px;
	$thumb-width: 8px;
	$thumb-height: 8px;
	$thumb-color: $color-gray-2;
	position: relative;
	width: 100%;
	height: auto;
	overflow: hidden;
	.ui-wrapper {
		display: block;
		width: 100%;
		margin-top: 30px;
	}
	/*
    Override plyr control styles
    @see node_modules/plyr/src/scss/plyr.scss
  */
	video {
		width: 100%;
		height: auto;
	}

	//volumn track
	.plyr__volume--display {
		height: $track-height;
		color: rgba(226, 223, 218, 1);
		margin: 0;
	}

	.plyr__controls [data-plyr='pause'],
	.plyr--playing .plyr__controls [data-plyr='play'] {
		display: inline-block;
	}

	// overlay play large btn
	.plyr__control--overlaid,
	.plyr__play-large {

		border-radius: 50%;
		box-shadow: 0 1px 1px rgba(0, 0, 0, .15);
		color: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		border: 0;
		padding: 20px;
		box-shadow: none;
		background: rgba(255, 255, 255, 1);

		svg {
			position: relative;
			left: 2px;
			width: 32px;
			height: 32px;
			display: block;
			fill: rgba(0, 0, 0, 1);
		}
	}

	.plyr--playing .plyr__control--overlaid {
		opacity: 0;
		visibility: hidden;
	}

	.plyr__controls {
		height: $control-height;
		padding: 0 30px;
		background: rgba(255, 255, 255, 1);

		>label {
			display: none;
		}

		button {
			border-radius: 0;
			padding: 0;
			&[data-plyr="play"],
			&[data-plyr="pause"],
			&[data-plyr="mute"],
			&[data-plyr="fullscreen"] {
				svg {
					color: $color-black;
					width: 32px;
					height: 32px;
				}
			}
			&[data-plyr="captions"] {
				svg {
					color: $color-black;
					width: 18px;
					height: 18px;
				}
			}
			&[data-plyr="fullscreen"] {
				// exit
				&[aria-pressed=true] .icon--pressed {
					width: 20px;
					height: 20px;
					margin-left: 4px;
				}
			}

			&.plyr__tab-focus,
			&[aria-expanded=true],
			&:hover {
				background-color: transparent!important;
			}
		}
	}

	// play state
	.plyr__control[aria-pressed=false] .icon--pressed,
	.plyr__control[aria-pressed=false] .label--pressed,
	.plyr__control[aria-pressed=true] .icon--not-pressed,
	.plyr__control[aria-pressed=true] .label--not-pressed {
		display: none;
	}

	.plyr__time {
		flex-grow: 1;
		text-align: right;
		display: none;
	}
	.plyr__progress {
		display: block;
		border: 0;

		input[type=range] {
			width: 100%;
			-webkit-appearance: none;
			appearance: none;
			z-index: 3;
			border-radius: 0;
			background: transparent;
			color: red;

			&::-webkit-slider-runnable-track {
				border-radius: 0;
				border: 0;
				height: $track-height;
				background-color: rgba($color-gray-3, .35);
				transition: all .3s ease;
				-webkit-user-select: none;
				user-select: none;
			//jm	background-image: linear-gradient(to right, $color-gray-0 var(--value, 0), transparent var(--value, 0));
			}
			&::-moz-range-track {
				border-radius: 0;
				border: 0;
				height: $track-height;
				background-color: rgba(255, 255, 255, .25);
				transition: all .3s ease;
				-webkit-user-select: none;
				user-select: none;
	   	//jm	background-image: linear-gradient(to right, $color-gray-0 var(--value, 0), transparent var(--value, 0));
			}
			// base
			&::-ms-fill-upper {
				height: $track-height;
				background: rgba(226, 223, 218, 1);
				border-radius: 0;

			}
			// played
			&::-ms-fill-lower {
				height: $track-height;
				background-color: rgba(255, 255, 255, 1);
				border-radius: 0;
				background: $color-gray-2;
			}
			//thumbs
			&::-webkit-slider-thumb {
				border-radius: 0;
				margin: 0;
				width: $thumb-width;
				height: $track-height;

				cursor: pointer;
				border: none;
				box-shadow: none;
				-webkit-appearance: none;
				appearance: none;
				background: $thumb-color;
			}
			&::-moz-range-thumb {
				border-radius: 0;
				margin: 0;
				width: $thumb-width;
				height: $track-height;

				cursor: pointer;
				border: none;
				box-shadow: none;
				-webkit-appearance: none;
				appearance: none;
				background: $thumb-color;
			}
			&::-ms-thumb {
				border-radius: 0;
				margin: 0;
				width: $thumb-width;
				height: $track-height;

				cursor: pointer;
				border: none;
				box-shadow: none;
				-webkit-appearance: none;
				appearance: none;
				background: $thumb-color;
			}
		}

		//progress
		.plyr__progress--buffer,
		.plyr__progress--played {
			top: calc(50% - #{$track-height}/2);

			left: 0;
			width: 100%;
			height: $track-height;
			border: none;
			margin: 0;
			padding: 0;
			border-radius: 0;
			-webkit-appearance: none;
			appearance: none;

			&::-webkit-progress-bar {
				border-radius: 0;
			}
			&::-moz-progress-bar {
				border-radius: 0;
			}
			&::-webkit-progress-value {
				border-radius: 0;
			}
			&::-moz-progress-value {
				border-radius: 0;
			}
			&::-ms-progress-bar {
				border-radius: 0;
			}
			&::-ms-progress-value {
				border-radius: 0;
			}
		}

		// buffer(loaded)
		.plyr__progress--buffer {
			color: $color-gray-3;
		}

		.plyr__progress--seek {
			opacity: 0;
		}
	}
	&.no-control {
		.plyr__controls {
			display: none !important;
		}
	}
}

.video__container {
	position: relative;
	box-sizing: border-box;
}

//experienceeditor
.experienceeditor {
	.video__container {
		background-image: url('/app/common/images/video-placeholder.jpg');
		background-repeat: no-repeat;
		background-size: contain;
		background-color: transparent;
	}
}

@include media(sm,
true) {
	.video {
		margin: 0 auto;
		display: block;
	}
	.video__container {
		width: 100%;
		min-height: 0;
	}
}

@media print {
	.video {
		display: none;
	}
}
