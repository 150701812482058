/**
* UI Components index
*
* @section Setup
* @min
*/

@import 'components/config';
@import 'components/loader';
@import 'components/button';
@import 'components/filter';
@import 'components/breadcrumb';
@import 'components/dropdown';
@import 'components/input';
@import 'components/social';
@import 'components/dots';
@import 'components/checkbox';
@import 'components/pagination';
@import 'components/selector';

.ui-wrapper{
  display: inline-block;

  &.vertical {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .ui-btn + .ui-btn{
      margin-top: 20px;
    }
  }
}

//ui test
/*.ui-test{
  background: #eee;
  padding: 60px;

  i {
    display: inline-block;
    padding: 10px;
    margin: 20px;
    border: 1px solid #333;
    font-size: 18px;
  }

  &.white {
    background: #111;
    color: white;

    i {
      color: white;
    }
  }
}*/

/*custom Rich Text Editor CSS styles*/
.RTEimage-Left-XSmall, .RTEimage-Left-Small, .RTEimage-Left-Large, .RTEimage-Left-XLarge,
.RTEimage-Right-XSmall, .RTEimage-Right-Small, .RTEimage-Right-Large, .RTEimage-Right-XLarge
{
   img
   {
      width: 98% !important;
      height: auto !important;
      padding: 12px 0 !important;
      margin: 0 !important;

      @media screen and (min-width: 624px)
      {
         width: auto !important;
      }
   }
}

@media screen and (min-width: 624px) {
   .RTEimage
   {
      &-Left
      {
         &-XSmall img
         {
            height: 125px !important;
            float: left !important;
            padding: 8px 16px 0 0 !important;
         }

         &-Small img
         {
            height: 210px !important;
            float: left !important;
            padding: 8px 16px 0 0 !important;
         }

         &-Large img
         {
            height: 280px !important;
            float: left !important;
            padding: 8px 16px 0 0 !important;
         }

         &-XLarge img
         {
            height: 415px !important;
            float: left !important;
            padding: 8px 16px 0 0 !important;
         }
      }

      &-Right
      {
         &-XSmall img
         {
            height: 125px !important;
            float: right !important;
            padding: 8px 16px 0  !important;
         }

         &-Small img
         {
            height: 210px !important;
            float: right !important;
            padding: 8px 16px 0 !important;
         }

         &-Large img
         {
            height: 280px !important;
            float: right !important;
            padding: 8px 16px 0 !important;
         }

         &-XLarge img
         {
            height: 415px !important;
            float: right !important;
            padding: 8px 16px 0 !important;
         }
      }
   }
}

/*.Divider {
    display: block;
    background-color: #ddd;
    width: 98%;
    height: 1px;
    margin: 32px 0;
}*/
