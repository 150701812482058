/**
* Product Accordion
*
* @section module
*  
*/

$t-border: 1px solid #e0e0e0;
.p-accordian-list {
	padding: 10px 0 20px;

	&__top {
		padding: 0 0 15px;

		h4 {
			font-size: 24px;
			line-height: 32px;
			font-weight: 500;
			margin: 0;
			@media screen and (min-width: $bp-tablet) {
				font-size: 30px;
				line-height: 40px;
				font-weight: 300;
			}
		}

		p {
			margin-bottom: 20px; 
			max-width: 768px;
			font-weight: 300;
		}
	}

	&__title {
		font-weight: 500;
		margin-top: 0;
		margin-bottom: 15px;
		font-size: 15px;
	}

	&__desc {
		font-weight: 300;
		margin-top: 30px;
		margin-bottom: 0;
	}
	// load more
	.ui-wrapper {
		display: block;
		text-align: center;
		margin-top: 15px;
	}

	// caret in th
	a.anchor {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		transform-origin: 50%;
		will-change: transform;
		z-index: 1;
		color: $color-aa-red;
		width: 100%;
		text-align: right;
		padding: 8px 0;

		> em,
		> .icon {
			text-align: center;
			font-size: 24px;
			pointer-events: none;
			text-decoration: none;
			outline: none;
			float: right;
		}
	}

	table {
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;
		border: 0;
		table-layout: fixed;
	}
	//outer table
	table[data-lv="0"] {
		> thead {
			border-bottom: $t-border;
			display: none;
		}
		> tbody {
			border-top: $t-border;
			border-bottom: $t-border;
			overflow: hidden;

			&.active {
				a.anchor > em,
				a.anchor > .icon {
					display: inline-block;
					transform: rotate(180deg);
				}

				.expanded-row[data-lv="0"] {
					display: table-row;
					will-change: tranform;
					transform-origin: 50% 0;
					animation: 300ms linear csl-table-row-open;
				}
			}
		}
	}

	th,
	td {
		font-size: 15px;
		line-height: 25px;
		padding: 10px 30px 10px 3px;
		position: relative;
		text-align: left;
	}

	p {
		margin: 0;
		font-size: 15px;
		padding: 0;
		line-height: 1.5em;
	}

	.header-row {
		td {
			line-height: 1.5em;
			padding: 10px 30px 10px 3px; 
			@media screen and (min-width: $bp-tablet) {
				padding: 15px 30px 15px 3px;
			}
		}
	}

	.expanded-row {
		td {
			padding: 0;
			vertical-align: top;
		}

		&[data-lv="0"] {
			display: none;
		}
	}
	///////INNER///////
	// inner table
	table[data-lv="1"] {
		width: 90%;
		a {
			color: #E7040B;
			font-size: 15px;
			line-height: 18px;
			font-weight: 500;
		}
		td {
			@media screen and (max-width: $bp-tablet - 1px) {
				display: block;
			}
			&.country-list {
				@media screen and (min-width: $bp-tablet) {
					column-count: 3;
				}
			}
		}
	}
	// inner row
	.ir {
		overflow: hidden;
		padding: 0 10px 15px 10px;
		.ir-toggle {
			color: $color-gray-0;
			display: inline-block;
			padding-left: 30px;
			position: relative;

			&:before,
			&:after {
				content: "";
				position: absolute;
				width: 12px;
				height: 2px;
				top: 50%;
				left: 0;
				margin-top: -1px;
				background-color: $color-aa-red;
				font-size: 24px;
				text-align: center;
				color: $color-aa-red;
				text-align: left;
			}
			
			&:after {
				transform: rotate(90deg);
				transition: transform 150ms;
			}

			&:hover,
			&:active,
			&:focus {
				@include global-focus();
			}
		}

		h6 {
			margin-top: 0;
			margin-bottom: 0;
			font-size: 15px;
			font-weight: 500;
		}

		p {
			margin: 15px 0;
			font-weight: 300;
		}
		// open
		&.active {
			.expanded-row[data-lv="1"] {
				display: block;
				padding: 10px 0 0 32px;
			}

			.ir-toggle:before {
				opacity: 0;
			}

			.ir-toggle:after {
				transform: rotate(0);
			}
		}
		// us only row
		&:nth-of-type(2) {
			tr {
				font-weight: 300;

				td:nth-of-type(1) {
					font-weight: 500;
				}

				td:nth-last-of-type(1) {
					padding: 0;
				}
			}
		}

		.links {
			display: inline-flex;
			flex-direction: column;
			align-items: flex-start;
			text-align: left;

			span, a {
				padding: 5px 0;
				font-size: 13px;
				line-height: 16px;
				@media screen and (min-width: $bp-mobile) {
					font-size: 15px;
					line-height: 18px;
				}
			}

			// external & doc icon
			.icon {
				font-size: 24px;
				vertical-align: middle;
				pointer-events: none;
			}
		}
	}

	.expanded-row[data-lv="1"] {
		display: none;
	}
}

.p-accordian-list {
	&.open-all {
		.expanded-row[data-lv="0"] {
			display: table-row;
		}
		.expanded-row[data-lv="1"] {
			display: block;
		}
	}
}

//print
@media print {
	.p-accordian-list {
		.ui-wrapper {
			display: none;
		}
		a.anchor {
			display: none;
		}
		.expanded-row {
			display: block !important;
		}
		table {
			table-layout: auto;
		}
		th,
		td {
			width: auto;
		}
	}
}
