/**
* Brightcove Video
*
*/

.video-brightcove
{
	.video-js
	{
		.vjs-tech
		{
			position: relative;
		}
	}

	.bc-player-default_default
	{
		width: 100%;
		height: 100%;
	}

	.media-reponsive-container
	{
		padding:0;
		margin:0;
		height:auto;
	}
}
