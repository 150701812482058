/**
* Event Banner
*
* left | right
*
* @section module
*  
*/

.event-banner {
	padding-top: 30px;

	&__img {
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
	&__content {
		padding: 30px 20px;
		p {
			font-weight: 300;
		}
	}
	&__t {
		margin-top: 0;
		margin-bottom: 15px;
		font-weight: 300;
	}
	&__st {
		font-weight: 300;
	}
	// &__date {}
	.ui-wrapper {
		display: inline-flex;
		flex-direction: column;
		.ui-btn+.ui-btn {
			margin: 30px 0 0 0;
		}
	}
	.social-share {
		margin: 30px 0 15px -15px; // hide label, facebook for now
		li[data-type="facebook"],
		li[data-type="label"] {
			display: none;
		}
	}
	// variation placehoder
	// &.right {}
	// &.left {}
}

//not using(color)
// .event-banner {
// 	&.theme-black {}
// 	&.theme-white {}
// 	&.theme-gray {}
// }

@media screen and (min-width: $bp-tablet) {
	.event-banner {
		padding-top: 30px;

		&__row {
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			align-items: stretch;
		}
		&__img {
			flex: 0 0 auto;
			width: calc(50% - 30px);
			margin-left: 30px;
		}
		&__content {
			flex: 0 0 auto;
			width: 50%;
			overflow: hidden;
			align-self: flex-start;
			padding: 0 30px 30px;
		}
		.ui-wrapper {
			display: inline-flex;
			flex-direction: row;
			.ui-btn+.ui-btn {
				margin: 0 0 0 30px;
			}
		}
	}
	.event-banner.right {
		.event-banner__row {
			flex-direction: row-reverse;
		}
	}
}

@media screen and (min-width: $bp-desktop-small + 1px) {
	.event-banner {
		padding-top: 60px;

		&__row {
			display: flex;
			flex-direction: row;
			align-items: stretch;
		}
		&__img {
			flex: 0 0 auto;
			width: 50%;
			margin: 0;
			max-height: none;
		}
		&__content {
			flex: 0 0 auto;
			width: 50%;
			overflow: hidden;
			align-self: center;
			padding: 0 120px 60px 120px;
		}
	}
}
