/**
* Social Share
*
* @section module
*  
*/

.social-share {
	ul,
	li {
		padding: 0;
		margin: 0;
	}
	li {
		list-style: none;
	}
	ul {
		display: inline-flex;
	}
	&.vertical {
		display: inline-block;
		ul {
			display: flex;
			flex-direction: column;
		}
		li {
			margin: 0;
		}
	}
}

@media screen and (min-width: $bp-mobile) {}

@media screen and (min-width: $bp-tablet) {}
