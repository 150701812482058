/**
* Intro Text
*
* @section module
*  
*/

$col-gap: 130px;
$col-gap-sm: 40px;
.intro-text {
	background-color: rgba(252, 252, 252, 1);

	.module__top {
		padding: 20px 0; //30px 0 10px; //60px 45px;
	}
	//title
	&__t {
		@extend .h1;
		/*font-weight: 100;*/
		color: inherit;
		margin: 0;
		padding: 10px 0;
	}

	&__st {
		font-weight: 300;
		color: inherit;
		margin: 0;
		padding: 10px 0;
	}

	&__rich {
		padding: 10px 0;

		/*em {
			font-style: normal;
		}*/

		p {
			color: inherit;
			font-weight: 300;
			margin: 0; //30px 0;
		}

		a {
			color: $color-aa-red;

			&:active,
			&:hover,
			&:focus {
				@include global-focus();
			}
		}

		h1 {
			margin: 0;
			padding: .5em 0;
		}

		h4 {
			margin: 0;
			padding: .6em 0;
		}
	}

	/*.hero + &,
	.internal-hero + &,
	&.wedged {
		//@include set-brand-clip(30px, 'top-left');
		margin-top: -40px;

		.module__top {
			padding: 60px 0px 10px;
		}
	}*/
}

.intro-text {
	&.theme-black {
		background: $color-theme-black-bg;
		a {
			color: $color-red;
		}
		&,
		.c-breadcrumb li,
		.c-breadcrumb a {
			color: $color-font-white;
		}
	}
	&.theme-white {
		background: $color-theme-white-bg;
	}
}

@media screen and (min-width: $bp-tablet) {
	.intro-text {
		.module__top {
			//padding: 40px 0 20px; //60px $col-gap-sm 60px 0;
		}
		//title
		&__t {
			max-width: 85%;
			//margin-top: 35px;
			font-size: 48px;
			padding: 15px 0; //20px 0;
		}

		&__st {
			max-width: 85%;
			line-height: 1.3em;
			font-size: 28px;
		}

		/*&__rich {
			p:nth-of-type(1) {
				column-count: 2;
				column-gap: $col-gap-sm;
				max-width: 85%;
			}
		}*/

		/*.internal-hero + &,
		&.wedged {
			margin-top: -70px; //1px extra to fix thin line
			//@include set-brand-clip(60px, 'top-left');
			z-index: 1;

			.module__top {
				padding: 90px 0 20px;
			}
		}*/
	}
}

@media screen and (min-width: $bp-desktop-small) {
    .intro-text {
        .module__top {
            padding: 25px 0; //40px 0 10px; //60px $col-gap 60px 0;
        }

        &__t {
            max-width: 80%;
            padding: 20px 0; //30px 0;
            font-size: 54px; //60px;
        }

        &__rich {
            p {
                max-width: 85%;
            }
        }
    }
}

//language overrides
.ja-JP,
.zh-CN {
	.intro-text__t {
		font-size: 24px;
		line-height: 32px;
	}

	.intro-text__st {
		font-size: 20px;
		line-height: 28px;
	}

	@media screen and (min-width: $bp-tablet) {
		.intro-text__t {
			font-size: 28px;
			line-height: 36px;
		}

		.intro-text__st {
			font-size: 22px;
			line-height: 30px;
		}
	}
}
