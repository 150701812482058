/*
* CSLBehring project stylesheet combiner
*
* @section Setup
*/

// import project base config & variables
@import 'base';

// header config
@import 'modules/header-config';
@import 'modules/footer-config';

// import all default(common)
@import '../common/style';

// specific modules per projects
@import 'generic';
@import 'modules';
