/**
* Main project stylesheet combiner
*
* @section Setup
* @author <min>
*/

// Framework
@import 'framework/framework';
@import 'framework/media-queries';
@import 'framework/normalize';
@import 'framework/custom-bootstrap';//custom bootstrap

// Default typography for the site
@import 'framework/icon';
@import 'framework/typography';

@import 'mixin/slider'; // common mixin
@import 'generic'; // common generic
@import 'ui-components'; // common ui components
@import 'modules'; // common modules
@import 'pages'; // commont pages

//print
@import 'print';
