/**
* Featured Area
*
* @section module
*  
*/

//2 variations
//left | right
$content-height-lg: 520px;
$content-offset-lg: 80px;
$content-height-sm: 360px;
$content-offset-sm: 90px;
$f-offsetX: 100px;
$f-offsetY: 50px;
$f-anim-duration: 1200ms;
$f-anim-easing: ease-in-out;
@keyframes f-anim1 {
	0% {
		opacity: 0;
		transform: translate3d(-$f-offsetX, 0, 0);
	}
	100% {
		opacity: 1;
		transform: translateX(0, 0, 0);
	}
}

@keyframes f-anim2 {
	0% {
		opacity: 0;
		transform: translate3d($f-offsetX, 170px, 0);
	}
	100% {
		opacity: 1;
		transform: translate3d(0, 170px, 0);
	}
}

@keyframes f-anim3 {
	0% {
		opacity: 0;
		transform: translate3d(0, $f-offsetY, 0);
	}
	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.featured-area {
	padding: 10px 0 30px;

	p {
		font-weight: 300;
	}

	&__top {
		text-align: center;
		overflow: hidden;
		padding: 20px 0; //20px;

		h2 {
			@extend .h1;
			margin: 0px auto;
			padding: 10px 0; //font-weight: 100; //300;
		}

		p {
			max-width: 90%;
			margin: 0 auto; //margin-right: auto;
			padding: 10px 0;//margin-left: auto;
			color: rgba(169, 168, 165, 1);
		}
	}
	//72% | 50% = 120%
	//overlap-x: 22%;
	//overlap-y: 55px;
	&__imgs {
		display: flex;
		flex-wrap: nowrap;
		margin: 0 auto;
		z-index: 2;
		position: relative;
		perspective: 800px;
		perspective-origin: 50%;
	}

	&__img {
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: 0;
		position: relative;
		will-change: transform;
		height: auto;
		user-select: none;

		> img {
			display: block;
			width: 100%;
			height: auto;
		}

		&.lg {
			flex: 0 0 auto;
			width: 72%;
			z-index: 0;
			order: 0;
		}

		&.sm {
			flex: 0 0 auto;
			width: 50%;
			order: 1;
			z-index: 1;
			margin-left: -22%;
			margin-top: 25%;
		}
	}

	&__content {
		//margin-top: -15px;
		overflow: hidden;
		position: relative;
		z-index: 1;
		display: flex;
		
		.f-box {
			//padding: 30px 15px;
			text-align: left;
			//margin-top: -10px;
		}
		h2 {
			font-size: 48px;
		}
		h4 {
			max-width: 45%;
			margin: 0; //margin-top: 0;
			//margin-bottom: 0;
			padding: 10px 0;
			font-weight: 300;
			font-size: 30px;
		}

		h6 {
			margin: 0; //margin-top: 30px;
			//margin-bottom: 0;
			padding: 10px 0;
		}

		p {
			margin: 0; //margin-top: 30px;
			//margin-bottom: 0;
			padding: 10px 0;
		}
	}

	.ui-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 20px; //60px;

		a + a {
			margin-top: 15px;
		}
	}

	&.single {
		.featured-area {
			&__imgs {
				display: block;
			}

			&__img {
				&.lg,
				&.sm {
					flex: none;
					width: 100%;
					height: auto;
					margin: 0;
					transform: none;
				}

				&.lg {
					display: block; //none;
				}
			}
		}
	}
}

.experienceeditor .featured-area,
.experienceeditor .featured-area.right,
.experienceeditor .featured-area.single {
	&__imgs {
		display: block;
		margin: 0;
		overflow: hidden;
	}
	&__img.lg,
	&__img.sm {
		display: block !important;
		width: 100% !important;
		height: auto !important;
		transform: none !important;
		opacity: 1;
		margin: 0;
		padding: 0;
		background-size: 0;
		min-height: 100px;
		animation: none !important;

		img {
			display: block;
		}
	}
	&__content {
		.f-box {
			width: 100% !important;
			height: auto;
			padding: 30px !important;
			margin: 0;
		}
	}
}

@media screen and (max-width: $bp-tablet - 1px) {
    .featured-area {
        &__img.lg {
            width: 100%;
        }

        &__img.sm {
            display: none;
        }

        &__content {
            padding-top: 10px;

            h4 {
                font-size: 24px;
                line-height: 30px;
                font-weight: 300;
                max-width: 100%;
                padding-top: 20px;
            }

            h6 {
                font-size: 15px;
                line-height: 20px;
            }
        }
    }
}

@media screen and (min-width: $bp-tablet) {
	.featured-area {
		&__top {
			h2 {
				//max-width: 80%;
				//margin: 40px auto;
				font-size: 48px;
			}
		}

		&__img {
			//65% | 55%
			//overlap-x: 22%;
			//overlap-y: 130px;
			background-size: cover;
			height: 300px;

			> img {
				display: none;
			}

			&.lg {
				flex: 0 0 auto;
				width: 65%;
				order: 0;
			}

			&.sm {
				flex: 0 0 auto;
				width: 55%;
				order: 1;
				margin-top: 0;
				margin-left: -22%;
				transform: translateY(130px);
			}
		}

		&__content {
            min-height: 130px;
			margin-top: 40px;

			.f-box {
				//width: 100%;
				//padding: 70px;
			}

			h4 {
				max-width: 40%;
			}

			h6 {
				max-width: 40%;
				//margin-top: 30px;
			}

			p {
				max-width: 80%;
				//margin-top: 45px;
			}
		}

		.ui-wrapper {
			//margin-top: 60px;
		}
	}
	//single theme(1 image)
    .featured-area {
        &.single {
            .featured-area {
                &__imgs {
                    display: block;
                }

                &__img {
                    flex: unset; // use img not bg
                    background-size: 0;

                    > img {
                        display: block;
                    }

                    &.lg,
                    &.sm {
                        flex: none;
                        width: 100%;
                        margin: 0;
                        transform: none;
                    }

                    &.sm {
                        display: none;
                    }

                    &.lg {
                        display: block;
                    }
                }

                &__content {
                    display: block;
                    margin-top: 0;

                    .f-box {
                        flex: unset;
                        width: 80%;
                        display: block; //flex;
                        padding: 10px; //100px 70px;
                    }

                    .f-col {
                        width: 100%;
                        min-height: 0;
                        /*&:nth-of-type(1) {
							flex: 1 0 auto;
							width: 30%;
						}
						// second col could be
						&:nth-of-type(2) {
							flex: 0 0 auto;
							width: 70%;
						}*/
                    }

                    h4 {
                        width: 100%;
                        max-width: 100%;
                    }

                    h6 {
                        width: 100%;
                        max-width: 100%;
                    }

                    p {
                        //margin: 0;
                        width: 100%;
                        max-width: 100%;
                        padding: 10px 0;
                        text-align: left;
                    }
                    .ui-wrapper {
                        display: block;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $bp-desktop-small) {
	.featured-area {
		padding-bottom: 40px;
		&__top {
			padding: 30px 0;
			h2 {
				/*max-width: 60%;*/
				font-size: 54px; //60px;
			}
		}
		&__img {
			//75% | 50%
			//overlap-x: 25%;
			//overlap-y: 170px;
			height: 400px;
			opacity: 0; //for animation
			&.lg {
				flex: 0 0 auto;
				width: 75%;
			}
			&.sm {
				flex: 0 0 auto;
				width: 50%;
				margin-left: -25%;
				transform: translateY(170px);
				//margin-top: 20%;
			}
		}
		&__content {
			margin-top: 55px;
			h4 {
				max-width: 45%;
				font-size: 36px;
			}
			h6 {
				max-width: 45%;
			}
			p {
				padding-top: 50px;
			}
			.f-box {
				//width: 90%;
				padding: 0 15px; //80px 25px 70px 50px;
			}
		} //in viewport animation 
		&.in-view-animation {
			.featured-area__img {
				opacity: 1; //for animation
				animation-iteration-count: 1;
				animation-timing-function: $f-anim-easing;
				animation-duration: $f-anim-duration;
				&.lg {
					animation-name: f-anim1;
				}
				&.sm {
					animation-name: f-anim2;
				}
			}
			&.single {
				.featured-area__img {
					&.lg,
					&.sm {
						animation-name: f-anim3;
					}
				}
			}
		}
	} //right theme
	.featured-area {
		&.right {
			.featured-area {
				&__imgs {
					flex-direction: row-reverse;
				}
				&__img {
					height: 400px;
					&.lg {
						flex: 0 0 auto;
						width: 75%;
					}
					&.sm {
						flex: 0 0 auto;
						width: 50%;
						margin-right: -25%;
						transform: translateY(170px);
					}
				}
				&__content {
					justify-content: flex-end;
					.f-box {
						width: 50%;
						padding: 100px 25px 100px 100px;
					}
				}
			}
		}
	}
}

//language overrides
.ja-JP,
.zh-CN {
	.featured-area {
		&__content {
			h4 {
				font-size: 18px;
				line-height: 26px;
			}
		}

		&__top {
			h2 {
				font-size: 24px;
				line-height: 30px;
			}
		}
	}

	@media screen and (min-width: $bp-tablet) {
		.featured-area {
			&__content {
				h4 {
					font-size: 24px;
					line-height: 32px;
				}
			}

			&__top {
				h2 {
					font-size: 32px;
					line-height: 42px;
				}
			}
		}
	}
}

