/**
* Subscription Block
*
* @section module
*  
*/

.subscription-block
{
   background: $color-theme-black-bg;
   color: $color-font-white;
   padding: 30px 30px 10px; 
   &__t
   {
      @extend .font-module-title;
      text-align: center;
      color: inherit;
      font-weight: 200;
      margin-top: 0;
   }

   &__st
   {
      @extend .font-module-title;
      text-align: center;
      color: inherit;
      font-weight: 200;
      margin-top: 0;
   }

   .subscription
   {
      p
      {
         color: inherit;
         font-weight: 300;
      }

      a
      {
         @include ui-btn-factory('secondary');
      }
   }

   .thankyou
   {
      display: none;
   }

   .f-row
   {
      display: flex;
      align-items: center;
      flex-wrap: no-wrap;

      .col
      {
         flex: 0 0 auto;
         width: calc((100% - 45px)/2);

         &:nth-of-type(1)
         {
            padding-right: 15px;
            text-align: right;
         }

         &:nth-of-type(3)
         {
            padding-left: 15px;
            text-align: left;
         }

         &:nth-of-type(2)
         {
            text-align: center;
            padding: 0;
            width: 45px;
         }
      }

      span:not(.ui-btn--circle-check)
      {
         font-weight: 300;
         color: $color-font-white;
      }

      .ui-btn--circle-check
      {
         width: 45px;
         height: 45px;
      }
   }
   // state (is-submitted)
   &.is-form-submitted
   {
      .subscription
      {
         display: none;
      }

      .thankyou
      {
         display: block;
      }
   }
   // when first input is focus, then show all
   &.phase-1
   {
      form .form-group:not(:nth-of-type(2))
      {
         display: none;
      }

      .form-submit-border
      {
         display: none;
      }

      .subscription p
      {
         display: none; // hide when not phase-1
      }
   }

   form
   {
      position: relative;
      overflow: hidden;

      // form group for text input
      .form-group
      {
         @extend .ui-input !optional;
         @extend .ui-input.large !optional;
         display: block;
         margin: 20px 0;
         border: none; //jam
         input[type="text"], input[type="email"]
         {
            border: 1px solid #484848;
         }
         .checkbox,
         .g-recaptcha
         {
            border: 0;
            height: auto;

            label
            {
               font-size: 16px;
               margin-bottom: 10px;
               display: inline-block;
            }
         }

         input.text-box
         {
            color: $color-white;
            font-weight: 300;
            background: transparent;
            outline: none;

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active
            {
               //text color
               &:first-line
               {
                  color: $color-white !important;
               }
               //bg
               outline: none;
               box-shadow: 0 0 0px 1000px $color-theme-black-bg inset;
            }

            &::-webkit-input-placeholder
            {
               color: $color-white;
            }
         }

         input[type="checkbox"],
         label
         {
            &:focus
            {
               outline: none;
               border: 1px solid $color-global-focus;
            }
         }
         // show label and visually hide(AA)
         > label
         {
            @extend %visuallyhidden;
         }

         input[type="checkbox"]
         {
            width: 15px;
            height: 15px;
            vertical-align: middle;
         }
         // help-block
         .help-block:not(.field-validation-valid):not(.field-validation-error)
         {
            display: none;
         }

         .field-validation-error
         {
            margin-top: 5px;
            display: block;
         }
         //mail icon
         &:before
         {
            @include icon();
            content: "\e921";
            position: absolute;
            top: 50%;
            font-size: 20px;
            left: 15px;
            color: $color-red;
            transform: translateY(-50%);
            display: inline-block;
            cursor: pointer;
            z-index: 10;
            display: none;
         }
         //arrow
         &:after
         {
            @include icon();
            content: "\e90c";
            position: absolute;
            top: 50%;
            font-size: 20px;
            right: 15px;
            color: $color-red;
            transform: translateY(-50%);
            display: inline-block;
            cursor: pointer;
            z-index: 10;
            display: none;
         }
      }

      //submit btn
      .form-submit-border
      {
         text-align: center;
         margin-bottom: 30px; // round cta style
         padding-top: 15px;

         .btn
         {
            @include ui-btn-factory('box');
         }
      }

      .has-error.has-feedback:not(.form-group)
      {
         display: none !important;
      }

      .field-validation-error,
      .list-group-item-danger
      {
         font-size: 12px;
         font-weight: 300;
         margin: 0;
         color: $color-red;
         background-color: transparent;
         border: 0;
      }
   }
}

//experienceeditor
.experienceeditor {
	.subscription-block {
		.thankyou {
			display: block;
		}
	}
}

@media screen and (min-width: $bp-mobile) {}

@media screen and (min-width: $bp-tablet) {
	.subscription-block {
		padding: 40px 0 25px; //60px 0;
		.f-row {
			.col {
				flex: 0 0 auto;
				width: calc((100% - 60px)/2);
				&:nth-of-type(1) {
					padding-right: 30px;
					text-align: right;
				}
				&:nth-of-type(3) {
					padding-left: 30px;
					text-align: left;
				}
				&:nth-of-type(2) {
					text-align: center;
					padding: 0;
					width: 60px;
				}
			}
			span:not(.ui-btn--circle-check) {
				font-size: 30px;
				font-weight: 200;
				line-height: 45px;
			}
			.ui-btn--circle-check {
				width: 60px;
				height: 60px;
			}
		} //override
		form {
			position: relative;
			.form-group {
				//mail icon
				&:before {
					left: 30px;
				} //arrow
				&:after {
					right: 30px;
				}
			}
			.form-submit-border {
				right: 15px;
			}
		}
	}
}

@media screen and (min-width: $bp-desktop-small) {
	.subscription-block {
		padding: 40px 0 20px; 
		.f-row {
			.col {
				flex: 0 0 auto;
				width: calc((100% - 90px)/2);
				&:nth-of-type(1) {
					padding-right: 90px;
					text-align: right;
				}
				&:nth-of-type(3) {
					padding-left: 90px;
					text-align: left;
				}
				&:nth-of-type(2) {
					text-align: center;
					padding: 0;
					width: 90px;
				}
			}
			span:not(.ui-btn--circle-check) {
				font-size: 30px;
				font-weight: 200;
				line-height: 45px;
			}
			.ui-btn--circle-check {
				width: 90px;
				height: 90px;
			}
		}
	}
}

@media print {
	.subscription-block {
		display: none;
	}
}
