/**
* Print
*
* variables and function definitions
*
* @section framework
*/

@media print {

  body {
    font-size: 20px;
  }

  .no-print {
    display: none;
  }

  .clip-masker,
  .ui-component {
    display: none;
  }

  .module {
    padding: 30px;

    &:not(.featured-area) {
      img {
        max-width: 60%!important;
      }
    }
  }

  .module .container {
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: none;
    height: auto;
  }

  .off {
    display: block !important;
  }
}
