/**
* UI Bullets
*
* @author <min>
*/

.ui-dots {
	display: inline-flex;
	height: 20px;
	align-items: center;
	&__item {
		width: 30px; //hit area extended
		height: 30px; //hit area extended
		text-decoration: none;
		position: relative; //inner
		&:after {
			content: "";
			width: 8px;
			height: 8px;
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -4px 0 0 -4px;
			border-radius: 50%;
			background-color: $color-white;
		}

		&:focus {
			outline: none;
			border: 1px solid $color-global-focus;
		}

		&.active {
			&:after {
				width: 12px;
				height: 12px;
				margin: -6px 0 0 -6px;
				background-color: transparent;
				border: 2px solid $color-white;
			}
		}
	} //state & color variation
	&.inactive {
		opacity: .25;
		pointer-events: none;
	}
	@media screen and (min-width: 768px) {
		.ui-dots__item {
			//hover
			&:hover {
				//inner
				&:after {
					background-color: $color-ui-white;
				}
				&:before {
					transform: scale(1);
				}
			}
		}
	}
}

.ui-dots.theme-black {
	.ui-dots__item {
		//inner
		&:after {
			background-color: $color-ui-white;
		} //outer
		&:before {
			background-color: $color-ui-white;
		}
		&.active,
		&:hover {
			//inner
			&:after {
				background-color: $color-ui-gray;
			} //outer
			&:before {
				transform: scale(1);
			}
		}
	}
}
