/**
* Social UI
*
* @author <min>
*/

.ui-social {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	text-align: center;
	width: 45px;
	height: 45px;
	transition: opacity $ui-transition-dur;
	font-size: 12px;
	line-height: 45px;
	position: relative;

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 3;
		background-color: rgba(255,255,255,0.001);
	}

	em,
	i,
	[class^="icon-"] {
		pointer-events: none;
		font-size: 14px;
		line-height: 40px;
		display: inline-block;
		color: $color-gray-1;
		will-change: color;
		transition: color $ui-transition-dur;
	}

	img {
		flex: 0 0 15px;
		width: 15px;
		height: 15px;
		pointer-events: none;
	}

	&:focus,
	&:hover,
	&:active {
		outline: none;
		em,
		i,
		[class^="icon-"] {
			color: $color-red;
		}
	} //inactive state
	&.inactive {
		pointer-events: none;
		opacity: .25;
	}
}
