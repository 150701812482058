/**
* Prefooter
*
* @section module
*  
*/

.prefooter {
	position: relative;
	background-color: $color-gray-5;
	margin-top: 10px;
	&__item {
		box-sizing: border-box;
		padding: 10px 0px 20px; //10px 15px 25px;
		position: relative;
		text-align: center;

		&__title {
			margin: 0; //20px 0 40px; //15px 0 0; //30px 0;
			padding: 10px 0;
			font-weight: 300;
			font-size: 24px;
			line-height: 30px; //32px;
		}

		&__desc {
			margin: 0; //margin-bottom: 15px;
			//margin-top: -25px;
			padding-bottom: 20px;
			font-weight: 300;
		}
	}

	a,
	.ui-btn--text,
	.ui-btn--secondary {
		color: $color-dark-red;
	}
}

//experienceeditor
.experienceeditor {
	.prefooter {
		&__items {
			.scEmptyPlaceholder {
				min-width: 100px;
				min-height: 100px;
			}
		}
	}
}

@media screen and (max-width: $bp-tablet - 1px) {
	.prefooter__item+.prefooter__item {
		border-top: 1px solid #e2dfda;
	}
}

@media screen and (min-width: $bp-tablet) {
	.prefooter {
		&__items {
			padding: 30px 0 20px;
			>.container {
				width: 768px; //100%;
				display: flex;
				align-items: stretch;
				flex-wrap: nowrap;
			}
		}
		&__item {
			flex: 0 0 auto;
			width: 33.2%;
			padding: 0 20px 40px 0; //10px 40px 60px 40px;
			text-align:left;
			&__title {
				min-height: 80px; // 3 lines
				//font-size: 30px;
				//line-height: 40px;
			}
			&__desc {
				/*margin-top: 40px;*/
				//margin-bottom: 40px;
				//font-weight: 300;
			}
			[class*="ui-btn"] {
				position: absolute;
				bottom: 10px; //30px;
			}
			&+.prefooter__item {
				padding-left: 20px;
				border-left: 1px solid #e2dfda;
			}
		}
	}
}

@media screen and (min-width: $bp-desktop-small) {
	.prefooter {
		&__items {
			> .container {
				width: 1014px;
			}
		}

		&__item {
			padding: 0 20px 60px 0; //20px 60px 60px 60px;
			[class*="ui-btn"] {
				//bottom: 60px;
			}

			&__title {
				min-height: 60px; // 3 lines
				font-size: 28px;
				line-height: 40px;
			}

			&__desc {
				/*margin-top: 40px;*/
				//margin-bottom: 40px;
				font-weight: 300;
			}
		}
	}
}

@media screen and (min-width: 1200px) {
	.prefooter {
		&__items {
			>.container {
				width: 1180px;
			}
		}

		&__item {
			//padding: //60px 60px 60px 100px;
		}
	}
}

//language overrides
.ja-JP,
.zh-CN {
	.prefooter__item__title {
		font-size: 18px;
		line-height: 26px;
	}

	@media screen and (min-width: $bp-desktop-small) {
		.prefooter__item__title {
			font-size: 22px;
			line-height: 30px;
		}
	}
}
