/**
* List
*
* @section module
*  
*/

.list{
  padding: 30px 0;

  &__top {

    h2,
    h4 {
      @extend .h4;
	  font-size: 24px;
	  line-height: 32px;
	  font-weight: 500;
    }

    p{
      margin-bottom: 40px;
      max-width: 768px;
      font-weight: 300;
    }
  }

  &__item{
    border-top: 1px solid #e0e0e0;
    padding: 25px 0;

    &:nth-last-child(1) {
      border-bottom: 1px solid #e2dfda;
    }
  }

  &__title{
    margin-top: 0;
    margin-bottom: 15px;

    &,
    a {
      font-weight: 500;
      font-size: 15px;
      color: $color-font-black;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  &__desc{
    font-weight: 300;
    margin-top: 30px;
    margin-bottom: 0;
  }

  &__date {
    display: block;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    color: rgb(168,168,168);
    margin-bottom: 20px;

    > span:nth-child(1) {
      text-transform: uppercase;
    }

    span{
      display: block;
      line-height: 25px;

      & + span {
        margin-top: 10px;
      }
    }
  }

  .ui-wrapper{
    display: block;
    text-align: center;
    margin-top: 60px;

    .ui-btn--secondary {
      max-width: 768px;
      text-align: left;
    }
  }
}

// min-width
@media screen and (min-width: $bp-mobile) {
  .list{
    &__top {

      h2,
      h4 {
        max-width: 80%;
      }

      p{
        margin-bottom: 80px;
      }
    }

    &__item {
      padding: 30px 0;
    }
  }
}

@media screen and (min-width: $bp-tablet) {
	.list {
		padding: 60px 0;

		&__top {

			h2,
			h4 {
				max-width: 60%;
				font-size: 30px;
				line-height: 40px;
				font-weight: 300;
				margin: 0;
			}
		}
	}
}

@media screen and (min-width: $bp-desktop-small) {}

//print
@media print {
  .list {
    .ui-wrapper {
      display: none;
    }

    table {
      table-layout: auto;
    }

    th,
    td {
      width: 100%;
    }
  }
}
