/**
* News hub
*
* @section module
*  
*/

$news-hub-h: 265px;
.news-hub {
	overflow: hidden;
	position: relative;
	background-color: $color-theme-white-bg;

	> .container {
		position: relative;
		max-width: 100%;
		width: 100%;
		padding: 0;
		z-index: 1;
	}

	figure {
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: cover;
		height: $news-hub-h;
		@include set-brand-clip(20px, 'bottom-right');

		img {
			width: 100%;
			height: auto;
			display: none;
		}
	}
	// only when there is an image
	figure + .news-hub__content {
		margin-top: -150px; //-100px;
	}

	&__content {
		z-index: 1;
		position: relative;
		text-align: center;
		padding: 0 15px;
	}

	.f-row {
		/*min-width: 360px;
		min-height: 150px;*/
		width: 100%; //90%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		background: #fff; //rgba(255, 255, 255, 1); //d shadow
		margin-bottom: 15px; //30px;
		transform: translate3d(0, 0, 0);
		filter: drop-shadow(0 0 10px rgba(0,0,0,.15)); //drop-shadow(0px 0px 12px rgba(0, 0, 0, .15));
		//@include set-brand-clip(15px, 'bottom-right');
	}

	.f-col {
		box-sizing: border-box;
		position: relative;
		flex: 1 1 auto;
		color: $color-red;
		padding: 25px 10px; //15px; //30px 15px;
		text-align: center;

		p {
			text-transform: uppercase;
			margin: 0;
		}

		a {
			&:focus {
				@include global-focus();

				img {
					border: 1px solid $color-global-focus;
				}
			}
		}
		// logo
		img {
			border: 1px solid transparent;
			display: inline-block;
			width: 100%;
			max-width: 100%;
			height: auto;
		}
	}

	.f-col + .f-col {
		padding-top: 0;
	}

	.filter-container {
		flex: 0 0 auto;
		width: 100%;
		display: block;
		border-top: 1px solid $color-gray-5;
		padding: 30px;
	}

	.ui-filter-masker {
		padding: 0 15px;
	}
}

.news-hub__t {
	margin: 0;
	font-size: 24px;
	line-height: 40px;
	font-weight: 600;
	color: $color-red;
}

	
.news-hub:not(.has-hero-image) .container {
	@media screen and (min-width: $bp-tablet) {
		width: 750px;
	}
	@media screen and (min-width: $bp-desktop-small) {
		width: 1008px;
	}
	@media screen and (min-width: 1200px) {
		width: 1180px;
	}
}

@media screen and (min-width: $bp-mobile) {
	.news-hub {
		.f-row {
			/*width: auto;*/
			display: inline-flex;
		}
		.f-col {
			padding: 30px 15px;
			img {
				width: auto;
			}
		}
	}
}

@media screen and (min-width: $bp-tablet) {
	.news-hub {
		/* figure {
			@include set-brand-clip(15px, 'bottom-right');
		} */
		figure+.news-hub__content {
		margin-top: -100px;
		}
		/* .f-row {
			width: 994px;			
		} */
	}
}

@media screen and (min-width: $bp-desktop-small) {
	.news-hub {
		.f-row {
			width: 994px;
		}
	}
}

@media screen and (min-width: $bp-desktop-medium) {
	.news-hub {
		.f-row {
			width: 1170px;
		}
	}
}

@media screen and (max-width: $bp-tablet - 1px) {
	.news-hub {
		figure {
			height: 200px;
		}

		// only when there is an image
		figure + .news-hub__content {
			margin-top: -60px;
		}
	}
}

@media screen and (max-width: $bp-mobile - 1px) {
	.news-hub {
		figure {
			//clip-path: none;
			height: 160px;
		}
		// only when there is an image
		figure + .news-hub__content {
			margin-top: -50px;
		}
	}
}

//language overrides
.el-GR,
.pl-PL,
.cs-CZ
{
   .news-hub .f-col p {
      text-transform: none;
   }
}
