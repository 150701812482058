/**
* Simple Slider
*
* @type: mixin
* @min
*/
$js-slider-easing: cubic-bezier(0.15, 0.67, 0.67, 1);

.js-slider {
    $distY: 75px;
    $bounce: all 350ms cubic-bezier(0, 0.66, 0.42, 1.23);

    .js-slider-inner {
        will-change: transform;
        transform: matrix(1,0,0,1,0,0);
    }

    .js-slider-inside-animation {
        display: block;
        will-change: transform, opacity;
    }

    /*this is vertical translateY + scale*/
    &.type1 .js-slider-inside-animation,
    &.type1 .js-slider-outer-animation{
        &.before-animate-in {
            transform-origin: 0 100%;
            transform: translate3d(0, -$distY, 0) scale(1, 1.125);
            opacity: 0;
            transition: opacity 250ms $js-slider-easing, transform 250ms $js-slider-easing;
        }
        &.before-animate-in.animate-in {
            transform: none;
            opacity: 1;
            filter: none;
        }
        &.before-animate-out {
            transform-origin: 0 0;
            transition: opacity 250ms $js-slider-easing, transform 250ms $js-slider-easing;
        }
        &.before-animate-out.animate-out {
            transform: translate3d(0, $distY, 0) scale(1, 1.125);
            opacity: 0;
        }
    }


    /*simple opacity*/
    &.type2 {

      .js-slider-inside-animation{
        &.before-animate-in {
            opacity: 0;
            transition: opacity 450ms $js-slider-easing;
            transition-delay: 200ms;
        }
        &.before-animate-in.animate-in {
            opacity: 1;
        }
        &.before-animate-out {
            transition: opacity 450ms $js-slider-easing;
        }
        &.before-animate-out.animate-out {
            opacity: 0;
        }
      }

      .js-slider-outer-animation{
        transition: opacity 450ms $js-slider-easing;
        transition-delay: 200ms;

        &.before-animate-in {
            opacity: 0;
        }
        &.before-animate-in.animate-in {
            opacity: 1;
        }
      }
    }

    /*vertical scale + opacity*/
    &.type3 .js-slider-inside-animation,
    &.type3 .js-slider-outer-animation {
        will-change: clip-path;
        &.before-animate-in {
            opacity: 0;
            clip-path: polygon(0% 0%, 0% 0%, 10% 100%, 0% 100%);
            transition: clip-path 350ms $js-slider-easing, opacity 350ms $js-slider-easing;
        }
        &.before-animate-in.animate-in {
            opacity: 1;
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        }
        &.before-animate-out {
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
            transition: clip-path 350ms $js-slider-easing, opacity 350ms $js-slider-easing;
        }
        &.before-animate-out.animate-out {
            opacity: 0;
            clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 95% 100%);
        }
    }

    /*masker*/
    &.type4 .js-slider-inside-animation,
    &.type4 .js-slider-outer-animation {
        will-change: clip-path;
        &.before-animate-in {
            opacity: 0;
            clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 10%);
            transition: clip-path 350ms $js-slider-easing, opacity 150ms;
        }
        &.before-animate-in.animate-in {
            opacity: 1;
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        }
        &.before-animate-out {
            opacity: 1;
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
            transition: clip-path 350ms $js-slider-easing, opacity 150ms 250ms;
        }
        &.before-animate-out.animate-out {
            opacity: 0;
            clip-path: polygon(0% 95%, 100% 100%, 100% 95%, 0% 100%);
        }
    }

    /*opacity*/
    &.type-opacity .js-slider-inside-animation,
    &.type-opacity .js-slider-outer-animation {
        will-change: opacity;
        &.before-animate-in {
            opacity: 0;
            transition: opacity 700ms ease;
        }
        &.before-animate-in.animate-in {
            opacity: 1;
        }
        &.before-animate-out {
            opacity: 1;
            transition: opacity 700ms 250ms ease;
        }
        &.before-animate-out.animate-out {
            opacity: 0;
        }
    }

    //container tx (both direction left | right)
    &.type1,
    &.type2,
    &.type3,
    &.type-opacity {

      .js-slider-inner {
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        will-change: transform;
        transform: matrix(1,0,0,1,0,0);
      }

    }

    /* animate each slide. (one directional)*/
    &.type-each-tx {

      .js-slider-inner {
        position: relative;
      }

      .js-slider-item {
        opacity: 0;

        position: absolute;
        width: 100%;
        height:100%;
        top: 0;
        left: 0;
        z-index: 0;
        will-change: transform;
        transform: matrix(1,0,0,1,0,0);
      }

      .already-in {
        opacity: 1;
        transform: matrix(1,0,0,1,0,0);
      }

      .before-animate-out {
        opacity: 1;
        transform: translate3d(0,0,0);
      }

      .before-animate-out.animate-out {
        transform: translate3d(-100%, 0, 0);
        transition: transform 800ms cubic-bezier(.57,.13,.4,.88);
        transition-delay: 150ms;
      }

      .before-animate-in {
        opacity: 1;
        transform: translate3d(100%, 0, 0);
      }

      .before-animate-in.animate-in {
        transform: translate3d(0, 0, 0);
        transition: transform 800ms cubic-bezier(.57,.13,.4,.88);
        z-index: 99;
      }

    }

}

//experience mode
.experienceeditor .js-slider {
  .js-slider__inner {
    display: block;
  }

  .ui-dots,
  .ui-pagination {
    display: none;
  }
}
