/**
* Home Hero
*
* @section module
*
* See mixin/slider.
* This modules uses it as a mixin
*
*  
*
*/

$hero-height-sm: 330px+$header-sm-height !default;
$hero-min-height: 500px !default; // desktop min
$hero-max-height: 700px !default; // desktop max(780px)
$hero-thumb-height: 160px !default;
$hero-thumb-height-large: 280px !default;
$hero-thumb-spacing: 30px !default;
$hero-thumb-masker-bg: $color-gray-0 !default;
$color-hero-bar-loader: $color-red !default;
$hero-thumb-bg: $color-theme-black-bg; // #1a1813(AA)

//masker
@mixin innerMasker($active: "true", $delay: 300ms, $animationDuration: 400ms, $bgColor: #000) {

	@if $active=="true" {
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			z-index: 10;
			pointer-events: none;
			width: calc(100% + 6px);
			height: calc(100% + 6px);
			margin-top: -3px;
			margin-left: -3px;
			background-color: transparent;
			transform: scaleX(1);
			transform-origin: 100% 50%;
			transition: transform $animationDuration ease-in-out;
			transition-delay: $delay;
		}
	}

	@if $active=="false" {
		&:before {
			background-color: $bgColor;
			transform: scaleX(0);
		}
	}
}

@keyframes activeThumb {
	0% {
		transform: scaleY(.65);
	}

	100% {
		transform: scaleY(1);
	}
}

// under 480
.hero {
	margin: 0;
	padding: 0;
	position: relative;
	background-color: transparent;
	@media screen and (min-width: $bp-tablet) {
			background-color: rgba(0, 0, 0, 1);
		}

	&__items {
		position: relative;
		width: 100%;
		height: 380px; //$hero-height-sm;
		z-index: 0;
		//@include set-brand-clip(30px, 'bottom-right');
	}

	&__item {
		overflow: hidden;
		background-repeat: no-repeat;
		background-size: 130%;
		@media screen and (min-width: $bp-mobile) {
			background-size: cover;
		}
		background-position: 50%;
		will-change: opacity;
		transition: opacity 800ms; 
		
		//dim overlay
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 3;
			background: rgba(0, 0, 0, .25);
		}

		&__meta {
			display: none;
		}

		img {
			width: 100%;
			height: auto;
			display: block;
		}

		.ui-wrapper,
		figure,
		span {
			display: none;
		}

		.video {
			margin: 0;
			padding: 0;
			min-width: 100%;
			min-height: 100%;
			width: auto;
			z-index: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);
			z-index: 0;
			display: none;
			pointer-events: none;
		}

		&.has-video {
			overflow: hidden;
		}

		&.show-video {
			background-position: -9999px;

			.video {
				display: block;
			}
		}
	}
	//h2 with h1 style
	&__title {
		max-width: 80%;
		min-width: 500px;
		margin: 25px auto;
		color: $color-white;
		font-size: 36px;
		line-height: 45px;
		font-weight: 400;
		margin-bottom: 40px;
	}

	&__date,
	&__meta {
		display: block;
		color: $color-white;
		font-size: 11px;
		line-height: 24px;
		margin-bottom: 40px;
	}

	&__sub-title {
		display: none;
		margin: 0;
	}

	&__desc {
		margin: 0;

		& + .ui-wrapper {
			margin-top: 30px;
		}
	}
	
	&__thumbs-inner {
		padding-bottom: 0px;
	}

	&__thumb {
		width: 100%;
		height: auto;
		//min-height: 230px;
		padding: 0;
		position: relative;
		color: $color-gray-0;
		cursor: pointer;
		background-color: $hero-thumb-bg;
		display: none;

		> * {
			pointer-events: none;
		}
		// wedged bg
		/* svg {
			display: none;
		} */

		polygon {
			fill: $color-white;
		}

		figure {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: 50%;
			background-color: #eee;
		}

		img {
			width: 100%;
			height: auto;
			opacity: 0;
		}

		.hero__item__t {
			color: inherit;
			font-size: 15px;
			font-weight: 300;
			line-height: 25px;
			margin-top: 0;
			margin-bottom: 30px;
			position: relative;
			@include innerMasker("true", 1000ms, 400ms);
		}

		p {
			font-weight: 300;
			opacity: 0;
			pointer-events: none;
			display: none;
			position: relative;
			@include innerMasker("true", 1200ms, 400ms);
		}

		.ui-wrapper {
			margin-top: 30px;
			display: block;
		}
		//active
		&.active {
			display: block;
			margin: 0;
			color: $color-white;
			cursor: default;
			//@include set-brand-clip(30px, 'bottom-right');

			> * {
				pointer-events: auto;
			}
			// AA
			polygon {
				fill: $hero-thumb-bg;
			}

			.ui-btn--secondary {
				color: $color-red;
			}

			.hc {
				padding: 16px 16px 8px;
			}

			.hero__item__t {
				font-size: 18px;
				line-height: 26px;
			}

			p {
				opacity: 1;
				pointer-events: auto;
			}

			.ui-btn + .ui-btn {
				margin-top: 30px;
				margin-left: 0;
			}

			.bar-loader {
				opacity: 1;
			}
		}
	}

	&__ui {
		margin-top: -170px;
		text-align: center;
	}

	.bar-loader {
		position: absolute;
		left: 0;
		top: 0;
		background-color: $color-hero-bar-loader;
		pointer-events: none;
		opacity: 0;
		animation-iteration-count: 1;
	}

	.c-loader {
		opacity: 0;
		width: 60px;
		height: 60px;
		background-color: inherit;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);

		.spinner,
		.right {
			background: $color-red;
		}
	}
	// AA
	.ui-btn--circle-pause,
	.ui-btn--circle-play {
		position: absolute;
		top: $header-sm-height + 100px;
		left: 50%;
		z-index: 9;
		transform: translate3d(-50%, 0, 0);
		opacity: 0;
		pointer-events: none;

		&:focus {
			pointer-events: auto;
			opacity: 1;
		}
	}

	&.pause {

		.ui-btn--circle-pause {
			display: none;
		}
	}

	&:not(.pause) {

		.ui-btn--circle-play {
			display: none;
		}
	}
}

// state
.hero {
	&.loading {
		.hero__item:nth-of-type(1) {
			opacity: 0;
		}

		.c-loader {
			opacity: 1;
		}
	}

	.experienceeditor &,
	&.hide-ui {
		background: #eee;
		//clip-path: none;

		.hero__items {
			display: block;
			height: auto;
			min-height: 0;
			max-height: none;
		}

		.hero__item {
			position: static;
			background-size: 0;

			&,
			&:nth-of-type(1) {
				opacity: 1;
				transition: none;
			}

			figure {
				display: block;
			}

			img {
				opacity: 1;
			}

			&__meta {
				display: block;
			}
			//dim overlay
			&:after {
				display: none;
			}
		}

		.c-loader,
		.hero__thumbs,
		.ui-pagination,
		.ui-dots {
			display: none;
		}
	}
}

@media screen and (min-width: $bp-mobile) {
	.hero {
		&__items {
			height: 400px;
		}

		&__thumb {
			&.active {
				.hero__item__t {
					font-size: 22px;
					line-height: 30px;
				}
			}
		}
	}
}
// ~768
@media screen and (min-width: $bp-tablet) {
	.hero {
		//@include set-brand-clip(60px, 'bottom-right');

		&__items {
			width: 100%;
			height: 625px; //100vh;
			min-height: $hero-min-height;
			max-height: $hero-max-height;
		}

		&__thumbs {
			position: absolute;
			width: 100%;
			overflow: hidden;
			margin-bottom: $hero-thumb-spacing;
			left: 0;
			bottom: 0;
			z-index: 10;
			//@include set-brand-clip(60px, 'bottom-right');

			> .container {
				width: 750px; //100%;
			}
		}

		&__thumbs-inner {
			display: flex;
			justify-content: flex-start;
			align-items: flex-end;
			flex-wrap: nowrap; // prevent glitch
			//min-height: $hero-thumb-height-large + 1px;
		}

		&__thumb {
			flex: 0 0 auto;
			background: transparent;
			min-height: 0;
			width: calc(25% - #{$hero-thumb-spacing/2}); // default 25%
			height: auto;
			min-height: 0;
			display: block;
			position: relative;
			color: $color-gray-0;
			cursor: pointer;

			> * {
				pointer-events: none;
			}

			svg {
				display: block;
				width: 100%;
				height: $hero-thumb-height + 30px;
			}

			.hc {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				background: transparent;
				padding: 15px;
			}

			figure {
				background-size: cover;
				background-repeat: no-repeat;
				background-position: 50%;
				background-color: #eee;
			}

			img {
				width: 100%;
				height: auto;
				opacity: 0;
			}

			.hero__item__t {
				color: inherit;
				font-size: 17px; //15px;
				font-weight: 300;
				line-height: 24px; //20px;
				margin-top: 0;
				margin-bottom: 30px;
				position: relative;
				@include innerMasker("true", 1000ms, 400ms);
			}

			p {
				font-weight: 300;
				position: relative;
				@include innerMasker("true", 1200ms, 400ms);
			}

			.ui-wrapper {
				display: none;
				@include innerMasker("true", 1300ms, 400ms);
			}

			& + .hero__thumb {
				margin-left: $hero-thumb-spacing/2;
			}
			// active
			&.active {
				width: 50%;
				height: auto;
				color: $color-white;
				cursor: default;
				animation-name: activeThumb;
				animation-duration: 400ms;
				animation-iteration-count: 1;
				animation-timing-function: cubic-bezier(.19, .74, .54, .95);
				transform-origin: 0 100%;

				//svg {
				//	height: 320px;
				//}

				.hc {
					padding: 30px;
				}

				.hero__item__t {
					font-size: 30px;
					line-height: 40px;
				}

				p {
					opacity: 1;
					pointer-events: auto;
				}

				.ui-wrapper {
					display: block;
					position: absolute;
					bottom: 40px; //60px;
					opacity: 1;
					pointer-events: auto;
				}
				//undo masker
				.hero__item__t,
				p,
				.ui-wrapper {
					@include innerMasker("false", 300ms, 400ms, $hero-thumb-bg);
				}

				.ui-btn + .ui-btn {
					margin-top: 0;
					margin-left: 30px;
				}

				.bar-loader {
					opacity: 1;
				}
			}
			// hover, not active one
			&:not(.active) {
				svg {
					will-change: transform;
					transition: transform 300ms cubic-bezier(.19, .74, .54, .95);
					transform-origin: 0 100%;
				}

				&:hover {
					svg {
						transform: scaleY(1.075);
					}
				}
			}
		}

		&__ui {
			margin: 0;
		}

		.ui-dots {
			display: none;
		}
	}
}

// ~1028
@media screen and (min-width: $bp-desktop-small) {
	.hero {
		&__items {
			height: 100vh;
		}
		&__thumbs {
			> .container {
				width: 1008px;
			}
		}
		&__thumb {
			.hc {
				padding: 20px;
			}

			.hero__item__t {
				line-height: 25px;
			}

			&.active {
				svg {
					height: 300px;
				}
			}
		}
	}
}

// ~1200
@media screen and (min-width: 1200px) {
	.hero {
		&__thumbs {
			> .container {
				width: 1180px;
			}
		}
		&__thumb {
			width: calc(25% - #{$hero-thumb-spacing}); // default 25%
			height: $hero-thumb-height;

			& + .hero__thumb {
				margin-left: $hero-thumb-spacing;
			}

			&.active {
				.hc {
					padding: 45px; //45px 90px;
				}
			}
		}
	}
}

/* @media screen and (max-width: $bp-tablet - 1px) {
	.hero {
		background-color: transparent;

		&__items {
			clip-path: polygon(0 0,100% 0,100% calc(100% - 70px),0 calc(100% - 40px));
		}
	}
} */

@media screen and (max-width: $bp-mobile - 1px) {
	.hero {
		background-color: transparent;

		&__thumbs {
			.container {
				padding-left: 0;
				padding-right: 0;
			}
		}

		&__item {
			background-size: 130%;
			background-position: top;
		}

		&__ui {
			margin-top: -225px;
		}
	}
}

//2.3 wedge on the carousel container
.hero .carousel-wedge {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	svg {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		polygon {
			fill: #fcfcfc;
		}
	}
}

//2.3 wedge on the thumbs
.hero__thumb[data-id="0"] {
	height: auto;
	margin-bottom: 16px;
	@media screen and (min-width: $bp-tablet) {
		margin-bottom: 0;
	}
	svg
	{
		@media screen and (min-width: $bp-tablet) {
			height: 200px;
		}
	}
}
.hero__thumb[data-id="1"] {
	height: 200px;
	margin-bottom: 16px;
	svg
	{
		@media screen and (min-width: $bp-tablet) {
			height: 200px;
		}
		@media screen and (min-width: $bp-desktop-small) {
			height: 195px;
		}
		@media screen and (min-width: 1200px) {
			height: 192px;
		}
	}
}
.hero__thumb[data-id="2"] {
	height: 200px;
	margin-bottom: 16px;
	svg
	{
		@media screen and (min-width: $bp-tablet) {
			height: 193px;
		}
		@media screen and (min-width: $bp-desktop-small) {
			height: 185px;
		}
		@media screen and (min-width: 1200px) {
			height: 180px;
		}
	}
}
.hero__thumb.active {
	height: auto;
	svg {
		height: auto;
		position: absolute;
		bottom: -20px;
		width: 100%;
		@media screen and (min-width: $bp-mobile) {
			bottom: -30px;
		}
		@media screen and (min-width: $bp-tablet) {
			position: inherit;
			bottom: 0;
		}
	}
}
.hero__thumb.active[data-id="0"] {
	@media screen and (min-width: $bp-tablet) {
		height: 310px;
	}
	svg {
		@media screen and (min-width: $bp-tablet) {
			height: 310px;
		}
	}
}
.hero__thumb.active[data-id="1"] {
	@media screen and (min-width: $bp-tablet) {
		height: 300px;
		margin-bottom: 10px;
	}
	svg {
		@media screen and (min-width: $bp-tablet) {
			height: 303px;
		}
		@media screen and (min-width: $bp-desktop-small) {
			height: 300px;
		}
		@media screen and (min-width: $bp-desktop-medium) {
			height: 299px;
		}
	}
}
.hero__thumb.active[data-id="2"] {
	@media screen and (min-width: $bp-tablet) {
		height: 300px;
		margin-bottom: 18px;
	}
	svg {
		@media screen and (min-width: $bp-tablet) {
			height: 304px;
		}
		@media screen and (min-width: $bp-desktop-small) {
			height: 300px;
		}
		@media screen and (min-width: $bp-desktop-medium) {
			height: 297px;
		}
	}
}
.hero__thumb[data-id="0"]:not(.active)+.hero__thumb[data-id="1"]:not(.active)
{
	height: 184px;
	svg {
		height: 192px;
		@media screen and (min-width: $bp-desktop-small) {
			height: 190px;
		}
		@media screen and (min-width: $bp-desktop-medium) {
			height: 189px;
		}
	}
}

//adjust bottom margin of active slides for mobile
.hero.js-slider {
	margin-bottom: 20px;
	@media screen and (min-width: $bp-mobile) {
		margin-bottom: 30px;
	}
	@media screen and (min-width: $bp-tablet) {
		margin-bottom: 0;
	}
}

//-------end 2.3 wedge on thumbs

.js-slider.type-each-tx .js-slider-item {
	height: 280px;
	@media screen and (min-width: $bp-mobile) {
		height: 335px;
	}
	@media screen and (min-width: $bp-tablet) {
		height: 100%;
	}
}

//print
@media print {
	.hero {
		background: white;

		> * {
			opacity: 1;
		}
	}

	.hero.loading > * {
		opacity: 1;
	}

	.hero__items {
		display: block;
		height: auto;
		min-height: 0;
		max-height: none;
	}

	.js-slider.type-each-tx .js-slider-item,
	.hero__item {
		opacity: 1;
		position: static;
		background-size: 0;
		width: 100%;
		height: auto;
		margin: 0;
		padding: 30px;

		figure {
			display: block;
		}

		img {
			opacity: 1;
		}

		&__meta {
			display: block;
		}
		//dim overlay
		&:after {
			display: none;
		}
	}

	.c-loader,
	.hero__thumbs,
	.ui-pagination,
	.ui-dots {
		display: none;
	}
}

//language overrides
.ja-JP,
.zh-CN {
	.hero__thumb.active .hero__item__t {
		font-size: 16px;
		line-height: 24px;
	}

	@media screen and (min-width: $bp-tablet) {
		.hero__thumb.active .hero__item__t {
			font-size: 20px;
			line-height: 30px;
		}

		.hero__thumb .hero__item__t {
			font-size: 15px;
			line-height: 20px;
		}
	}
}

