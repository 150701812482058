/**
* Search Result
*
* @section module
*  
*/

.search-result {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__form {
        padding-top: 20px; //30px;
        padding-bottom: 20px; //30px;
        background-color: $color-gray-4;
    }

    &__tag {
        display: block;
        //margin: 15px 0;
        font-size: 12px;
        line-height: 20px;
        font-style: normal;
    }

    &__items {
        overflow: hidden;

        ul,
        li {
            padding: 0;
            margin: 0;
        }

        .f-row {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0 20px; //30px 0;
        }

        h1 {
            @extend .h3;
            font-weight: 300;
            margin: 0;
        }

        li {
            border-bottom: 1px solid #e2dfda;

            &:first-child {
                border-top: 1px solid #e2dfda;
            }
        }
    }

    &__item {
        &:not(.featured) {
            padding: 20px 0; //30px 0;
            a {
                //color: $color-gray-0;
                &:focus,
                &:active,
                &:hover {
                    @include global-focus();
                }
            }
        }

        &.featured {
            padding: 10px 0 20px;
            margin: 0 0 10px; //margin-bottom: 10px;
            border-bottom: 1px solid #e2dfda;

            > figure {
                img {
                    display: block;
                    width: 75%; //100%;
                    height: auto;
                }
            }

            > div {
                h2 {
                    margin-top: 0;
                    margin-bottom: 0; //30px;
                    padding: 5px 0;
                    font-weight: 300;
                    font-size: 20px; //30px;
                    line-height: 30px; //40px;
                }

                p {
                    font-weight: 300;
                    padding-bottom: 15px; //margin-bottom: 40px;
                    margin: 0;
                    font-size: 14px;
                    line-height: 22px;
                }
            }
            /*> figure + div {
				margin-top: 10px; //30px;
			}*/
        }
    }

    &__title {
        margin-top: 0;
        margin-bottom: 0; //30px;
        padding-bottom: 5px;
        font-weight: 500;
        font-size: 16px;

        a {
            color: $color-gray-0;
        }
    }

    &__desc {
        margin-top: 0;
        margin-bottom: 0;
        font-weight: 300;
    }
    /*&__desc + .ui-btn {
		margin-top: 30px;
	}*/
    //ui pagiantion
    .ui-wrapper {
        display: block;
        margin: 30px 0;
        text-align: center;
        min-height: 20px; // when there is no ui-pagination printed
    }
}

@media screen and (min-width: $bp-tablet) {
    .search-result {
        &__form {
            padding-top: 25px; //60px;
            padding-bottom: 25px; //120px;
        }

        &__items {
            .f-row {
                //padding: 120px 0 60px;
            }
        }

        &__item {
            &.featured {
                //margin-top: 60px;
                border-bottom: none;
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                align-items: center;

                > figure {
                    flex: 0 0 auto;
                    width: 40%; //55%;
                    align-self: flex-start;

                    img {
                        width: 100%;
                    }
                }
                // when there is an img
                > figure + div {
                    width: 60%; //45%;
                    padding: 0 15px; //30px 45px;
                    margin: -12px 0 0;
                }

                > div {
                    flex: 0 0 auto;
                    width: 100%;
                    overflow: hidden;
                    padding: 30px 0;

                    h2 {
                        //margin-bottom: 50px;
                    }

                    p {
                        //margin-bottom: 30px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $bp-desktop-small) {
    .search-result__item.featured > div {
        h2 {
            font-size: 24px;
            line-height: 36px;
        }

        p {
            font-size: 16px;
            line-height: 26px;
        }
    }
}
