/**
* Media query mixin for better breakpoint alignment, ported from Inuit CSS
* Based on work from LJD <ljd.dk>
* @section Framework
* @author echristensen
* @author dkvistgaard
*/
@mixin media($point, $max-width: false, $top-point: false) {
	$sizes: (
		xl: $bp-desktop-xlarge,
		lg: $bp-desktop-large,
		md: $bp-desktop-small,
		sm: $bp-tablet,
		xs: $bp-mobile
	);

	@if $point == print {
		@media print {
			@content;
		}
	} @else if $point == retina {
		@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
			@content;
		}
	} @else {
		$top-define: '';
		$width-define: if($max-width, max-width, min-width);

		@if ($top-point) {
			$width-reverse: if($max-width, min-width, max-width);
			$top-size: if(map-has-key($sizes, $top-point), map-get($sizes, $top-point), $top-point);
			$top-define: and (#{$width-reverse}: $top-size);
		}

		$size: if(map-has-key($sizes, $point), map-get($sizes, $point), $point);

		@if ($max-width) {
			$size: $size;
		}@else {
			$size: $size - 1;//emil, why are we substracting by 1 here?
		}

		@media screen and (#{$width-define}: $size) #{$top-define} {
			@content;
		}
	}
}
