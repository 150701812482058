/**
* Table
*
* @section module
*  
*/

.csl-table {
	padding: 10px 0;

	> .container {
	}

	&__top {
		padding: 0 0 10px;
		h4 {
			font-size: 24px;
			line-height: 32px;
			font-weight: 500;
			margin: 0px;
			@media screen and (min-width: $bp-tablet) {
				font-size: 30px;
				line-height: 40px;
				font-weight: 300;
			}
		}

		p {
			font-weight: 300;
			padding-bottom: 0;
		}
	}

	&__bottom {
		position: relative;
		@media screen and (min-width: $bp-desktop-small) {
			overflow: hidden;
		}
	}

	&__title {
		font-weight: 500;
		margin-top: 0;
		margin-bottom: 15px;
		font-size: 15px;
	}

	&__desc {
		font-weight: 300;
		margin-top: 30px;
		margin-bottom: 0;
	}

	.ui-wrapper {
		display: block;
		text-align: center;
		margin-top: 50px;
	}

	.table-wrapper {
		width: 100%;
		overflow-x: auto;
		//linear graident
		&:after {
			content: "";
			position: absolute;
			top: 0;
			right: 0px;
			width: 40%;
			@media screen and (min-width: $bp-tablet) {
				width: 25%;
			}
			height: 100%;
			z-index: 1;
			pointer-events: none;
		}
	}


	table {
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;
		border: 0;
		font-size: 15px;
		line-height: 1.5em; //18px;
		table-layout: auto;

		p {
			font-weight: 500;
		}
	}

	thead {
		border-bottom: 1px solid #e0e0e0;
	}
	//tr group by id(data-id)
	tbody {
		overflow: hidden;
		border-top: 1px solid #e0e0e0;

		&.active,
		&:hover {
			i {
				transform: rotate(180deg) translateY(50%);
			}
		}

		&.active {
			.expanded-row {
				display: table-row;
			}
		}
	}
	//rows
	tr {
		border-bottom: 1px solid #e0e0e0;
	}

	th,
	td {
		position: relative;
		padding: 10px 10px 10px 3px;
		@media screen and (min-width: $bp-tablet) {
			padding: 15px 20px 15px 3px;
		}
		text-align: left;
		vertical-align: center;
		font-size: 15px;
		line-height: 18px;

		p {
			margin: 0;
			font-size: 15px;
			padding: 0;
			line-height: 18px;
		}

		/*i {
			@extend .icon-caret-outline;
			position: absolute;
			right: 30px;
			top: 45px;
			color: $color-red;
			font-size: 24px;
			transform: translateY(-50%);
			transform-origin: 50%;
			will-change: transform;
		}*/
	}

	a {
		@include ui-btn-factory('secondary');

	}
	// dashboard table only
	&.change-table {
		th {
			&:nth-of-type(3) {
				min-width: 150px;
			}
		}
	}
}

//optional table settings
.csl-table__bottom {
	&.auto-width {
		table {
			width: auto;
		}
		th,
		td {
			padding-right: 25px;
		}
	}
	&.mobile-display {
		@media screen and (max-width: 579px) {
			table {
				width: 100%;
			}
			thead {
				display: none;
			}
			td {
				display: block;
				padding: 3px 0 3px 3px;
				&:first-child {
					padding-top: 12px;
				}
				&:last-child {
					padding-bottom: 12px;
				}
			}
		}
	}
}

//print
@media print {
  .csl-table  {
    .ui-wrapper {
      display: none;
    }

    .table-wrapper:after {
      display: none;
    }

    table {
      table-layout: auto;
    }

    th,
    td {
      width: auto;
    }
  }
}
