/**
* Footnotes
*
* @section module
*/
.footnotes {
	padding: 20px 0 10px;
	font-weight: 300;
	a {
		color: $color-aa-red;

		&:active,
		&:hover,
		&:focus {
			@include global-focus();
		}
	}
}

/*@media screen and (min-width: $bp-mobile) {

	.footnotes {
		padding: 60px 0;
	}
}*/

