/**
* Generic(non project specific)
*
* @section framework
* @author min
*/

%no-scroll {
	overflow-y: scroll;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

body {
	font-family: $base-font;
	color: $color-font-black;
	overflow-y: auto;
	overflow-x: hidden;
	&.no-scroll {
		@extend %no-scroll;
	}
	&.error-500 {
		h1 {
			text-align: center;
		}
	}
	&.search-active {
		@extend %no-scroll;
	}
	&.hide-header {
		.header {
			display: none;
		}
	}
	//   &.lang-selector-active {}
	//   &.sidebar-active{}
}

main {
	overflow: hidden;
	min-height: 800px;
}

.experienceeditor main,
main.no-js {
	visibility: visible;
}

a {
	text-decoration: none;
	&:active,
	&:hover {
		text-decoration: none;
	}
}

// AA, skip to main content
.skip-to-content {
	display: block;
	position: fixed;
	z-index: 999999;
	background: white;
	width: 1px;
	height: 1px;
	overflow: hidden;
	text-align: center;

	&:active,
	&:focus {
		outline: none;
		width: 100%;
		height: auto;
		padding: 30px;
		border: 1px dashed #000;
		text-decoration: underline;
		color: $color-red;
	}
}

// this should be added due to AA instead of
%visuallyhidden,
.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

//ie hack(wedge images)
.clip-masker {
	display: none;
	width: 100%;
	height: auto;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 99;
	user-select: none;
}

%brand-clip {
	clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), 0 100%);
}

//visible only below 480px
.mobile-only {
	display: block;
	@media screen and (min-width: $bp-mobile + 1px) {
		display: none;
	}
}

.mobile-hide {
	@media screen and (max-width: $bp-mobile) {
		display: none;
	}
}

//load more
.js-load-more-alternative {
	opacity: 0;
	pointer-events: none;
}

.show-load-more-alternative {
	.js-load-more-alternative {
		opacity: 1;
		pointer-events: auto;
	}
}

.hide-load-more {
	.js-load-more {
		display: none;
	}
}

.t-center {
	text-align: center;
}

.media-reponsive-container {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 20px;
	height: 0;
	margin: 30px 0;
	>iframe,
	>object {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

a{
  text-decoration: none;

  &:active,
  &:hover{
    text-decoration: none;
  }
}

main{
  overflow: hidden;
  min-height: 800px;
}

.experienceeditor main,
main.no-js{
  visibility: visible;
}

//when page doesn't have a top hero in a page
.no-hero {
	.header {
		@extend .header.white;
	}
	main {
		padding-top: $header-sm-height;
	}
	@media screen and (min-width: 1024px) {
		main {
			padding-top: $header-height;
		}
	}
}

.off {
	display: none !important;
}

.off-only-desktop {
	@media screen and (min-width: 1024px) {
		display: none !important;
	}
}

.hide {
	opacity: 0;
	pointer-events: none;
}

.blur {
	filter: blur(3px);
	transform: translate3d(0, 0, 0);
}

.lock,
.fetching {
	pointer-events: none !important;
	user-select: none;
}

//GENERAL "UTIL" classes
.tag,
.cat {
	font-family: $base-font;
	font-size: 15px;
	font-weight: 300;
	position: relative;
	color: #b7b5ae; //$color-font-gray;
}

.pagination {
	font-family: $base-font;
	font-size: 15px;
	font-weight: 300;
	position: relative;
}

//colr bar
.color-bar {
	width: 100px; //default width 100px
	height: 2px;
	transition: all $ui-transition-dur*2;
	background: $color-gray-0; //default color
}

// red color & underline
@mixin global-focus() {
	outline: unset;
	outline: none;
	text-decoration: underline;
	color: $color-global-focus;
}

@mixin set-brand-clip($offset: 0px, $direction: 'bottom-right') {
	@if $direction=='bottom-right' {
		clip-path: polygon(0 0, 100% 0, 100% calc(100% - #{$offset}), 0 100%);
	}
	@if $direction=='bottom-left' {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - #{$offset}));
	}
	@if $direction=='top-left' {
		clip-path: polygon(0 $offset, 100% 0, 100% 100%, 0 100%);
	}
	@if $direction=='top-right' {
		clip-path: polygon(0 $offset, 100% 0, 100% 100%, 0 100%);
	}
}

@mixin module-box-shadow() {
	box-shadow: 0 0px 10px 2px rgba(37,37,37,.15); //0px 7px 14px 9px rgba(37, 37, 37, 0.08);
}

@keyframes animate-y-in-plus {
	0% {
		transform: translateY(90px);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes animate-y-in-minus {
	0% {
		transform: translateY(-90px);
	}
	100% {
		transform: translateY(0);
	}
}

@mixin animate-in($name: 'animate-y-in', $duration: 300ms, $easing: cubic-bezier(.19, .74, .54, .95), $delay: 0ms, $count: 1) {
	will-change: transform;
	animation-name: $name;
	animation-duration: $duration;
	animation-delay: $delay;
	animation-timing-function: $easing;
	animation-iteration-count: $count;
}

@keyframes csl-table-row-open {
	0% {
		transform: translateY(-10px);
		opacity: 0.5;
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

//language font-family overrides
body.el-GR,
body.pl-PL,
body.cs-CZ {
	font-family: Arial, Helvetica, sans-serif; 
}
