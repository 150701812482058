/**
* Article
*
* @section module
*  
*/

$article-sidebar-width: 275px; //350px;
.article {
	overflow: hidden;
	position: relative;
	background-color: $color-theme-white-bg;

	.c-breadcrumb {
		margin-bottom: 30px;
		padding-right: 60px;
	}

	ol,
	ul {
		font-weight: 300;
	}

	li {
		margin: 0;
	}

	p {
		font-weight: 300;
	}

	blockquote {
		margin: 60px 0;
		text-align: center; //add this with blockquotes fix
		p {
			@extend .h3;
			font-weight: 300;
			position: relative;
			//add this with blockquotes fix
			span {
				font-size: 1.3em;
				padding: 0 0.2em;
				font-family: "Times New Roman", Times, serif;
			}
			//remove this with blockquotes fix
			/*
			&:before,
			&:after {
				position: absolute;
				font-size: 60px;
				line-height: 60px;
				width: 20px;
				height: 20px;
			}
			//remove this with blockquotes fix
			&:before {
				content: "“";
				top: -30px;
				left: 0;
			}
			//remove this with blockquotes fix
			&:after {
				content: "”";
				bottom: 0;
				right: 0;
			}*/
		}

		cite {
			font-size: 15px;
			font-weight: 500;
			font-style: normal;
		}
	}

	figcaption {
		font-size: 12px;
		line-height: 18px;
		font-weight: 300;
		font-style: italic;
		color: #3A372E;
		padding: 10px 0;
		width: 90%;
	}

	&__inner {
		padding: 20px 0; //30px 0;
		position: relative;
		overflow: hidden;
		z-index: 1;
	}

	&__title {
		@extend .font-module-title;
		//margin-top: 0;
		//margin-bottom: 30px;
		margin: 10px 0;
		width: calc(100% - 60px); //social width
	}

	&__subheadline {
		margin: 15px 0;
	}

	&__abstract {
		//margin-bottom: 30px;
		margin: 15px 0;
		font-size: 15px;
		line-height: 20px;
		font-weight: $font-weight-medium;
	}

	&__meta {
		margin-bottom: 30px;
	}

	&__tag {
		@extend .font-meta;
		display: inline-block;
		font-size: 15px;
		line-height: 25px;
		color: #5e5e5e;

		& + .article__tag {
			padding-left: 15px;
			position: relative;

			&:before {
				content: "|";
				position: absolute;
				top: -1px;
				left: 4px;
			}
		}
	}

	&__image {
		margin: 20px 0; //30px 0;
		img {
			width: 100%;
			height: auto;
			display: block;
		}
	}

	&__buttons {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		align-content: flex-start;

		.ui-btn {
			margin-bottom: 30px;
		}
	}

	&__body {
		a {
			color: $color-red;

			&:hover,
			&:focus {
				@include global-focus();
			}
		}

		figure {
			margin: 0 0 30px;
		}

		img,
		figure img {
			max-width: 100%;
			height: auto;
			display: block;
			margin: 30px 0 0;
		}
	}

	&__sidebar {
		display: none;
		padding-top: 20px; //padding: 30px;
		border-top: solid 1px #ddd;

		h3 {
			margin-top: 0;
			font-weight: 300;
		}

		.m-tile {
			@extend .m-tile--normal;
			margin: 0;

			&__content {
				padding: 15px; //25px 25px 25px;
			}

			&__title {
				font-size: 20px;
				line-height: 30px;
			}

			&__meta {
				margin: 0; //margin-top: 40px;
			}
		}
	}
	// social share
	&.has-social-share {
		.social-share {
			display: block;
			@extend .vertical;
			position: absolute;
			top: 13px; //15px;
			right: 0;
			z-index: 99;
			margin: 0;
			/*background-color: $color-gray-8;*/
		}

		[data-type="youtube"] {
			display: none;
		}
	}

	&.has-sidebar {
		.article__sidebar {
			display: block;
		}
	}

	.module.video {
		margin: 30px 0;
	}
	// default hidden
	.social-share {
		display: none;
	}
}

//experienceeditor
.experienceeditor {
	.article {
		@extend .article.has-sidebar;
	}
}

@media screen and (min-width: $bp-mobile) {
	.article {
		// social share
		&.has-social-share {
			.social-share {
				top: 12px; //45px;
				right: 0;
			}
		} // &.has-sidebar {}
	}
}

@media screen and (min-width: $bp-tablet) {
	.article {
		&__inner {
			//padding: 60px 90px 60px 30px;
		}
		&__image {
			width: calc(100% + 90px);
			width: 100%;
			margin: 30px 0; //60px 0;
		}
		&__buttons {
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;
			margin-top: 50px;
			.ui-btn+.ui-btn {
				margin-left: 30px;
			}
		}
		blockquote {
			margin: 90px 0;
		}
		&.has-social-share {
			.social-share {
				top: 23px; //45px;
			}
		}
		/*&.has-sidebar {
			>.container {
				display: flex;
				flex-wrap: nowrap;
				align-items: stretch;
			}
			.article__inner {
				flex: 0 0 auto;
				width: calc(100% - #{$article-sidebar-width});
				padding: 60px 0;
			}
			.article__image {
				width: 100%;
			}
			.article__sidebar {
				flex: 0 0 auto;
				width: $article-sidebar-width;
				padding: 30px 0 30px 30px; //60px 0 60px 30px;
			}
		}*/
	}
}

@media screen and (min-width: $bp-desktop-small) {
	.article {
		&__inner {
			//padding: 120px 300px 120px 0;
		}

		&__image {
			//width: calc(100% + 300px);
		}

		blockquote {
			margin: 120px 0;
			width: 90%;
		}

		.c-breadcrumb {
			/*margin-bottom: 60px;*/
		}

		&.has-social-share {
			.social-share {
				top: 37px; //105px;
				right: 0;
			}
		}

		&.has-sidebar {
			> .container {
				display: flex;
				flex-wrap: nowrap;
				align-items: stretch;
			}

			.article__inner {
				padding: 60px 0; // 120px 0;
				width: calc(100% - 350px);
			}

			.article__sidebar {
				padding: 40px 0 40px 30px; //120px 0 120px 30px;
				width: 350px;
				border: none;
			}
		}
		.m-tile {
			&__content {
				padding: 25px 30px;
			}
		}
	}
}

@media print {
	.article {
		.media-reponsive-container,
		.social-share {
			display: none !important;
		}
	}
}

//language overrides
.ja-JP,
.zh-CN {
	.article__title {
		font-size: 24px;
		line-height: 32px;
	}

	@media screen and (min-width: $bp-tablet) {
		.article__title {
			font-size: 28px;
			line-height: 36px;
		}
	}
}
