.inline-video-image
{

   .content
   {
      font-weight: 300;
   }

   .video-Left-small, .video-Left-large, .video-Right-small, .video-Right-large, .video-Full,
   .image-Left-small img, .image-Left-large img, .image-Right-small img, .image-Right-large img, .image-Full img
   {
      width: 98%;
      height: auto;
      padding: 8px 0 16px 0;
      float: left;
   }


   @media screen and (min-width: 624px)
   {
      .video
      {
         &-Left-small
         {
            float: left;
            width: 40%;
            height: auto;
            padding: 8px 24px 0 0;
         }

         &-Left-large
         {
            float: left;
            width: 60%;
            height: auto;
            padding: 8px 24px 0 0;
         }

         &-Right-small
         {
            float: right;
            width: 40%;
            height: auto;
            padding: 8px 0 0 24px;
         }

         &-Right-large
         {
            float: right;
            width: 60%;
            height: auto;
            padding: 8px 0 0 24px;
         }
      }

      .image
      {
         &-Left-small img
         {
            float: left;
            height: 210px;
            width: auto;
            padding: 8px 16px 0 0;
         }

         &-Right-small img
         {
            float: right;
            height: 210px;
            width: auto;
            padding: 8px 16px 0;
         }

         &-Left-large img
         {
            float: left;
            height: 280px;
            width: auto;
            padding: 8px 16px 0 0;
         }

         &-Right-large img
         {
            float: right;
            height: 280px;
            width: auto;
            padding: 8px 16px 0;
         }
      }
   }
}
