/**
* Banner
*
* @section module
*  
*/

$banner-img-desktop-h: 660px;
.banner {
	overflow: hidden;
	position: relative;
	//padding: 10px 0;

	&__content {
		position: relative;
		margin: 0 0 20px; //0;
	}

	.container {
		display: flex;
	}

	&__meta {
		padding: 15px 25px 20px; //0px 30px 30px; //60px 30px;
		position: relative;
		text-align: left;
		margin: -60px auto 0;
		background: $color-theme-white-bg; // defaul white
		box-shadow: 0 0px 10px 2px rgba(37,37,37,.15); //0 7px 14px 9px rgba(37,37,37,.08);
		.ui-wrapper {
			display: inline-flex;
			flex-direction: column;
			justify-content: center;
		}

		.ui-btn + .ui-btn {
			margin-top: 30px;
		}
	}

	&__cat {
		@extend .cat;
		display: inline-block;
		opacity: .2;
	}
	//h2 with h1 style
	&__title {
		@extend .font-module-title;
		margin: 0; //margin-top: 30px;
		//margin-bottom: 30px;
		padding: 10px 0;
	}

	&__desc {
		margin: 0;
		font-weight: 300;
		padding: 20px 0;

		+ .ui-wrapper {
			margin-top: 15px;
		}
	}

	&__image {
		width: 100%;
		height: auto;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 0 50%;

		img {
			display: block;
			width: 100%;
			height: auto;
		}

		figcaption {
			display: none;
		}
	}
}

// variations
.banner {
	// white contents
	&.theme-white {
		.banner__meta {
			background: $color-theme-white-bg;
		}
	}
	&.theme-black {
		.banner__meta {
			background: $color-theme-black-bg;
		}
		.banner__title,
		.banner__sub-title,
		.banner__desc {
			color: $color-white;
		}

		a,
		.ui-btn--text,
		.ui-btn--secondary {
			color: $color-red;
		}

	} //right anchored
	&.right {
		.container {
			flex-direction: row-reverse;
		}
	}
}

//experienceeditor
.experienceeditor {
	.banner {
		&__content {
			position: static;
			transform: none;
			width: 100%;
		}
		&__meta {
			width: 100%;
			max-width: none;
			padding: 30px;
			box-shadow: none;
		}
	}
}

@media screen and (min-width: $bp-mobile) {
	.banner {
		&__title {
			//margin-top: 45px;
			//margin-bottom: 45px;
		}

		&__meta {
			width: 100%;
			max-width: 600px;
			padding: 30px 40px 40px; //60px;
			margin: -120px auto 10px; //120px auto 30px;
			@include module-box-shadow();

			.ui-wrapper {
				display: block;
			}

			.ui-btn + .ui-btn {
				margin-left: 20px;
			}
		}

		&__image {
			height: 400px;

			img {
				display: none;
			}
		}
	}
}

@media screen and (min-width: $bp-tablet) {
	.banner {
		&__meta {
			padding: 30px 50px 50px; //10px 60px 60px;
		}
		&__title {
			font-size: 36px;
			line-height: 40px;
		}
	}
}

@media screen and (min-width: $bp-desktop-small) {
	.banner {
		&__content {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 100%;
			margin: 0;
		}
		&__meta {
			width: 100%;
			max-width: 550px;
			margin: 0;
			/*padding: 80px 140px 80px 100px;*/
		}
		&__image {
			height: 625px; // $banner-img-desktop-h;
		}

        &+.banner {
            margin-top: 10px;
        }
	}
    //angled variations(only in desktop)
	/*.banner {
		//banner + banner
		& + .banner {
			$offset-y: 50px;
			@include set-brand-clip($offset-y, 'top-right');
			margin-top: -50px; //margin-top: -$offset-y;
			z-index: 1;

			//.banner__image {
			//	height: 685px; //$banner-img-desktop-h + $offset-y;
			//}
		}
	}*/
	/*body[class*="ie-"] {
		.banner {
			//banner + banner
			&+.banner {
				margin-top: 0;
				.banner__image {
					height: $banner-img-desktop-h;
				}
			}
		}
	}*/
}

//language overrides
.ja-JP,
.zh-CN {

	.banner__title {
		font-size: 18px;
	}

	@media screen and (min-width: $bp-tablet) {
		.banner__title {
			font-size: 22px;
		}
	}

	@media screen and (min-width: $bp-desktop-small) {
		.banner__title {
			font-size: 26px;
		}
	}
}
