/**
* Product Pipeline
*
* @section module
*  
*/

$p-border-color: #e0e0e0;
.product-pipeline {
	a:not(.p-item):not(.close-btn) {
		color: $color-red;
		&:hover,
		&:active {
			text-decoration: underline;
		}
	}
	table {
		width: 100%;
		min-width: $csl-table-min-width;
		border-collapse: collapse;
		border-spacing: 0;
		border: 0;
		//min-width: 768px;
		font-size: 15px;
		line-height: 25px;
		table-layout: fixed;
	}
	table+table {
		margin-top: 60px;
	} //thead
	thead {
		border-bottom: 1px solid $p-border-color;
		th {
			text-align: left;
		}
		p {
			margin: 0;
		}
		span {
			font-weight: 300;
		}
	}
	th,
	td {
		position: relative;
		padding: 10px 30px 10px 0; //30px 30px 30px 0;
		text-align: left;
		vertical-align: top;
		width: 25%; //4 cols
	} //tbody
	td:nth-of-type(1)~td {
		//spaced dotted bottom border
		background-image: linear-gradient(90deg, $p-border-color 50%, transparent 0%);
		background-position: bottom;
		background-size: 20px 1px;
		background-repeat: repeat-x;
	} //override ui components
	&__filters {
		background-color: rgba(255, 255, 255, 1);
		padding: 0 0 30px; //30px 0; //mode
		/*&.fix {
			padding: 0 60px;
			position: fixed;
			top: $header-sm-height;
			left: 0;
			width: 100%;
			z-index: $overlay-index;
			border-bottom: 1px solid $color-gray-4;
			box-shadow: 0px 13px 12px -4px rgba(37, 37, 37, 0.05);
		}*/
		.show-overlay &,
		.search-active &,
		.sidebar-active & {
			display: none;
		}
	}
	&__table {
		background: $color-gray-4;
		>.container {
			overflow-x: auto;
		}
	}
	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: $overlay-index + 1;
		background-color: rgba(0, 0, 0, 0.8);
		overflow: hidden;
		margin: 0;
		padding: 0;
		display: none;
	}
	&.show-overlay {
		.product-pipeline__overlay {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	} // old colors
	// this must be kept since these colors are used for ver 1.(old)
	.ui-checkbox-item {
		&[data-color="blue"] {
			color: #0092c8 !important; // blue
		}
		&[data-color="pink"] {
			color: #ff00fd !important; // pink
		}
		&[data-color="orange"] {
			color: #faa61a !important; // orange
		}
		&[data-color="green"] {
			color: #72bf44 !important; // green
		}
		&[data-color="magenta"] {
			color: #b41e8e !important; // magenta
		}
	}
}

//product item
.p {
	&-item {
		position: relative;
		text-align: left;
		display: block;
		padding: 0 0 10px;

		>* {
			pointer-events: none;
		}
		&:hover {
			.p-name {
				color: $color-red;
				text-decoration: underline;
			}
			.p-color-bar {
				width: 70px;
			}
		}

		&:focus,
		&:active {
			@include global-focus();

			.p-name {
				color: $color-global-focus;
			}

		}

		p {
			margin: -5px 0 0;
		}
	}
	&-color-bar {
		display: inline-block;
		width: 50px;
		height: 5px;
		//margin-bottom: 20px;
		background-color: #eee;
		transition: width 300ms;
	}
	&-name {
		font-weight: 300;
		color: $color-gray-0;
	}
	&-content {
		font-weight: 300;
		color: $color-gray-0;
		display: none; // in experience mode, this should be visible
	}
}

//product detail in overlay
.p-detail {
	background: $color-white;
	padding: 10px; //30px;
	width: 90%;
	position: relative;
	h4 {
		margin-top: 0;
		margin-bottom: 30px;
	}
	p.top {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
	}
	hr {
		margin-top: 45px;
		margin-bottom: 45px;
	}
	p.bottom {
		font-weight: 300;
	}
	.close-btn {
		position: absolute;
		top: -45px;
		right: 0;
		font-size: 18px;
		text-decoration: none;
		i {
			color: rgba(255, 255, 255, 1);
		}
	} // product detail value - color
	// this must be kept since these colors are used for ver 1.(old)
	&[data-color="blue"] {
		.p-cat {
			color: #0092c8 !important; // blue
		}
	}
	&[data-color="pink"] {
		.p-cat {
			color: #ff00fd !important; // pink
		}
	}
	&[data-color="orange"] {
		.p-cat {
			color: #faa61a !important; // orange
		}
	}
	&[data-color="green"] {
		.p-cat {
			color: #72bf44 !important; // green
		}
	}
	&[data-color="magenta"] {
		.p-cat {
			color: #b41e8e !important; // magenta
		}
	}
}

// experienceeditor
.experienceeditor {
	.p-content {
		display: block;
	}
}

// old colors
// this must be kept since these colors are used for ver 1.(old)
.p-item {
	&[data-color="blue"] {
		.p-color-bar {
			background-color: #0092c8 !important; // blue
		}
	}
	&[data-color="pink"] {
		.p-color-bar {
			background-color: #ff00fd !important; // pink
		}
	}
	&[data-color="orange"] {
		.p-color-bar {
			background-color: #faa61a !important; // orange
		}
	}
	&[data-color="green"] {
		.p-color-bar {
			background-color: #72bf44 !important; // green
		}
	}
	&[data-color="magenta"] {
		.p-color-bar {
			background-color: #b41e8e !important; // magenta
		}
	}
}

@media screen and (min-width: $bp-mobile) {
	.p-detail {
		padding: 20px;
	}
}

@media screen and (min-width: $bp-tablet) {
	.product-pipeline {
		table + table {
			margin-top: 120px;
		}

		&__filters {
			padding: 0 0 40px; //90px 0;
			&.fix {
				padding: 0 0 10px;
				position: fixed;
				top: $header-sm-height;
				left: 0;
				width: 100%;
				z-index: $overlay-index;
				border-bottom: 1px solid $color-gray-4;
				box-shadow: 0px 13px 12px -4px rgba(37, 37, 37, 0.05);
			}
		}

		&__table {
			background: $color-gray-4;

			> .container {
				overflow: hidden;
			}
		}
	}
	.p-detail {
		padding: 40px; //90px;
		width: 60%;
	}
}

@media screen and (min-width: $bp-desktop-small) {}

@media screen and (max-width: $bp-mobile - 1px) {
	.p-detail {
		hr {
			border-top: none;
		}
		p.top span {
			position: absolute;
			+ span {
				margin-top: 40px;
			}
		}
	}
}
