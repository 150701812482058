/**
* SideBar(Mobile nav)
*
* @section module
*  
*/

$sidebar-bottom-border: 1px solid #c5c3be !default; //rgb(213, 212, 210) ;
$sidebar-top-height: 105px !default; //90px !default;
$sidebar-sm-height: 40px !default;
$sidebar-bg: #eceae5 !default;
$sidebar-icon-color: #bcb5ac !default; //#d1cac2 !default;
.sidebar {
	position: relative;
	pointer-events: auto;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	z-index: $sidebar-index;
	background-color: $sidebar-bg;
	transform: translateY(-150%); //translateY(-100%);
	will-change: transform;

	ul,
	li {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&.mounted {
		transition: transform $transition-dur $slider-easing;
	}

	&.reached-top {
		#sidebar-back-btn {
			display: none;
		}
	}

	&:not(.reached-top) {
		#sidebar-close-btn {
			display: none;
		}
	}

	&.active {
		transform: none;
	}
}

#sidebar-close-btn,
#sidebar-back-btn {
	transform-origin: center;
	color: $color-red;
	padding: 10px 0 10px 10px;
	display: inline-flex;
	flex-wrap: nowrap;
	>i {
		pointer-events: none;
		user-select: none;
		font-size: 18px;
		font-weight: 100;
	}
	span {
		font-size: 12px;
		font-weight: 400;
		pointer-events: none;
		user-select: noen
	}
}

#sidebar-back-btn {
	vertical-align: middle;
	line-height: 30px;
	i {
		font-size: 30px;
		transform: rotate(90deg);
	}
}

#sidebar-current h7 {
	color: $color-white;
	font-size: 13px;
}

.sidebar__row {
	&.top {
		position: relative;
		height: $sidebar-top-height;
		border-bottom: $sidebar-bottom-border;
		padding: 0 15px;
		display: flex;
		align-items: center;
		.col {
			flex: 1 0 auto;
			position: relative;
			&:nth-of-type(1) {
				text-align: left;
			}
			&:nth-of-type(2) {
				text-align: center;
				opacity: 0;
			}
			&:nth-of-type(3) {
				text-align: right;
			}
		}
	}
}

.sidebar-nav {
	background-color: $sidebar-bg;
}

.sidebar-item {
	position: relative;
	/*height: 45px;*/ //$sidebar-sm-height;
	border-bottom: $sidebar-bottom-border;
	padding: 13px 15px; // 0 15px;
	display: flex;
	align-items: center;

	> a {
		font-size: 15px; //12px;
		font-weight: 500;
		line-height: 18px; // $sidebar-sm-height;
		width: 100%;
		display: inline-block;
		position: relative;
		text-align: left;
		letter-spacing: -.25px;
		color: $color-gray-0; //caret
		&:focus {
			outline: none;
			border: 0;
		}

		> i {
			font-size: 10px;
			flex: 0 0 auto;
			transform: rotate(-90deg);
			color: $sidebar-icon-color;
			pointer-events: none;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%) rotate(-90deg);
		}
		// overide
		&:focus {
			color: $color-global-focus;
			text-decoration: underline;
			outline: none;
			border: 0;
		}
	}
	//children nav
	.sidebar-nav {
		pointer-events: none;
		position: fixed;
		width: 100%;
		height: calc(100% - #{$sidebar-top-height});
		top: $sidebar-top-height;
		left: 100%;
		overflow-y: auto;
		will-change: left;
		transition: left $transition-dur $slider-easing;
		z-index: 1;
		@include webkit-custom-scrollbar(2, 0, 0, $color-red, transparent, 0);
	}
	//parent duplicate
	&.parent-dup {
		> a {
			text-transform: uppercase;
		}
		// only lv 1
		&[data-level="1"] {
			> a > i {
				display: inline-block;
				font-size: 14px;
				transform: translateY(-50%);
			}
		}
	}
	//state
	&.no-children {
		> a > i {
			display: none;
		}
	}
	// &.has-children {}
	//show direction children nav node
	&.active {
		> a {
			color: $color-red;
			pointer-events: none;
		}

		> .sidebar-nav {
			left: 0;
			pointer-events: auto;
			z-index: 99;
		}
	}
}

// ~1024
@include media(md, true) {}

// ~480
@include media(xs, true) {}

//print
@media print {
	.sidebar {
		display: none;
	}
}

//language overrides
.el-GR,
.pl-PL,
.cs-CZ
{
   .sidebar-item.parent-dup>a {
      text-transform: none;
   }
}
