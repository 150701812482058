/**
* Language Selector
*
* -utility links
*
* @section module
*  
*/

.language-selector {
	display: none;
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	background: $color-gray-4;
	z-index: $overlay-index;
	will-change: transform;
	&.active {
		display: block;
		@include animate-in('animate-y-in-minus', 400ms);
	}
	>.container {
		height: 100%;
		//padding-top: 40px; //90px;
		//padding-bottom: 90px;
		padding: 30px; 
	}
	.f-row {
		display: flex;
		height: 100%;
		flex-wrap: wrap;
		align-items: flex-start;
		//margin-left: -30px;
		//margin-right: -30px;
		overflow-x: hidden;
		overflow-y: auto;
		@include webkit-custom-scrollbar(2, 0, 0, $color-red, transparent, 0);
		&[data-lv="0"] {}
		&[data-lv="1"] {
			.col-1+.col-1 {
				margin-top: 30px;
			}
		}
	}
	[class*="col-"] {
		padding-right: 40px; //padding: 0 30px;
	}
	.col-1 {
		flex: 0 0 auto;
		width: 25%;
		.col-1 {
			width: 100%;
		}
	}
	.col-2 {
		flex: 0 0 auto;
		width: 50%;
		.col-1 {
			width: 100%;
		}
	}
	ul,
	li {
		margin: 0;
		padding: 0;
		list-style: none;
		text-align: left;
	}
	li {
		padding: 5px 0;
	}
	/*li+li {
		margin-top: 12px;
	}*/
	h4 {
		margin: 0; //margin-top: 0;
		//margin-bottom: 60px;
		padding: 0 0 10px;
		font-size: 30px;
		line-height: 40px;
		font-weight: 300;
		text-align: left;
		position: relative;
		/*&:before {
			content: "";
			position: absolute;
			bottom: -15px;
			left: 2px;
			width: 8px;
			height: 2px;
			background: black;
			opacity: 0;
		}*/
	}
	p {
		padding: 7px 0;
		margin: 0; //margin: 0 0 30px 0;
		text-align: left;
		font-size: 16px;
	}
	ul+p,
	ul+.f-row:not([data-lv="0"]),
	.f-row:not([data-lv="0"])+ul {
		margin-top: 20px;
	}
	a {
		text-align: left;
	}
	.ui-btn--secondary {
		//line-height: 1.4em;
		//vertical-align: top;
		padding: 0;
		margin: 0;
	}
	.close-btn {
		@include ui-btn-factory('icon');
		position: absolute;
		bottom: 30px;
		left: 50%;
		transform: translateX(-50%);
		font-size: 24px;
		color: $color-gray-0;
	}
}

@media screen and (max-width: $bp-desktop-small - 1px) {
	.language-selector {
		h4 {
			//margin-bottom: 30px;
			padding: 15px 0 0;
		}

		> .container {
			//padding-top: 40px; //60px;
			//padding-bottom: 90px;
			padding: 20px 40px;
		}

		.f-row {
			display: flex;
			margin: 0;
			padding: 0;
			flex-wrap: wrap;
			display: flex;
		}

		[class*="col-"] {
			padding: 0;
		}

		.col-1 {
			flex: 0 0 auto;
			width: 50%;
		}

		.col-2 {
			flex: 0 0 auto;
			width: 100%;
		}
		/*.col-1 + .col-2,
		.col-2 + .col-2 {
			margin-top: 60px;
		}*/
		ul + p,
		ul + .f-row:not([data-lv="0"]),
		.f-row:not([data-lv="0"]) + ul {
			margin-top: 10px;
		}

		.col-2 {
			padding-top: 10px;
		}
	}
}

@media screen and (max-width: $bp-tablet - 1px) {
	.language-selector {
		h4 {
			//margin-bottom: 15px;
		}

		/*> .container {
			padding-top: 30px;
			//padding-bottom: 90px;
		}*/

		.f-row {
			margin: 0;
			padding: 0;
		}

		.col-1,
		.col-2 {
			flex: 0 0 auto;
			width: 100%;
		}

		/*.col-1 + .col-1,
		.col-1 + .col-2,
		.col-2 + .col-2 {
			margin-top: 45px;
		}*/
	}
}

@media screen and (max-width: $bp-mobile - 1px) {
	.language-selector {
		> .container {
			padding: 10px 20px;
		}

		h4 {
			//margin-bottom: 10px;
			font-size: 20px;
			font-weight: 500;
			line-height: 28px;
		}

		p {
			font-size: 15px;
		}

		li {
			font-size: 14px;
			padding: 4px 0;
			.ui-btn--secondary {
				font-size: 15px;
			}
		}

		.col-1,
		.col-2 {
			width: 100%;
		}

	}
}

//language overrides
.ja-JP,
.zh-CN {
	.language-selector h4 {
		font-size: 18px;
		line-height: 26px;
	}

	@media screen and (min-width: $bp-tablet) {
		.language-selector h4 {
			font-size: 24px;
			line-height: 32px;
		}
	}
}
