/**
* Simple popup
*
* @section module
* @author author
*/

@keyframes s-popup-in {
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}
.simple-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: $overlay-index+1;
	background-color: rgba(0, 0, 0, 0.8);
	overflow: hidden;
	margin: 0;
	clear: both;
	display: none;
	pointer-events: none;
	opacity: 0;

	&.active {
		display: flex;
		align-items: center;
		justify-content: center;
		pointer-events: auto;

		animation-name: s-popup-in;
		animation-duration: 300ms;
		opacity: 1;
	}
	.inner {
		background: $color-white;
		padding: 45px 30px;
		width: 90%;
		max-width: 600px;
		position: relative;
		max-height: 90vh;
		overflow-x: hidden;
		overflow-y: auto;
		@include webkit-custom-scrollbar(2, 0, 0, $color-red, transparent, 0);
	}

	// .content-container {}
	.popup-content {
		display: block;
	}
	.close-btn {
		position: absolute;
		top: 15px;
		right: 15px;
		text-decoration: none;
		i {
			font-size: 18px;
			color: $color-red;
			pointer-events: none;
		}
	}
}

body[class*="ie"] {

	.simple-popup.active{
		animation: none;
	}

}


.popup-content {
	display: none;
}

@media screen and (min-width: $bp-mobile) {

	.simple-popup {
		.inner {
			padding: 60px 45px;
		}
	}

}
