/**
* Mosaic/Advanced Mosaic
*
* @section module
*  
*/

$t-img-width: 300px;
$t-height: 600px;
$t-gutter: 30px;

.mosaic {
	overflow: hidden;
	background: $color-gray-7;

	//modue top title/desc
	.module__top {
		text-align: center;
		overflow: hidden;
		padding: 0 40px;
		margin: 0; //margin-bottom: 60px;
		h2 {
			@extend .h1;
			margin-right: auto;
			margin-left: auto;
			font-weight: 100;
			max-width: 60%;
		}

		p {
			max-width: 50%;
			margin: 0 auto;
			color: $color-font-gray;
		}
	}

	.grid {
		margin: 10px auto; //30px auto 0 auto;
		overflow: hidden;
	}

	.filter-container {
		display: block;
		padding: 30px 0 25px;
		//padding-top: 60px;
		/*padding-bottom: 60px;*/
		//margin-bottom: 60px;
		/*border-bottom: 1px solid $color-gray-3;*/
	}

	.load-more-container {
		text-align: center;
		margin: 20px 0 40px 0;

		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&.hide-ui {
		.filter-container {
			display: none;
		}
	}
}


.mosaic.advanced-mosaic {
	.js-load-more-alternative {
		display: none;
	}

	.filter-container {
		border-top: 1px solid $color-gray-3;
	}
	// override partial news-hub(logo + text)
	.news-hub {
		background: transparent;

		&.has-hero-image {
			.filter-container {
				border-top: 1px solid $color-gray-5;
				border-bottom: 0;
				padding: 30px;
				margin: 0;
			}
		}

		&:not(.has-hero-image) {
			.f-row {
				min-height: 0;
				background: transparent;
				// nullify d shadow
				margin-bottom: 0;
				transform: none;
				filter: none;
				clip-path: none;
			}
			//override
			.filter-container {
				//display: block;
				padding-top: 30px; //60px;
				padding-bottom: 30px; //60px;
				margin-bottom: 15px; //60px;
				border-top: 1px solid $color-gray-3;
				border-bottom: 1px solid $color-gray-3;
			}
		}
	}
	//variation
	// &.mosaic-tiles {
	// }
	&.mosaic-list {
		.m-sizer {
			width: 100%;
		}
	}

	.experienceeditor &,
	&:not(.has-hero-image) {
		.module__top.bottom {
			display: none;
		}
	}
}

.m-sizer {
	width: calc(33% - #{$t-gutter});
}

.m-list {
	width: 100%;
	border-bottom: 1px solid #e0e0e0;
	padding: 0 0 20px; //20px 0px 50px; //25px 0;

	& + .m-list {
		padding-top: 25px;
	}

	a {
		color: $color-gray-0;
		font-weight: 500;
	}

	a:active,
	a:focus,
	a:hover {
		@include global-focus();
	}

	&__title {
		margin-top: 0;
		margin-bottom: 15px;
	}

	&__desc {
		font-size: 14px;
		font-weight: 300;
		margin-top: 15px;
		margin-bottom: 0;
	}

	&__meta {
		span + span {
			margin-left: 30px;
			position: relative;

			&:before {
				content: "";
				left: -15px;
				top: 0;
				position: absolute;
				width: 1px;
				height: 100%;
				background: $color-gray-0;
			}
		}
	}

	.ui-wrapper {
		display: block;
		text-align: left;
		margin-top: 30px; //60px;

		a {
			font-size: 15px;
			color: #e7040b;
		}
	}
}

.m-tile {
	color: $color-gray-0;
	font-size: 20px;
	float: left;
	overflow: hidden;
	box-sizing: border-box;
	box-shadow: 0 0 7px 2px rgba(37,37,37,.05); //0px -1px 7px 2px rgba(37, 37, 37, 0.05);
	position: relative;
	height: $t-height; //fixed height
	margin-bottom: $t-gutter;
	background-color: $color-white;
	//3 col
	background-color: $color-white;

	&__inner {
		position: relative;
		width: 100%;
		height: 100%;
	}

	&__content {
		padding: 25px 30px; //35px 35px 25px;
	}

	&__image {
		position: relative;
		overflow: hidden;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;

		img {
			width: 100%;
			height: auto;
			display: none;
		}
		//video circle btn
		.ui-btn--circle-video {
			display: none;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate3d(-50%, -50%, 0);
			z-index: 2;
		}
	}

	&__title {
		margin-bottom: 0;
		margin-top: 0;
		font-size: 24px;
		font-weight: 300;
		line-height: 32px;
	}

	&__desc {
		margin: 0; //margin-top: 15px;
		font-weight: $font-weight-medium;
		padding: 20px 0;
		line-height: 1.5em;
	}

	&__meta {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: calc(100% - 90px);
		position: absolute;
		bottom: 30px;
		/*left: 45px;*/
		.ui-wrapper {
			border-bottom: 1px solid $color-gray-5;
			padding-bottom: 20px;
			margin-bottom: 20px;
			flex: 0 0 auto;
			width: 100%;
			display: flex;
			flex-direction: row;

			.ui-btn + .ui-btn {
				margin-left: 30px;
			}
		}
	}
	/*.ui-btn--secondary {
		text-align: left;
	}*/
	//size variations
	//2x2
	&--2x2 {
		width: calc(66% - #{$t-gutter});

		.m-tile__inner {
			display: flex;
		}

		.m-tile__image {
			flex: 0 0 auto;
			width: 50%;
			background-repeat: none;
			background-size: cover;
			background-position: 50%;

			> a:not(.ui-btn) {
				text-decoration: none;
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 1;
			}
		}

		.m-tile__content {
			flex: 0 0 auto;
			width: 50%;
			height: 100%;
			padding-bottom: 120px;
			position: relative;
		}

		.m-tile__title {
			//margin: 0 0 40px 0;
		}
	}
	//1x2 tile
	&--1x2 {
		width: calc(33% - #{$t-gutter});

		.m-tile__inner {
			display: block;
		}

		.m-tile__image {
			flex: 0 0 $t-img-width;

			> a:not(.ui-btn) {
				position: static;
				width: auto;
				height: auto;
				display: block;

				&:focus {
					// red & underline
					outline: none;
					text-decoration: none;
					border: 1px solid $color-global-focus;
				}
			}

			img {
				pointer-events: none;
				user-select: none;
				display: block;
			}
		}

		.m-tile__content {
			flex: 0 0 auto;
			width: 100%;
		}
	}
	//normal stack version
	&--normal {
		width: 100%;
		height: auto;

		.m-tile__meta {
			position: static;
			width: 100%;
			//margin-top: 60px;
		}

		.m-tile__image {
			img {
				display: block;
			}
		}
	}
}

@media screen and (min-width: $bp-desktop-small) {

	.news-hub {
		&.has-hero-image {
			.filter-container {
				display: block;
			}
		}

		&:not(.has-hero-image) {
			.filter-container {
				display: block;
			}
		}
	}
}

@media screen and (min-width:$bp-tablet) {

	.m-tile {
		margin-left: 20px;
		margin-top: 10px;

		&__title {
			font-size: 30px;
			line-height: 40px;
		}

		.ui-btn--secondary {
			text-align: left;
		}
	}
}

@media screen and (max-width: $bp-desktop-small - 1px) {
	.mosaic {
		.module__top {
			//margin-bottom: 30px;

			h2 {
				max-width: none;
				margin: 0; //margin-bottom: 30px;
				padding: 20px 0;
			}

			p {
				max-width: 360px;
			}
		}

		.filter-container {
			/*margin-top: 30px;*/
			//margin-bottom: 30px;
			display: none;
		}
	}

	.mosaic.advanced-mosaic {
		.news-hub {
			&.has-hero-image {
				.filter-container {
					display: none;
				}
			}
		}

		.news-hub {
			&:not(.has-hero-image) {
				.filter-container {
					display: none;
				}
			}
		}
	}

	.m-tile {
		.ui-btn--secondary {
			text-align: center;
		}
	}

	.iso-tiles {
		.m-sizer,
		.m-tile--1x2,
		.m-tile--2x2 {
			width: calc(50% - #{$t-gutter});
			height: auto;

			.m-tile__meta {
				width: 100%;
				position: static;
				//margin-top: 25px; //45px; //60px;
			}
		}

		.m-tile--2x2 {
			.m-tile__inner {
				display: block;
			}

			.m-tile__content {
				padding-bottom: 25px;
				width: 100%;
			}

			.m-tile__image {
				width: 100%;
				background-repeat: none;
				background-size: cover;
				background-position: 50%;

				> a:not(.ui-btn) {
					position: static;
				}

				img {
					display: block;
				}
			}
		}
	}
}

@media screen and (max-width: $bp-tablet - 1px) {
	.m-list {
		&__desc {
		font-size: 16px;
		}
	}
	.mosaic {
		padding: 15px 0 0;
		/*.grid {
			max-width: calc(100% - #{$t-gutter});
		}*/
		.module__top {
			h2 {
				font-size: 42px;
				line-height: 50px;
			}
		}
	}

	.iso-tiles {
		.m-sizer,
		.m-tile--1x2,
		.m-tile--2x2 {
			width: 100%;

			.m-tile__content {
				padding: 15px; //30px 25px;
				text-align: center;
			}
			//mobile only cta
			.ui-wrapper {
				//margin-top: 30px;
				display: block; //inline-flex;
				flex-direction: column;

				.ui-btn + .ui-btn {
					margin-top: 30px;
					margin-left: 0;
				}
			}
		}
	}	
}

//print
@media print {
	.mosaic {
		.grid {
			height: auto !important;
		}

		.m-tile {
			width: 100%;
			height: auto;
			position: static !important;
		}

		.m-tile__image {
			background-image: none !important;

			img {
				display: block;
			}
		}

		.m-tile__image .ui-btn--circle-video {
			display: none;
		}

		.m-tile__meta {
			width: 100%;
			height: auto;
			position: static;
			display: block;
		}

		.load-more-container {
			display: none;
		}
	}
}

//language overrides
.de-DE {
	.m-tile {
		&__title {
			font-size: 28px;
			line-height: 1.2em;
		}
	}
}

//language overrides
.ja-JP,
.zh-CN {
	.mosaic .module__top h2 {
		font-size: 24px; //42px;
		line-height: 32px; //50px;
	}

	.m-tile__title {
		font-size: 20px; //24px;
		line-height: 30px; //32px;
	}

	.m-tile__desc {
		font-weight: 300;
	}

	@media screen and (min-width: $bp-tablet) {
		.mosaic .module__top h2 {
			font-size: 30px; //42px;
			line-height: 38px; //50px;
		}

		.m-tile__title {
			font-size: 22px; //24px;
			line-height: 30px; //32px;
		}
	}
}
