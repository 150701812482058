/**
* Base(CSLBeriing)
*
* Load all common variables and defined local(project) variables
*
* @section framework
*/
// default sass env
$ENV: 'development' !default;

// set project vars
$root-dir: '/dist/cslbehring' !default;

@if ($ENV != 'development') {
	$root-dir: '..';
}

// import common variables
@import '../common/base';

// @debug('current ENV: ' + $ENV, ' root: ', $root-dir);
