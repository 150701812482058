/**
* Dropdown Table
*
* @section module
*  
*/

.dropdown-table {
	padding: 10px 0 20px; 

	&__top {
		padding: 0 0 10px;
		h4 {
			@extend .h4;
			font-size: 24px;
			line-height: 32px;
			font-weight: 500;
			margin: 0;
		}

		p {
			margin-bottom: 40px;
			font-weight: 300;
		}
	}

	&__title {
		font-weight: 500;
		margin-top: 0;
		margin-bottom: 15px;
		font-size: 15px;
	}

	&__desc {
		font-weight: 300;
		margin-top: 30px;
		margin-bottom: 0;
	}

	.ui-wrapper {
		display: block;
		text-align: center;
		margin-top: 15px;
	}

	table {
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;
		border: 0;
		font-size: 15px;
		line-height: 25px;
		table-layout: fixed;
	}

	thead {
		border-bottom: 1px solid #e0e0e0;
	}
	//tr group by id(data-id)
	tbody {
		border-bottom: 1px solid #e0e0e0;
		overflow: hidden;

		&.active {
			a.anchor > em,
			a.anchor > .icon {
				display: inline-block;
				transform: rotate(180deg);
			}

			.expanded-row {
				display: table-row;
				will-change: tranform;
				transform-origin: 50% 0;
				animation: 300ms linear csl-table-row-open;
			}
		}
	}
	//rows
	.header-row {
		td {
			padding: 10px 30px 10px 3px; 
			line-height: 18px;
			@media screen and (min-width: $bp-tablet) {
				padding: 15px 30px 15px 3px;
			}
		}
	}

	.expanded-row {
		font-size: 15px;
		font-weight: 300;
		display: none;

		td {
			@media screen and (max-width: 479px) {
				display: block;
				width: 100%;
				padding-right: 0;
			}
		}
	}

	th,
	td {
		position: relative;
		padding: 8px 40px 8px 0;
		text-align: left;
		vertical-align: top;
		
		p {
			margin: 0;
			font-size: 15px;
			padding: 0;
			line-height: 1.5em;
		}
	}

	// caret in th
	a.anchor {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		transform-origin: 50%;
		will-change: transform;
		z-index: 1;
		color: $color-red;
		width: 100%;
		text-align: right;
		padding: 8px 0;

		> em,
		> .icon {
			text-align: center;
			font-size: 24px;
			pointer-events: none;
			text-decoration: none;
			outline: none;
			float: right;
		}
	}
}

//print
@media print {
	.dropdown-table {
		.ui-wrapper {
			display: none;
		}

		a.anchor {
			display: none;
		}

		.expanded-row {
			display: block;
		}

		table {
			table-layout: auto;
		}

		th,
		td {
			width: auto;
		}
	}
}
