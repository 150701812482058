/**
* Content Block
*
* left | right
*
* @section module
*  
*/

.content-block {
	//padding: 10px 0;

	&__row {
	}

	&__img {
		background-position: center;
		background-repeat: no-repeat;
		background-size: 1px;

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	&__content {
		padding: 10px 0px; //30px 20px;
		h4 {
			margin: 0; //margin-top: 0;
			//margin-bottom: 20px;
			padding: 10px 0;
			font-weight: 300;
			font-size: 24px;
			line-height: 30px;
		}

		p {
			font-weight: 300;
			padding: 10px 0; //margin-bottom: 30px;
			margin: 0;
		}
	}

	.ui-wrapper {
		display: inline-flex;
		flex-direction: column;

		.ui-btn + .ui-btn {
			margin: 30px 0 0 0;
		}
	}
}

//not using(color)
// .content-block {
//   &.theme-black{}
//   &.theme-white{}
//   &.theme-gray{}
// }

//experienceeditor
.experienceeditor {
  .content-block {

    &__row {
      display: block;
    }

    &__img {
      width: 100%;
      background-size: 1px;

      img {
        display: block;
      }
    }

    &__content {
      width: 100%;
    }

  }
}

@media screen and (min-width: $bp-tablet) {
	.content-block {

		.ui-wrapper {
			display: inline-flex;
			flex-direction: row;

			.ui-btn + .ui-btn {
				margin: 0 0 0 30px;
			}
		}

		&__content {
			h4 {
				//margin-bottom: 30px;
				font-size: 30px;
				//line-height: 40px;
			}
		}
	}		

	.content-block.right {
		.content-block__row {
			flex-direction: row-reverse;
		}
	}
}
	

@media screen and (min-width: $bp-desktop-small) {

	.content-block {

		&__row {
			display: flex;
			flex-direction: row;
			align-items: stretch;
			padding: 15px 0; //margin-bottom: 50px;
		}

		&__img {
			flex: 0 0 auto;
			width: 50%;
			margin: 0;
			max-height: none;
			padding-top: 10px; //padding: 10px 0;
		}

		&__content {
			flex: 0 0 auto;
			width: 50%;
			overflow: hidden;
			align-self: center;
			padding: 0 40px;

			h4 {
				padding-top: 0;
			}

			p {
				/*margin-bottom: 90px;*/
			}
		}

		&.left {
			.content-block__content {
				padding-right: 0px;
			}
		}

		&.right {
			.content-block__content {
				padding-left: 0px;
			}
		}
	}
}

//language overrides
.ja-JP,
.zh-CN {
	.content-block__content h4 {
		font-size: 18px; //24px;
		line-height: 26px; //30px;
	}

	@media screen and (min-width: $bp-tablet) {
		.content-block__content h4 {
			font-size: 22px;
			line-height: 30px;
		}
	}
}
