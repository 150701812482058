/**
* Event List
*
* @section module
*  
*/

.event-list {
	padding: 10px 0; //30px 0;

	&__top {
		padding: 0 0 15px;
		h4 {
			@extend .h4;
			font-size: 24px;
			line-height: 32px;
			font-weight: 500;
			margin: 0;
		}

		p {
			margin-bottom: 20px; //40px;
			max-width: 768px;
			font-weight: 300;
		}
	}

	&__bottom {
		overflow-x: auto;
		overflow-y: hidden;
		padding-bottom: 60px;
	}

	&__title {
		font-weight: 500;
		margin-top: 0;
		margin-bottom: 15px;
		font-size: 15px;
	}

	&__desc {
		font-weight: 300;
		margin-top: 30px;
		margin-bottom: 0;
	}

	&__item {
		position: relative;

		&.show-tooltip {
			z-index: 1; // calendar icon
			.ui-icon-btn.js-show-tooltip {
				@extend .disabled;
			}

			.event-tooltip {
				opacity: 1;
				transform: none;
				pointer-events: auto;
			}
		}

		&.gapi-success {
			// calendar icon
			.ui-icon-btn.js-show-tooltip {
				@extend .disabled;
			}
		}

		&:nth-of-type(1) {
			.event-tooltip {
				top: 10px;
			}
		}
	}
	//----override----//
	.event-tooltip {
		position: absolute;
		top: -60px;
		right: 15px;
		pointer-events: none;
		opacity: 0;
		transform: rotateX(90deg);
	}
	// load more
	.ui-wrapper {
		display: block;
		text-align: center;
		margin-top: 50px;
	}
	// table
	table {
		width: 100%;
		//min-width: $csl-table-min-width;
		border-collapse: collapse;
		border-spacing: 0;
		border: 0;
		table-layout: auto;
		font-size: 15px;
		line-height: 25px;
	}
	//tr group by id(data-id)
	tbody {
		overflow: hidden;

		&:hover {
			a.anchor {
				transform: translateY(calc(-50% + 2.5px));
			}
		}

		&.active {
			a.anchor {
				transform: rotate(180deg) translateY(50%);
			}

			.expanded-row {
				display: table-row;
			}
		}
	}
	//rows
	tr {
		border-bottom: 1px solid #e0e0e0;

		&:nth-of-type(1) {
			border-top: 1px solid #e0e0e0;
		}
	}

	th,
	td {
		position: relative;
		padding: 10px 30px 10px 3px; //30px 30px 30px 0;
		text-align: left;
		vertical-align: middle;

		p {
			margin: 0;
			font-size: 15px;
			padding: 0;
			line-height: 1.5em;
		}

		.ui-icon-btn.js-show-tooltip {
			color: $color-aa-red;
			font-size: 30px;

			em,
			i {
				@extend .icon-date;
			}

			&:active,
			&:focus {
				outline: none;
				border: 1px solid $color-aa-red;
			}
		}

		a:not(.ui-icon-btn):not(.ui-btn) {
			color: $color-aa-red;
			@include global-focus();
		}

		&:nth-last-of-type(1) {
			padding: 30px 15px 30px 0;
			width: 30px;
		}
	}
}

.event-tooltip {
	will-change: transform, opacity;
	transition: all 200ms ease-in-out;
	transform-origin: 60%;
	padding: 30px;
	background-color: rgba(255, 255, 255, 1);
	z-index: 5;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: column;
	min-width: 260px;
	text-align: left;
	box-shadow: 0px -1px 14px 7px rgba(37, 37, 37, 0.05);
	.close-btn {
		color: $color-aa-red;
		font-size: 18px;
		position: absolute;
		top: 15px;
		right: 15px;
	} // msg
	em {
		font-size: 12px;
		font-style: normal;
		color: $color-aa-red;
		display: none;
	}
	.event-tooltip-fn-btns {
		margin-bottom: 15px;
		display: flex;
		flex-direction: column;
		align-items: center;

		a+a {
			margin-top: 15px;
		}
		.js-insert-date {
			display: none;
		}
	}

	// js-g-authorize-btn, js-g-signout-btn
	.ui-btn--box+.ui-btn--box {
		margin-top: 15px;
	}
	// js-insert-date, js-ics
	.ui-btn--secondary {
		text-align: left;
	} // state with gapi
	&.gapi-user-signed {
		.js-insert-date {
			display: inline-block;
		}
	}
	&.gapi-success {
		em {
			display: block;
		} // hide insert btn & js-g-authorize-btn, js-g-signout-btn
		.js-g-authorize-btn,
		.js-g-signout-btn,
		.js-insert-date {
			display: none !important;
		}
	}
	&.gapi-error {
		em {
			display: block;
		}
	}
}

// min-width
@media screen and (min-width: $bp-mobile) {
	.event-list {
		&__top {
			p {
				margin-bottom: 60px;
			}
		}

		&__item {
			padding: 30px 0;
		}
		//----override----//
		.event-tooltip {
			top: -60px;
			right: 30px;
		}


		td,
		th {
			padding: 15px 30px 15px 3px;
		}
	}
}

@media screen and (min-width: $bp-tablet) {
	.event-list {
		padding: 120px 0 0 0;
	}
	&__top {
		h4 {
			font-size: 30px;
			line-height: 40px;
			font-weight: 300;
		}

		p {
			//
		}
	}
}

@media screen and (min-width: $bp-desktop-small) {
	.event-list {
		&__desc {
			margin-top: 50px;
		}

		td,
		th {
			padding: 20px 30px 20px 3px;
		}
	}
}
