/**
* Blockquote
*
* @section module
*  
*/

.block-quote {
	display: flex;
	flex-direction: column;
	padding: 10px 0 30px;

	&__image {
		display: none;
	}

	&__image--mobile {
		display: block;
		width: 100%;
		height: auto;
	}

	&__image-wrapper {
		overflow: hidden;
		max-height: 175px; //300px;
	}

	&__body {
		@include module-box-shadow(); //box-shadow: 0 7px 14px 9px rgba(37,37,37,.08);
		position: relative;
		//padding: 0px 0px 20px; //30px 0;
		margin: -60px auto 0;
		width: calc(100% - 30px);
		text-align: center;
		z-index: 1;
		background: $color-white;
		max-width: 1200px;

		.tag {
			display: block;
			width: 100%;
			font-weight: 100;
			text-align: center;
			color: inherit;
		}

		blockquote {
			text-align: center;
			margin: 0;
			padding: 15px;

			p {
				font-size: 16px; //20px;
				line-height: 1.7em; //30px;
				font-weight: 300; //100;
				//margin-bottom: 15px;
				margin: 0;
				padding-bottom: 20px;
				//add this with blockquotes fix
				span {
					font-size: 1.1em;
					padding: 0 0.2em;
					font-family: "Times New Roman", Times, serif;
				}

				//remove this with blockquotes fix
				/*
				&::before,
				&::after {
					content: "\"";
				}*/
			}

			cite {
				margin: 0;
				font-size: 15px;
				line-height: 20px;
				font-style: normal;
				font-weight: $font-weight-bold;
			}
		}
	}
	// color thems
	&.theme-white {
		.block-quote__body {
			color: $color-font-black;
			background: $color-theme-white-bg;
		}
	}

	&.theme-black {
		.block-quote__body {
			color: $color-font-white;
			background: $color-theme-black-bg;
		}
	}
}

// 480~
@media screen and (min-width: $bp-mobile) {
	.block-quote {
		&__body {
			@include module-box-shadow();
			margin: -60px auto 0; //margin-bottom: 30px;
			padding: 0;
			width: calc(100% - 60px);
			blockquote {
				padding: 25px;
			}
		}

		&__image-wrapper {
			max-height: 250px //405px;
		}

		blockquote {
			p {
				font-size: 24px; //30px;
				line-height: 34px; //40px;
				font-weight: $font-weight-light;
				//margin-bottom: 20px; //margin: 36px 0; //50px 0;
			}
		}
	}
}

// 768~
@media screen and (min-width: $bp-tablet) {
	.block-quote {
		&__image--mobile {
			display: none;
		}
		&__body {
			align-self: center;
			text-align: left;
			padding: 10px 5%; // 60px 120px;
			margin: -100px 0 0;
			width: 80%;
		}
		
		&__image-wrapper {
			max-height: 400px;
		}
		blockquote {
			p {
				font-size: 30px;
				line-height: 40px;
				font-weight: $font-weight-light;
				//margin: 50px 0;
			}
		}
	}
	.block-quote__image.block-quote__image--desktop {
		display: block;
		margin-left: 50%;
		transform: translateX(-50%);
		min-width: 100%;
		width: 100%;
		height: auto;
	}
}

// 1024~
@media screen and (min-width: $bp-desktop-small) {
	.block-quote {
		&__image-wrapper {
			max-height: 450px;
		}
		&__body {
			padding: 20px 10%; //80px 17.5%;
		}
	}
}

@media screen and (min-width: 1280px) {
	.block-quote {
		&__image-wrapper {
			max-height: 500px;
		}

		&__body {
			padding: 30px 10%; //80px 17.5%;
		}
	}
}

//language overrides
.ja-JP,
.zh-CN {
	@media screen and (min-width: $bp-mobile) {
		.block-quote blockquote p {
			font-size: 18px;
			line-height: 26px;
		}
	}

	@media screen and (min-width: $bp-tablet) {
		.block-quote blockquote p {
			font-size: 24px;
			line-height: 32px;
		}
	}
}
