/**
* Inline Search
*
* @section module
*  
*/

//inline module
.inline-search {
	width: 100%;
	padding: 20px 0; //30px 0 2px;
	background: $color-gray-4;
	position: relative;
	z-index: 1;
	pointer-events: auto;

	> .container {
		width: 100%;
		@media screen and (min-width: $bp-tablet) {
			width: 750px;
		}
		@media screen and (min-width: $bp-desktop-small) {
			width: 1008px;
		}
		@media screen and (min-width: 1200px) {
			width: 1180px;
		}
	}

	ul,
	li {
		padding: 0;
		margin: 0;
		list-style: none;
	}

	&__row {
		//margin-bottom: 30px;
		padding-bottom: 10px;
	}

	&__popular {
		//width: 100%;
		display: flex;
		flex-wrap: no-wrap;
		align-items: center;
		position: relative;
		margin: 0; //padding-bottom: 25px;
		padding-left: 10px;
		.col {
			flex: 0 0 auto;

			&:nth-of-type(1) {
				display: none;
			}

			&:nth-of-type(2) {
				//width: 100%;
				position: relative;
				//padding: 0 30px;
				height: 30px; //carets only for mobile
				&:after,
				&:before {
					@include icon();
					content: "";
					position: absolute;
					width: 20px;
					line-height: 30px;
					font-size: 20px;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					z-index: 3;
					opacity: .25;
				}
				// left
				&:before {
					content: "\e000";
					left: 0px;
				}
				// right
				&:after {
					content: "\e001";
					right: 0px;
				}
			}
		}

		ul {
			display: flex;
			flex-wrap: no-wrap;
			align-items: center;
			height: 100%;
			overflow-x: auto;
		}

		li {
			flex: 0 0 auto;
			white-space: nowrap;
			width: auto;
			margin: 0;

			& + li {
				margin-left: 30px;
			}

			> * {
				display: block;
			}
		}

		p {
			text-transform: uppercase;
			font-size: 12px;
			line-height: 15px;
			color: $color-gray-2; //AA
			margin: 0;
		}

		a,
		.ui-btn--secondary {
			@include secondary-btn-color('gray');
			font-size: 12px;
			line-height: 15px;
			font-weight: 500;
			// color: darken($color-gray-9, 35%);
			color: $color-gray-2; // AA
			&:active,
			&:hover,
			&:focus {
				@include global-focus();
			}
		}
	}
	//svg
	svg {
		display: block;
		width: 100%;
		height: auto; //60px;
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden;
		transform: translateY(-100%);
	}

	polygon {
		fill: $color-gray-4;
	}
}

/* body:not([class*="ie"]):not([class*="edge"])
{
   .hero + .inline-search,
   .internal-hero + .inline-search,
   .inline-search .wedged
   {
      svg
      {
         display: none;
      }

      @include set-brand-clip(30px, 'top-left');
      margin-top: -31px;
      padding-top: 60px;
      z-index: 1;
   }
} */

//ie
/*body //body[class*="ie"], body[class*="edge"]
{
   .hero + .inline-search,
   .internal-hero + .inline-search,
   .inline-search.wedged
   {
      z-index: 1;

      svg
      {
         display: block;
			margin-top: 1px;
      }
   }
}*/

@media screen and (min-width: $bp-tablet) {
	.inline-search {
		/*padding: 60px 0;*/
		&__popular {
			.col {
				&:nth-of-type(1) {
					display: block;
					padding-right: 20px; //width: 250px;
				}

				/*&:nth-of-type(2) {
					width: calc(100% - 250px); // left
					&:before {
						left: 0;
					}
					// right
					&:after {
						right: 0;
					}
				}*/

				& + .col {
					padding-left: 30px;
				}
			}

			li {
				margin: 0;
				padding: 5px 35px 5px 0;
				/*& + li {
					margin-left: 60px;
				}*/
			}

			p,
			a,
			.ui-btn--secondary {
				font-size: 15px;
				line-height: 20px;
				padding: 0;
			}
		}
	}
}

@media screen and (min-width: $bp-desktop-small + 1px) {
	.inline-search {
		&__popular {
			.col {
				&:nth-of-type(2) {
					// carest, hide
					&:before,
					&:after {
						opacity: 0;
					}
				}
			}
		}
	}
}

@media screen and (max-width: $bp-tablet - 1px) {
	.inline-search {
		display:none;
	}
}

@media screen and (max-width: $bp-desktop-small - 1px) {
	.inline-search {
		&__popular {
			display: none;
		}
	}
}

//print
@media print {
	.inline-search {
		display: none;
	}
}

//language overrides
.ja-JP,
.zh-CN {

	.ui-input input {
		font-size: 16px;
	}

	@media screen and (min-width: $bp-tablet) {
		.ui-input input {
			font-size: 18px;
		}
	}

	@media screen and (min-width: $bp-desktop-small) {
		.ui-input input {
			font-size: 20px;
		}
	}
}

//language overrides
.el-GR,
.pl-PL,
.cs-CZ
{
   .inline-search__popular p {
      text-transform: none;
   }
}
