/**
* UI Loader
*
* @author <min>
*/

$c-loader-color: rgba(0,0,0,1);
$c-loader-animation-dur: 2000ms;
$c-loader-animation-interation: infinite;


@keyframes scaleX {
  0%   { transform: scaleX(0); }
  50%   { transform: scaleX(0.5); }
  100% { transform: scaleX(1); }
}

@keyframes spin {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes showHide {
  0%        { opacity: 1; }
  50%, 100% { opacity: 0; }
}

/*
  html sample:

  <span class="bar-loader js-loader"></span>
 */
.bar-loader{
  display: inline-block;
  width: 100%;
  height: 2px;
  background: rgba(0,0,0,.9);

  will-change: transform;
  transform-origin: 0 50%;
  transform: scaleX(0);

  &.run {
    animation-duration: $c-loader-animation-dur;
    animation-timing-function: linear;
    animation-iteration-count: $c-loader-animation-interation;
    animation-name: scaleX;
  }
}

/*
  html sample:

  <span class="c-loader js-loader">
    <span class="left"></span>
    <span class="spinner"></span>
    <span class="right"></span>
  </span>
 */
.c-loader {
  background: inherit;
  overflow: hidden;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  pointer-events: none;
  z-index: 0;

  animation-duration: $c-loader-animation-dur;
  animation-iteration-count: $c-loader-animation-interation;

  //has masker
  &.has-masker {
    &:after {
      content: "";
      border-radius: 50%;
      position: absolute;
      width: 90%;
      height: 90%;
      background: inherit;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      z-index: 4;
    }
  }

  &.run {
    .spinner{
      animation-name: spin;
    }
    .left{
      animation-name: showHide;
    }
    .right{
      animation-name: showHide;
    }
  }

  &.paused {
    >* {
      animation-play-state: paused;
    }
  }

  &.running {
    >* {
      animation-play-state: running;
    }
  }

  .left, .right, .spinner{
    position: absolute;
    top:0;
    width: 50%;
    height: 100%;
  }

  //spinner
  .spinner {
    left: 0;
    background: $c-loader-color;
    transform-origin: 100% 50%;
    z-index: 2;
    border-radius: 100% 0 0 100%/ 50% 0 0 50%;

    will-change: transform;
    transform: rotate(0);

    animation-duration: inherit;
    animation-timing-function: linear;
    animation-iteration-count: inherit;
  }

  //left masker
  .left {
    left: 0;
    opacity: 1;
    z-index: 3;

    transform-origin: 100% 50%;
    background: inherit;
    border-radius: 100% 0 0 100%/ 50% 0 0 50%;

    animation-timing-function: steps(1, end);
    animation-iteration-count: inherit;
    animation-duration: inherit;
  }

  //right masker
  .right {
    opacity: 0;
    width: 50%;
    height: 100%;
    border-radius: 0 100% 100% 0/ 0 50% 50% 0;

    right: 0;
    z-index: 1;
    background: $c-loader-color;

    animation-direction: reverse;
    animation-timing-function: steps(1, end);
    animation-iteration-count: inherit;
    animation-duration: inherit;
  }
}
