.basic-content {
	.basic-content-section {
		background-color: #fcfcfc;
	}

	.article__inner {
		padding-top: 0;
	}

	h1.basic-content__pagetitle {
		margin-top: 0;
		margin-bottom: 0;
		padding-top:20px;
	}

	@media screen and (min-width: $bp-tablet) {
		h1.basic-content__pagetitle {
			font-size: 48px;
		}
	}
}
