/**
* Site Header(CSL Behring)
*
* @section module
*/

$header-logo-box-w: 165px;
$header-logo-box-h: 150px;
$header-logo-box-sm-w: 90px;
$header-logo-box-sm-h: 30px;
$header-logo-w: 130px; //
$header-logo-h: 55px; //
$header-row-height: 430px;
$header-hover-bg: #eceae5;
$header-dark-bg: $color-gray-0;
$header-t-duration: 250ms;
$header-col-length: 3;
$header-nav-item-color: $color-gray-6;
$header-border-color: $color-gray-6;
