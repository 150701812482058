/**
* Footer
*
* @section module
*  
*/

$footer-desktop-height: 260px !default;
$footer-mobile-font-size: 9px !default;
$footer-logo-width: 65px !default;

.footer {
	width: 100%;
	background: $color-red;
	overflow: hidden;
	ul,
	li {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	p,
	a {
		font-size: 13px; //12px;
		line-height: 25px;
		font-weight: 300;
		color: $color-white;
		margin: 0;
		padding: 0;
	}
	a {
		&:active,
		&:hover,
		&:focus {
			outline: none;
			text-decoration: underline;
		}
	}

	// lang selector override
	// there are two instances. one in desktop footer, one in mobile footer
	.ui-selector {
		width: auto;
		min-width: 0;

		.ui-selector-label {
			font-weight: 400;
			i {
				display: none;
			}
		}
		.ui-selector-label {
			font-size: $footer-mobile-font-size;
			line-height: 12px;
			letter-spacing: .5px;
		}
		.l-list {
			width: 130px;
			overflow: hidden;
			overflow-y: auto; // show in mobile

			a {
				font-size: $footer-mobile-font-size;
				font-weight: 400;
				line-height: 12px;
				letter-spacing: .5px;
			}
		}
		&:not(.active) {
			.ui-selector-label,
			.l-list a {
				color: $color-white;
			}
		}

		// when it has only default lang
		&.has-limited-item {
			display: none;
		}
	}

}

.footer__logo {
	width: $footer-logo-width; //csl logo
	height: auto;
	display: inline-block;
}

.footer__social {
	display: flex;
	width: 100%;
	li {
		a,
		em,
		i {
			font-size: 16px;
			color: $color-white;
		}
		img{
			display: block;
			width: 22px; //16px;
			height: auto;
		}
	}
	a {
		&:active,
		&:hover,
		&:focus {
			outline: none;
			em,
			i {
				text-decoration: underline;
			}
		}
	}
	li+li {
		margin-left: 20px;
	}
}

.footer__links {
	display: inline-flex;

	li {
		color: $color-white;
	}
	a {
		//letter-spacing: .5px;
		//font-size: 11px;
		height: 25px;
		color: inherit;
	}
	i {
		color: inherit;
	}
}

.footer__desktop {
	padding-top: 60px;
	.top {
		display: flex;
		align-items: flex-start;
		padding-bottom: 40px;
		border-bottom: 1px solid rgba(255, 255, 255, .5);
		.left {
			width: 33%;
			ul {
				display: flex;
			}
			li {
				a {
					display: inline-block;
					color: $color-white;
					font-size: 15px;
					font-weight: 300;
					letter-spacing: .5px;
					line-height: 22px;
				}
				i {
					color: $color-white;
				} //glosary hide it for now.
				// &:nth-of-type(2) {
				// 	display: none;
				// }
			}
			li+li {
				margin-left: 15px;
				padding-left: 15px;
			}
		} //logo
		.center {
			width: 33%;
			flex: 1 1 auto;
			text-align: center;
			a {
				display: inline-block;
			}
		} //social
		.right {
			width: 33%;
			text-align: right;
			.footer__social {
				display: inline-flex;
				width: auto;
			}
		}
	}
	.bottom {
		display: flex;
		align-self: flex-start;
		padding: 30px 0;

		.left,
		.right {
			flex: 0 0 auto;
		}
		.right {
			flex: 1 1 auto;
			padding-left: 15px;
			text-align: right;

			.footer__links {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;

				li {
					a {
						display: inline-block;
						letter-spacing: .5px;
						font-size: 13px; //11px;
						min-height: 22px;
						height: 100%;
						vertical-align: top;
					}
					i {
						color: $color-white;
					}
				}


			}
		}
	}

	// ui selector(lang)
	.ui-selector {
		.l-list {
			max-height: 100px;
		}
	}

}

.footer__mobile {
	padding: 45px 0 20px;
	text-align: center;
	.top {
		padding-bottom: 40px;
	}
	.middle {
		display: block; //flex;
		//align-items: center;
		padding-bottom: 25px;
		border-bottom: 1px solid rgba(255, 255, 255, .5);
		.left {
			//flex: 0 0 auto;
			width: 100%; //33%;
			text-align: center; //left;
		} //logo
		.center {
			//flex: 1 1 auto;
			width: 100%; //33%;
			text-align: center;
			.footer__social {
				justify-content: center;
			}
		}
		.right {
			//flex: 0 0 auto;
			width: 100%; //33%;
			text-align: center; //right;
			padding-top: 20px;
		}
	}
	.bottom {
		padding-top: 25px;

		p {
			font-size: $footer-mobile-font-size;
			font-weight: 500;
		}

		.footer__links {
			display: block;
			padding-bottom: 15px;
			margin-bottom: 15px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.5);

			li {
				width: 100%;

				a {
					line-height: 16px;
					display: inline-block;
				}
			}

			li + li {
				padding: 0;
				margin: 0;

				&:before {
					content: none;
				}



			}
		}
	}

	// ui selector(lang)
	.ui-selector {
		.l-list {
			max-height: 150px;
		}
	}
}

@media screen and (max-width: $bp-desktop-small - 1px) {
	// (max-width: $bp-desktop-small) {
	.footer__desktop {

		.bottom {
			padding: 25px 0;
		}
	}
}

/*@media screen and (max-width: $bp-tablet - 1px) {
	.footer__links {
		a {
			//font-size: $footer-mobile-font-size;
			height: 20px;
			font-weight: 500;
		}
	}
}*/

@media screen and (min-width: $bp-desktop-small) {
	.footer__desktop {
		.ui-selector {
			display: none;
		}

		.bottom {
			.right {
				.footer__links {
					display: flex;
					flex-direction: row;
					justify-content: flex-end; // "|" pipe seperate >= 1024
					li+li {
						margin-left: 5px;
						padding-left: 5px;
						position: relative;
						white-space: nowrap;
						&:before {
							content: "";
							position: absolute;
							top: 14px;
							left: 0;
							width: 1px;
							height: 13px;
							margin-top: -7px;
							background-color: $color-white;
						}
					}
				}
			}
		}
	}
}

//print
@media print {
	.footer__mobile {
		display: none;
	}
}
