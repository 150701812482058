/**
* Internal Hero
*
* @section module
*  
*/
$internal-hero-h: 335px;
$internal-hero-h-md: 340px + $header-height/2;
$internal-hero-h-sm: 235px;

.internal-hero {

  //2.3 wedge
  .hero-wedge {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    svg {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      polygon {
        fill: #fcfcfc;
			}
    }
  }

  overflow: hidden;
  position: relative;
  height: $internal-hero-h-sm;

  background-color: #111;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  padding-bottom: 30px;
  //@include set-brand-clip(20px, 'bottom-right');

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    z-index: 0;
  }

  >.container{
    position: relative;
    height: 100%;
    z-index: 1;
  }

  figure {
    display: none;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .ui-btn--circle-play {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0); //mobile header height/2
    z-index: 2;
    opacity: 0;
    pointer-events: none;

    .c-loader{
      display: none; // turn this one off for now
    }
  }

  &.has-video {
    .ui-btn--circle-play {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.internal-hero__t {
  position: absolute;
  bottom: 0;
  /*left: 30px;*/
  margin-bottom: 0;

  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  letter-spacing: 1.5px;
  color: $color-white;
}

@media screen and (min-width: $bp-mobile) {}

@media screen and (min-width: $bp-tablet) {
  .internal-hero{
    height: 350px; //$internal-hero-h-md;

    padding-bottom: 60px;
    //@include set-brand-clip(15px, 'bottom-right');

    .ui-btn--circle-play {
      top: 50%;//mobile
    }
  }

  .internal-hero__t {
    /*left: 90px;*/
    font-size: 30px;
    line-height: 40px;
    font-weight: 300;
    max-width: 60%;
  }
}

@media screen and (min-width: $bp-desktop-small) {
  .internal-hero{
    height: 335px; //$internal-hero-h;

    padding-bottom: 60px;
    //@include set-brand-clip(60px, 'bottom-right');
  }

  .internal-hero__t {
    /*left: auto;*/
  }
}

@media screen and (min-width: $bp-desktop-medium) {
	.internal-hero {
		height: 400px;
	}
}

		//print
		@media print {

			.internal-hero {
				background-image: none !important;
				background-color: white;
				height: auto;
				clip-path: none;

				.ui-btn--circle-play,
				&:before {
					display: none;
				}

				figure {
					display: block;
				}
			}

			.internal-hero__t {
				position: static;
				color: black;
			}
		}

//language overrides
.ja-JP,
.zh-CN {
	@media screen and (min-width: $bp-tablet) {
		.internal-hero__t {
			font-size: 22px;
			line-height: 30px;
		}
	}

	@media screen and (min-width: $bp-desktop-small) {
		.internal-hero__t {
			font-size: 24px;
			line-height: 30px;
		}
	}
}
