/**
* HTTP Status Pages
* pages for various HTTP status
*
* @author <min>
*/

.p-403,
.p-410,
.p-404 {
	margin-top: 100px;
}
