/**
* UI Dropdown
* Use UI elements to create basic user-select: features like buttons or tables
*
* @author <min>
*/

$ui-dropdown-h: 60px;
$ui-dropdown-font-size: 15px;
$ui-dropdown-icon-size: 25px;

.ui-dropdown {
  min-width: 300px;
  border: 1px solid $ui-color-border;
  height: $ui-dropdown-h;
  overflow: hidden;

  will-change: height;
  background: $ui-color-white;
  position: relative;

  &.active {
    height: auto;

    .ui-dropdown__item.top-label {
      i {
        transform: translate3d(50%, -50%, 0) rotate(180deg);
      }
    }
  }

  select {
    width: 100%;
    height: $ui-dropdown-h;
    border-radius: 0;
    border: 0;
    background: $ui-color-gray;
    color: $ui-color-black;
    font-size: 15px;
    line-height: 25px;
    outline: none;
    padding: 0 20px;
  }

  option:checked {
    color: red;
  }

  &.is-rendering {
    select {
      opacity: .5;
    }
  }
}

.ui-dropdown__items {
  width: 100%;
  padding: 0;
  margin: 0;
  display: none;
}

.ui-dropdown__item {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  height: $ui-dropdown-h;

  a {
    color: $ui-color-black;
    font-size: $ui-dropdown-font-size;
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
    line-height: $ui-dropdown-h;
    padding: 0 50px;

    will-change: color;
    transition: color 300ms;

    > * {
      pointer-events: none;
      user-select: none;
    }
  }

  span {
    color: inherit;
  }

  &.top-label {
    background: $color-ui-white;

    //arrow down
    &:after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-color: red transparent transparent transparent;
      border-width: 5px 5px 0 5px;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);

      //not using
      visibility: hidden;
    }

    i {
      @extend .icon-caret-outline;

      color: $ui-color-red;
      font-size: $ui-dropdown-icon-size;

      position: absolute;
      top: 50%;
      right: 50px;
      transform: translate3d(50%, -50%, 0);
    }
  }

  &:not(.top-label) {
    &:hover,
    &.active {
      a {
        color: $ui-color-red;
      }
    }

    &.active {
      cursor: default;
      pointer-events: none;
    }
  }

}

@media screen and (min-width: 480px) {
  .ui-dropdown {
    .ui-dummy-select {
      display: none;
    }
  }
  .ui-dropdown__items {
    display: block;
  }
}
