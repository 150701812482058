/**
* Stock Chart
*
* @section module
*  
*/

.stock-chart {
	background-color: $color-theme-white-bg;
}

@media screen and (min-width: $bp-mobile) {
}

@media screen and (min-width: $bp-tablet) {
}

@media screen and (min-width: $bp-desktop-small) {
}
