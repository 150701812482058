/**
* Fact cards
*
* @section module
*  
*/

.fact-cards {
	padding: 30px 0;
	ul,
	li {
		padding: 0;
		margin: 0;
		list-style: none;
	}
	ul {
		padding: 0;
		display: flex;
		flex-direction: column;
	}
	li {
		list-style: none;
		text-align: center; //left;
		padding: 20px 0; //30px;
	}
	&__value {
		position: relative;
		font-size: 30px; //35px;
		//line-height: 50px;
		letter-spacing: -2px;
		font-weight: $font-weight-ultra-light;
		color: $color-aa-red;
		margin-bottom: 10px; //30px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		span {
			font-weight: inherit;
			text-align: center;
			display: inline-block;
			min-width: 10px;
			opacity: .35;
			&.current {
				opacity: 1;
			}
		}
	}
	&__p {
		font-weight: $font-weight-light;
		font-size: 25px;
		line-height: 30px;
		margin: 0;
	} //color theme
	&.theme-black {
		background: $color-theme-black-bg;
		.fact-cards__p {
			color: $color-white;
		}
	}
	&.theme-white {
		background: $color-white;
	}
	&.theme-transparent {
		background: transparent;
		.fact-cards__p {
			color: $color-white;
		}
	}
}


@media screen and (min-width: $bp-mobile) {
	.fact-cards {
		&__value {
			font-size: 40px; //60px;
			//line-height: 50px; //62px;
		}
	}
}

/*@media screen and (min-width: $bp-tablet) {

	.fact-cards {
		&__value {
			font-size: 60px; 
			line-height: 62px; 
		}
	}
}*/


@media screen and (min-width: $bp-desktop-small) {
	.fact-cards {
		padding: 10px 0;

		&__value {
			//min-height: 115px; //70px;
			justify-content: left;
			font-size: 48px;
			line-height: 52px;
		}

		ul {
			padding: 20px 0; //100px 0;
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: stretch;
		}

		li {
			flex: 0 0 auto;
			width: 33.3%;
			text-align: left;
			padding-right: 20px;
		}
		li+li {
			padding-left: 20px;
		}
	}
}

@media screen and (min-width: 1200px) {
	.fact-cards {
		&__value {
			//min-height: 70px;
			font-size: 54px;
			line-height: 60px;
		}
	}
}
