/**
* UI Buttons
*
* @author <min>
*/

$cta-font-size: 12px!default;
$cta-line-height: 12px!default;
$cta-text-color: #fff!default;

@mixin primary-btn-color($theme: "white-blue") {
	@if $theme=="white-blue" {
		color: $color-black;
		background-color: $color-white;
		&:active,
		&:hover {
			color: $color-white;
			background-color: $color-blue-3;
		}
	}
	@if $theme=="white-gray" {
		color: $color-black;
		background-color: $color-white;
		&:active,
		&:hover {
			color: $color-white;
			background-color: $color-grey-5;
		}
	}
	@if $theme=="gray-blue" {
		color: $color-white;
		background-color: $color-grey-5;
		&:active,
		&:hover {
			color: $color-white;
			background-color: $color-blue-3;
		}
	}
	@if $theme=="black-gray" {
		color: $color-white;
		background-color: $color-black;
		&:active,
		&:hover {
			color: $color-white;
			background-color: $color-grey-5;
		}
	}
}

@mixin secondary-btn-color($theme: "white-blue") {
	@if $theme=="black" {
		color: $color-black;
		&:after {
			background-color: $color-black;
		}
		&:active,
		&:hover {
			color: $color-black;
		}
	}
	@if $theme=="black-red" {
		color: $color-black;
		&:after {
			background-color: $color-black;
		}
		&:active,
		&:hover {
			color: $ui-color-red;
			&:after {
				background-color: $ui-color-red;
			}
		}
	}
	@if $theme=="red" {
		color: $ui-color-red;
		&:after {
			background-color: $ui-color-red;
		}
	}
	@if $theme=="gray" {
		color: $color-gray-9;
		&:after {
			background-color: $color-gray-9;
		}
	}
}

@mixin circle-btn-color($theme: "white-blue") {
	@if $theme=="white-red" {
		background-color: $color-white; //icon
		i {
			color: $ui-color-red;
		} //">" icon
		&:before {
			border-color: transparent transparent transparent $ui-color-red;
		}
	}
	@if $theme=="black-red" {
		background-color: $color-black; //icon
		i {
			color: $ui-color-red;
		} //">" icon
		&:before {
			border-color: transparent transparent transparent $ui-color-red;
		}
	}
	@if $theme=="black-white" {
		background-color: $color-black; //icon
		i {
			color: $color-white;
		} //">" icon
		&:before {
			border-color: transparent transparent transparent $color-white;
		}
	}
	@if $theme=="transparent" {
		background-color: transparent;
		border: 1px solid $color-white; //icon
		i {
			color: $ui-color-red;
		} //">" icon
		&:before {
			border-color: transparent transparent transparent $color-white;
		}
	}

	@if $theme=="transparent-white" {
		background-color: transparent;
		border: 1px solid $color-white; //icon
		i {
			color: $color-white;
		} //">" icon
		&:before {
			border-color: transparent transparent transparent $color-white;
		}
	}

}

@mixin ui-btn-factory($type: 'primary') {
	@if $type=='primary' {
		position: relative;
		display: inline-flex;
		padding: 0 35px;
		height: 60px;
		box-sizing: border-box;
		color: $cta-text-color;
		background-color: transparent;
		align-items: center;
		transition: color $ui-transition-dur, border-color $ui-transition-dur, background-color $ui-transition-dur;
	}
	@if $type=='secondary' {
		color: $ui-color-red;
		position: relative;
		padding: 0 1px 2.5px;
		display: inline-flex;
		align-items: center;

		&:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 2px;
			bottom: 0;
			left: 0;
			background-color: $ui-color-red;
			pointer-events: none;
			opacity: 0;
			will-change: opacity;
			transition: 150ms all;
			transform-origin: 50%;
		}
		&:focus,
		&:active,
		&:hover {
			@include global-focus();
		}

		i {
			margin-right: 5px;
		}
	}
	@if $type=='box' {
		display: inline-block;
		font-family: $ui-font-family;
		font-size: 15px;
		padding: 15px 25px;
		font-weight: 300;
		border-radius: 30px;
		outline: none;
		border: 0;
		text-align: center;
		background-color: $ui-color-red;
		color: $color-white;
		position: relative;
		will-change: color;
		transition: color $ui-transition-dur, background-color $ui-transition-dur;
		&:focus,
		&:hover {
			outline: none;
			background-color: rgb(226, 223, 218); //fc1921
			color: $ui-color-red;
		}
		&.active {
			background-color: $color-white;
			color: $ui-color-red;
			pointer-events: none;
		}
	}
	@if $type=='icon' {
		position: relative;
		.icon,
		i {
			vertical-align: middle;
			pointer-events: none;
		}
		&:after {
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.001);
			position: absolute;
			top: 0;
			left: 0;
			z-index: 10;
			content: "";
		}
		&:focus,
		&:active {
			outline: none;
			border: 1px solid $ui-color-red;
			text-decoration: none;
		}
	}
}

%ui-btn,
// UI block
.ui-btn {
	display: inline-block;
	font-size: $cta-font-size;
	line-height: $cta-line-height;
	font-weight: $font-weight-bold;
	font-family: $ui-font-family;
	text-decoration: none;
	&.inactive,
	&.disabled {
		opacity: .5;
		pointer-events: none;
		.icon-font,
		[class^="icon-"],
		i {
			color: $color-black;
		}
	}
	.icon-font,
	[class^="icon-"],
	i {
		pointer-events: none;
	}
}

%ui-icon-btn,
.ui-icon-btn {
	@extend %ui-btn;
	@include ui-btn-factory('icon');
}

// UI modifiers
.ui-btn--primary {
	@include ui-btn-factory('primary');
}

.ui-btn--text,
.ui-btn--secondary {
	@include ui-btn-factory('secondary');
}

.ui-btn--box {
	@include ui-btn-factory('box');
}

.circle-btn,
%circle-btn {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	text-indent: -9999px;
	text-align: center; //c-loader
	.c-loader {
		opacity: 0;
		display: none; //hide it for now
	}
	&.lg {
		width: 90px;
		height: 90px;
	} //variation
	&.transparent {
		@include circle-btn-color('transparent');
	}
	&.transparent-white {
		@include circle-btn-color('transparent-white');
	}
	&.black-red {
		@include circle-btn-color('black-red');
	}
	&.black-white {
		@include circle-btn-color('black-white');
	}


	&:active,
	&:focus {
		border: 2px dotted $color-global-focus;
		outline: none;
	}
	@media screen and (max-width: 768px) {
		&.lg {
			width: 60px;
			height: 60px;
		}
	}
}

.ui-btn--circle-plus {
	@extend %circle-btn;
	@include circle-btn-color('white-red');
	text-indent: 0;
	border: 1px solid $ui-color-border;
	position: relative;
	color: $ui-color-red; //arrow icon
	i {
		position: absolute;
		left: 50%;
		top: 50%;
		color: inherit;
		font-size: 18px;
		transform: translate3d(-50%, -50%, 0) rotate(45deg) scale(.8, .8);
		z-index: 11;
		will-change: transform;
		transition: 150ms transform;
		&:before {
			content: "\e902";
			pointer-events: none;
		}
	} //circle bg
	&:after {
		content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		width: 94%;
		height: 94%;
		background-color: inherit;
		border-radius: 50%;
		transform: translate3d(-50%, -50%, 0);
		z-index: 10;
	}
	 //hover
	&:hover,
	&.active {
		i {
			transform: translate3d(-50%, -50%, 0) rotate(45deg) scale(1, 1);
		}
	}
}

.ui-btn--circle-arrow {
	@extend %circle-btn;
	@include circle-btn-color('white-red');
	text-indent: 0;
	border: 1px solid $ui-color-border;
	position: relative;
	color: $ui-color-red; //arrow icon
	i {
		position: absolute;
		left: 50%;
		top: 50%;
		color: inherit;
		font-size: 20px;
		transform: translate3d(-50%, -50%, 0);
		z-index: 11;
		will-change: transform;
		transition: 150ms transform;
		&:before {
			content: "\e913";
			pointer-events: none;
		}
	} //circle bg
	&:after {
		content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		width: 94%;
		height: 94%;
		background-color: inherit;
		border-radius: 50%;
		transform: translate3d(-50%, -50%, 0);
		z-index: 10;
	} //hover
	&:hover,
	&.active {
		i {
			transform: translate3d(calc(-50% + 2.5px), -50%, 0);
		}
	}
}

.ui-btn--circle-video,
.ui-btn--circle-play {
	@extend %circle-btn;
	@include circle-btn-color('white-red');
	transition: background-color 150ms; //">" icon
	&:before {
		content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		border-width: 7px 0 7px 12px;
		border-color: transparent transparent transparent $ui-color-red;
		border-style: solid;
		will-change: transform;
		transform: translate3d(-40%, -50%, 0);
		z-index: 11;
	} //circle bg
	&:after {
		content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		width: 94%;
		height: 94%;
		background-color: inherit;
		border-radius: 50%;
		transform: translate3d(-50%, -50%, 0);
		z-index: 10;
	} //hover
	&:hover,
	&.active {
		background-color: $color-white;
		&:before {
			content: "Play";
			color: $color-black;
			text-indent: 0;
			border: 0;
			transform: translate3d(-50%, -50%, 0);
		}
		.c-loader {
			@extend .c-loader.run;
			opacity: 1;
		}
	}
}

.ui-btn--circle-pause {
	@extend %circle-btn;
	@include circle-btn-color('white-red');
	text-indent: 0;
	border: 1px solid $ui-color-border;
	position: relative;
	color: $ui-color-red; //pause icon
	i {
		position: absolute;
		left: 50%;
		top: 50%;
		color: inherit;
		font-size: 24px;
		transform: translate3d(-50%, -50%, 0);
		z-index: 11;
		will-change: transform;
		transition: 150ms transform;
		&:before {
			content: "\e916";
			pointer-events: none;
		}
	} //circle bg
	&:after {
		content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		width: 94%;
		height: 94%;
		background-color: inherit;
		border-radius: 50%;
		transform: translate3d(-50%, -50%, 0);
		z-index: 10;
	}
	//hover
	&:hover,
	&.active {
	}
}

.ui-btn--circle-check {
	@extend %circle-btn;
	@include circle-btn-color('white-red');
	text-indent: 0;
	border: 1px solid $ui-color-border;
	position: relative;
	color: $ui-color-red; //arrow icon
	i {
		position: absolute;
		left: 50%;
		top: 50%;
		color: inherit;
		font-size: 26px;
		transform: translate3d(-50%, -50%, 0) scale(.8, .8);
		z-index: 11;
		will-change: transform;
		transition: 150ms transform;
		&:before {
			content: "\e922";
			pointer-events: none;
		}
	} //circle bg
	&:after {
		content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		width: 94%;
		height: 94%;
		background-color: inherit;
		border-radius: 50%;
		transform: translate3d(-50%, -50%, 0);
		z-index: 10;
	}
	 //hover
	&:hover,
	&.active {
		i {
			transform: translate3d(-50%, -50%, 0) scale(1, 1);
		}
	}
}

//language font-family overrides
.el-GR,
.pl-PL,
.cs-CZ
{
	.ui-btn
	{
		font-family: Arial, Helvetica, sans-serif;
	}
}
